import {FC} from 'react'
import {Image, Photoset, PhotosetSpace} from '@/features/photoset/types'
import {ImageCard} from '@/components/commons/image-card/ImageCard'
import {SpaceImagesContainer} from '@/features/photoset/components/space-images-atoms/SpaceImagesAtoms'
import {ExpandImageButton} from '@/components/commons/expand-image-button/ExpandImageButton'
import {Stars01Icon} from '@/components/ui/icon/Icon'
import {useImageSelectionApproval} from '@/features/photoset/queries/useImageSelectionApproval'
import {errorHandler} from '@/utilities/helpers'

interface AiVettingSelectionSpaceImagesProps {
    photosetId: Photoset['id']
    photosetSpaceId: PhotosetSpace['id']
    images: Omit<Image, 'space'>[]
    spaceIndex: number
    onExpandImage: (spaceIndex: number, imageIndex: number) => void
}

export const AiVettingSelectionSpaceImages: FC<AiVettingSelectionSpaceImagesProps> = ({
    photosetId,
    photosetSpaceId,
    images,
    spaceIndex,
    onExpandImage
}) => {
    const {mutate: aiVettingSelectionToggle} = useImageSelectionApproval({
        photosetId,
        photosetSpaceId,
        options: {
            onError: errorHandler
        }
    })

    const onToggleClick = (image: Omit<Image, 'space'>) => {
        aiVettingSelectionToggle({
            imageId: image.id,
            need_manual_retouching: !image.need_manual_retouching
        })
    }

    return (
        <SpaceImagesContainer $gap={4}>
            {images.map((image, index) => (
                <ImageCard
                    key={image.id}
                    image={image}
                    isSelected={image.need_manual_retouching}
                    thumbnail={image.ai_retouched_thumbnails?.xs}
                    onSelect={() => onToggleClick(image)}
                    endSlot={<ExpandImageButton onClick={() => onExpandImage(spaceIndex, index)} />}
                    imageNameIcon={<Stars01Icon size={20} />}
                />
            ))}
        </SpaceImagesContainer>
    )
}
