import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {css, styled} from 'styled-components'

export const StyledServiceVettingMerchInputForm = styled.form(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 6}px;
        flex: 1;
    `
)

export const StyledServiceMerchVettingFormHostSection = styled(Flexbox)(
    ({theme: {palette, typography, spacing}}) => css`
        padding: ${spacing * 6}px 0;
        color: ${palette.neutral['900']};
        & h4 {
            ${typography.textMd};
            font-weight: 500;
        }
    `
)

export const StyledServiceMerchVettingFormOfferingSection = styled(Flexbox)(
    ({theme: {palette, typography, spacing}}) => css`
        padding: ${spacing * 6}px 0;
        color: ${palette.neutral['900']};
        & h4 {
            ${typography.textMd};
            font-weight: 500;
        }
        & p {
            ${typography.textSm};
            font-weight: 500;
        }
    `
)
