import {FC} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {Trash01Icon} from '@components/ui/icon/Icon.tsx'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {Activity, CopyPreparationValidationSchema} from '@/features/exp-copy-training/types.ts'
import {FieldErrors, UseFormRegister} from 'react-hook-form'
import {TextArea} from '@components/commons/textarea/TextArea.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StyledExpCopyActivityHeader} from '@/features/exp-copy-training/components/activity-form/style.ts'

interface ExpCopyActivityFormProps {
    index: number
    register: UseFormRegister<CopyPreparationValidationSchema>
    errors?: FieldErrors<CopyPreparationValidationSchema>
    onRemove?: () => void
    isReadOnly?: boolean
    value?: Activity
    onValueChange?: (value: Partial<Activity>) => void
}

export const ExpCopyActivityForm: FC<ExpCopyActivityFormProps> = ({
    index,
    register,
    errors,
    onRemove,
    isReadOnly,
    value,
    onValueChange
}) => (
    <Flexbox direction={'column'} gap={2}>
        <StyledExpCopyActivityHeader align={'center'} justify={'space-between'}>
            <h5>Activity #{index + 1}</h5>
            {!isReadOnly && onRemove && (
                <Button variant="ghost" size="xs" onClick={onRemove}>
                    <Trash01Icon />
                </Button>
            )}
        </StyledExpCopyActivityHeader>
        <InputText
            type={'text'}
            label="Title"
            placeholder="Paste here..."
            {...(isReadOnly
                ? {
                      value: value?.title,
                      onChange: e => onValueChange?.({title: e.target.value})
                  }
                : register(`activities.${index}.title`))}
            errorMessage={errors?.activities?.[index]?.title?.message}
        />
        <TextArea
            rows={4}
            label="Description"
            placeholder="Paste here..."
            {...(isReadOnly
                ? {
                      value: value?.description,
                      onChange: e => onValueChange?.({description: e.target.value})
                  }
                : register(`activities.${index}.description`))}
            errorMessage={errors?.activities?.[index]?.description?.message}
        />
    </Flexbox>
)

ExpCopyActivityForm.displayName = 'ExpCopyActivityForm'
