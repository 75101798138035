import styled, {css} from 'styled-components'
import {SelectTrigger} from '@components/ui/select-atoms/SelectAtoms.tsx'

export const StyledSelectTrigger = styled(SelectTrigger)(
    ({theme: {palette}}) => css`
        padding: 0;
        & h4 {
            text-decoration: underline;
        }
        &:not(:disabled):hover,
        &:focus,
        &[aria-expanded='true'] {
            outline: none;
            box-shadow: none;
            background: transparent;
        }
        &:disabled {
            background-color: ${palette.neutral['100']};
        }
    `
)
