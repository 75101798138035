import {Dropzone} from '@components/ui/dropzone/Dropzone.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useDropzone} from 'react-dropzone'
import {PlusIcon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'
import {useBulkPresignedServicePhotos} from '@/features/service-vetting/query/useBulkPresignedServicePhotos.ts'
import {useState} from 'react'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {GalleryItem, useServicePhotoUpload} from '@/features/service-vetting/store/servicesPhotoUpload.ts'
import {httpConfirmUploadPhoto, httpUploadFile} from '@/features/service-vetting/http/servicesVetting.http.ts'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import toast from 'react-hot-toast'

type FileToUpload = {
    id: number
    presignedUrl: string
    file: File
}
const MAX_FILE_TO_UPLOAD = 25
export const ServiceVettingConceptGalleryUploadButton = ({
    serviceId,
    galleryId,
    disabled
}: {
    serviceId: number
    galleryId: number
    disabled?: boolean
}) => {
    const {t} = useTranslation()
    const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
    const setUploadingItemsStatus = useServicePhotoUpload(store => store.setUploadingItemsStatus)
    const addUploadProgressValues = useServicePhotoUpload(store => store.addUploadProgressValues)
    const setGalleryItemSrc = useServicePhotoUpload(store => store.setGalleryItemSrc)
    const addGalleryItems = useServicePhotoUpload(store => store.addGalleryItems)
    const queryClient = useQueryClient()

    const bulkPresignedQuery = useBulkPresignedServicePhotos({
        urlParams: {serviceId, galleryId},
        options: {
            onSuccess: data => {
                const galleryItems: GalleryItem[] = data.map((item, index) => ({
                    id: item.media.id,
                    src: URL.createObjectURL(acceptedFiles[index]),
                    status: 'pending'
                }))
                const uploadingItems: FileToUpload[] = data.map((item, index) => ({
                    id: item.media.id,
                    presignedUrl: item.presigned_url,
                    file: acceptedFiles[index]
                }))
                addGalleryItems(galleryItems)
                uploadFiles(uploadingItems)
            }
        }
    })

    const dropzoneState = useDropzone({
        disabled: bulkPresignedQuery.isPending,
        accept: {
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/png': ['.png']
        },
        maxFiles: MAX_FILE_TO_UPLOAD,
        multiple: true,
        onDropAccepted: acceptedFiles => {
            setAcceptedFiles(acceptedFiles)
            bulkPresignedQuery.mutate({medias: acceptedFiles.map(file => file.name), is_offering_image: false})
        },
        onDropRejected: rejectedFiles => {
            if (rejectedFiles.length > MAX_FILE_TO_UPLOAD) {
                return toast.error(t('errors:dropzone_max_file', {count: MAX_FILE_TO_UPLOAD}))
            }
            console.error('Rejected files: ', rejectedFiles)
        }
    })

    const uploadFile = async (galleryItem: FileToUpload, uploadNextGroup: () => void) => {
        try {
            await httpUploadFile({
                url: galleryItem.presignedUrl,
                payload: galleryItem.file,
                onUploadProgress: progressEvent =>
                    addUploadProgressValues({
                        id: galleryItem.id,
                        progressValue: progressEvent.total
                            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            : 100
                    })
            })
            const response = await httpConfirmUploadPhoto({
                serviceId: serviceId,
                mediaId: galleryItem.id,
                galleryId: galleryId
            })

            setGalleryItemSrc({id: galleryItem.id, src: response.data.url})
            setUploadingItemsStatus({id: galleryItem.id, status: 'uploaded'})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICE_VETTING]})
        } catch (error) {
            setUploadingItemsStatus({id: galleryItem.id, status: 'error'})
        } finally {
            uploadNextGroup()
        }
    }

    const uploadFiles = (galleryItems: FileToUpload[]) => {
        let filesQueueIndex = 0
        const firstGroupSize = 6
        const othersGroupSize = 3
        let queue = [galleryItems.slice(0, firstGroupSize)]

        for (let index = firstGroupSize; index < galleryItems.length; index += othersGroupSize) {
            queue = queue.concat([galleryItems.slice(index, index + othersGroupSize)])
        }
        const filesQueue = queue

        const uploadFilesGroup = (galleryItems: FileToUpload[]) => {
            galleryItems.map(
                item =>
                    void uploadFile(item, () => {
                        const nextFilesQueue = filesQueue[++filesQueueIndex]
                        if (nextFilesQueue) {
                            uploadFilesGroup(nextFilesQueue)
                        }
                    })
            )
        }

        uploadFilesGroup(filesQueue[0])
    }

    return (
        <Dropzone state={dropzoneState}>
            <Button size={'sm'} variant={'secondary'} disabled={bulkPresignedQuery.isPending || disabled}>
                {bulkPresignedQuery.isPending ? <Spinner size={17} /> : <PlusIcon />}
                {t('vetting:concept:service:gallery:upload_photos')}
            </Button>
        </Dropzone>
    )
}
