import {Modal} from '@/components/ui/modal/Modal'
import {FC, useState} from 'react'
import {StyledRejectModalBody} from '@/features/photoset/components/reject-photoset-modal/style'
import {Button} from '@/components/ui/button/Button'
import {XCloseIcon} from '@/components/ui/icon/Icon'
import {useTranslation} from 'react-i18next'
import {Divider} from '@/components/ui/divider/Divider'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {DeclineReason} from '@/features/photoset/types'
import {useRejectPhotoset} from '@/features/photoset/queries/useRejectPhotoset'
import {errorHandler} from '@/utilities/helpers'
import {Radio} from '@/components/commons/radio/Radio'
import {ModalFooter, ModalHeader} from '@/components/ui/modal-atoms/ModalAtoms'

interface RejectPhotosetModalProps {
    reasons: DeclineReason[]
    close: () => void
}

export const RejectPhotosetModal: FC<RejectPhotosetModalProps> = ({close, reasons}) => {
    const [selectedReason, setSelectedReason] = useState<DeclineReason['id'] | null>(null)
    const rejectPhotoset = useRejectPhotoset({
        onError: errorHandler
    })

    const {t} = useTranslation()

    const onCloseModal = () => {
        if (!rejectPhotoset.isPending) {
            close()
        }
    }

    return (
        <Modal width={480} onOverlayClick={onCloseModal}>
            <ModalHeader>
                <Button iconOnly size="sm" variant="tertiary" onClick={onCloseModal}>
                    <XCloseIcon />
                </Button>
                <h3>{t('photoset:reject_modal:header_title')}</h3>
            </ModalHeader>

            <Divider direction="horizontal" />

            <StyledRejectModalBody>
                <Flexbox direction="column" gap={2}>
                    <h1>{t('photoset:reject_modal:title')}</h1>
                    <h2>{t('photoset:reject_modal:subtitle')}</h2>
                </Flexbox>

                <Flexbox direction="column" gap={4}>
                    {reasons.map(reason => (
                        <Radio
                            key={reason.id}
                            name="reason"
                            label={t(`photoset:reasons:${reason.key_name}`)}
                            value={reason.id}
                            onChange={() => setSelectedReason(reason.id)}
                        />
                    ))}
                </Flexbox>
            </StyledRejectModalBody>

            <ModalFooter>
                <Button variant="tertiary" onClick={() => close()}>
                    {t('commons:close')}
                </Button>
                <Button
                    variant="primary"
                    disabled={!selectedReason || rejectPhotoset.isPending}
                    onClick={() => selectedReason && rejectPhotoset.mutate(selectedReason)}
                >
                    {t('commons:reject')}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
