import {
    ServiceVettingConceptFlagsSelect,
    ServiceVettingConceptFlagsSelectProps
} from '@/features/service-vetting/components/service-vetting-concept-flags-select/ServiceVettingConceptFlagsSelect.tsx'
import {ServiceFlag} from '@/features/service-vetting/types.ts'
import {Chip, ChipProps} from '@components/commons/chip/Chip.tsx'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledServiceVettingFlagsContainer} from '@/features/service-vetting/components/service-vetting-concept-flags/style.ts'
import {XCloseIcon} from '@components/ui/icon/Icon.tsx'

interface ServiceVettingConceptFlagsProps extends Omit<ServiceVettingConceptFlagsSelectProps, 'selectedFlagsId'> {
    flags: ServiceFlag[]
    flagPosition?: 'left' | 'right'
    badgeVariant?: ChipProps['variant']
    hideAction?: boolean
}
export const ServiceVettingConceptFlags: FC<ServiceVettingConceptFlagsProps> = ({
    flags,
    addFlag,
    removeFlag,
    badgeVariant = 'info',
    types,
    hideAction,
    flagPosition = 'right'
}) => {
    const {t} = useTranslation()
    return (
        <StyledServiceVettingFlagsContainer $flagPosition={flagPosition} gap={2}>
            {!hideAction && (
                <ServiceVettingConceptFlagsSelect
                    selectedFlagsId={flags.map(flag => flag.id)}
                    addFlag={addFlag}
                    removeFlag={removeFlag}
                    types={types}
                />
            )}
            {flags.map(flag => (
                <Chip
                    key={flag.id}
                    label={t(`vetting:concept:service:flags_key_name:${flag.key_name}`)}
                    variant={badgeVariant}
                    isActive
                    endSlot={
                        !hideAction && (
                            <button tabIndex={-1} onClick={() => removeFlag(flag)}>
                                <XCloseIcon />
                            </button>
                        )
                    }
                />
            ))}
        </StyledServiceVettingFlagsContainer>
    )
}
