import {create} from 'zustand'

type Errors = {
    expertiseErrors: string[]
    offeringErrors: {offeringId: number; error: string}[]
    galleryErrors: string[]
}

export type AdminServiceVettingError = {
    errors: Errors
    isTouched: boolean
    setIsTouched: (isTouched: boolean) => void
    setErrors: (errors: Errors) => void
    reset: () => void
}

export const useServiceVettingError = create<AdminServiceVettingError>(set => ({
    errors: {expertiseErrors: [], offeringErrors: [], galleryErrors: []},
    isTouched: false,
    setIsTouched: (isTouched: boolean) => set({isTouched}),
    setErrors: errors => {
        set({errors})
    },
    reset: () => set({isTouched: false, errors: {expertiseErrors: [], offeringErrors: [], galleryErrors: []}})
}))
