import {useTranslation} from 'react-i18next'
import {
    SpaceImageFrame,
    SpaceImagesContainer,
    SpaceImageSelectionInfo
} from '@/features/photoset/components/space-images-atoms/SpaceImagesAtoms'
import {ImageCard} from '@/components/commons/image-card/ImageCard'
import {Stars01Icon, ThumbsDownIcon} from '@/components/ui/icon/Icon'
import {Image, Photoset, PhotosetSpace} from '@/features/photoset/types'
import {useImageSelectionApproval} from '@/features/photoset/queries/useImageSelectionApproval'
import {errorHandler} from '@/utilities/helpers'
import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ImageBadge} from '@/features/photoset/components/image-badge/ImageBadge'
import {QUERY_KEYS} from '@/queryClient'

export const VettingQaSpaceImages = ({
    images,
    photosetId,
    photosetSpaceId,
    isQaView
}: {
    images: PhotosetSpace['images']
    photosetId: Photoset['id']
    photosetSpaceId: PhotosetSpace['id']
    isQaView: boolean
}) => {
    const {t} = useTranslation()
    const {mutate: imageSelectionApprovalToggle} = useImageSelectionApproval({
        photosetId,
        photosetSpaceId: photosetSpaceId,
        queryKey: [QUERY_KEYS['PHOTOSET_SPACES']],
        options: {
            onError: errorHandler
        }
    })

    const onToggleClick = (image: Omit<Image, 'space'>) => {
        imageSelectionApprovalToggle({
            imageId: image.id,
            vetting_qa_approved: !image.vetting_qa_approved
        })
    }

    return (
        <SpaceImagesContainer $gap={4}>
            {images.map(image => (
                <SpaceImageFrame $isSelected={image.is_manually_retouched} key={image.id}>
                    <ImageCard
                        image={image}
                        isSelected={false}
                        thumbnail={image.ai_retouched_thumbnails?.xs || image.thumbnails?.xs}
                        hideImageInfo
                        imageNameIcon={!image.is_manually_retouched && <Stars01Icon size={20} />}
                        badgeType="selection"
                        endSlot={
                            <SpaceImageSelectionInfo direction={'column'} gap={2} fullWidth>
                                <Flexbox gap={2} align="center" justify="space-between" render="section" fullWidth>
                                    <h4>{image.name}</h4>
                                    {image.is_manually_retouched && <ImageBadge type="manually_retouched" />}
                                </Flexbox>
                                <Button
                                    fullWidth
                                    variant={image.vetting_qa_approved ? 'secondary' : 'secondaryDanger'}
                                    size={'sm'}
                                    disabled={!isQaView}
                                    onClick={() => onToggleClick(image)}
                                >
                                    <ThumbsDownIcon />
                                    {t('commons:reject')}
                                </Button>
                            </SpaceImageSelectionInfo>
                        }
                    />
                </SpaceImageFrame>
            ))}
        </SpaceImagesContainer>
    )
}
