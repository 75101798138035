import styled, {css} from 'styled-components'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'

export const StyledCopyTrainingWrapper = styled(Flexbox)(
    ({theme: {spacing, palette, typography}}) => css`
        position: relative;
        padding: ${spacing * 6}px 0 ${spacing * 6}px;
        border-bottom: 1px solid ${palette.neutral['200']};
        & h1 {
            color: ${palette.neutral['900']};
            ${typography.displayXs};
            font-weight: 700;
        }
    `
)
