import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledExpCopyActivityHeader = styled(Flexbox)`
    ${({theme: {spacing, typography, palette}}) => css`
        gap: ${spacing * 4}px;
        width: 100%;
        padding: ${spacing * 6}px 0 ${spacing * 2}px;
        & > h5 {
            ${typography.textMd};
            font-weight: 700;
            color: ${palette.neutral[900]};
        }
    `}
`
