import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpRejectPhotoset} from '@/features/photoset/http/photoset.http'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {AxiosError} from 'axios'
import {useOutletContext} from 'react-router-dom'
import {PhotosetURLParams} from '../types'
import {ErrorResponseData} from '@/types/commons'

type UseRejectPhotoset = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useRejectPhotoset = (options?: UseRejectPhotoset) => {
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.REJECT_PHOTOSET],
        mutationFn: (reasonId: number) =>
            httpRejectPhotoset({
                urlParams: {photosetId: urlParams.id},
                params: {reasonId}
            }),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
            options?.onSuccess?.()
        }
    })
}
