import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {
    httpUpdateExpertiseStatus,
    HttpUpdateExpertiseStatus
} from '@/features/service-vetting/http/servicesVetting.http.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {Service} from '@/features/service-vetting/types.ts'

export const useUpdateServiceExpertiseStatus = (
    {serviceId, expertiseId}: {serviceId: number; expertiseId: number},
    options?: UseMutationOptions<unknown, unknown, HttpUpdateExpertiseStatus['payload']>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationFn: payload =>
            httpUpdateExpertiseStatus({
                urlParams: {serviceId: serviceId, expertiseId: expertiseId},
                payload,
                params: {
                    response_type: 'small'
                }
            }),
        onError: (error, variables, context) => {
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.SERVICE_VETTING, serviceId]}, context)
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onMutate: async variables => {
            const queryKey = [QUERY_KEYS.SERVICE_VETTING, serviceId]

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey})

            // Snapshot previous values
            const previousData = queryClient.getQueryData<Service>(queryKey)

            // Update cached data
            if (previousData) {
                queryClient.setQueriesData<Service>(
                    {queryKey},
                    {
                        ...previousData,
                        expertise: {...previousData.expertise, status: variables.status}
                    }
                )
            }
            return previousData
        }
    })
}
