import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpAllocateImages, HttpAllocateImagesOptions} from '@/features/photoset/http/photoset.http'
import {MUTATION_KEYS} from '@/queryClient.ts'
import {AxiosError} from 'axios'
import {raise} from '@utilities/helpers.ts'
import toast from 'react-hot-toast'
import {generateToastAllocationId} from '@/features/photoset/utils.ts'
import {usePhotosetStore} from '@/features/photoset/store/store.ts'
import {UndoMessage} from '../components/undo-message/UndoMessage'

export const useAllocateImages = ({
    onUndo,
    showToast = false,
    options
}: {
    onUndo: (imagesIds: number[], spaceId: number, isShortcut: boolean) => void
    showToast?: boolean
    options?: Omit<UseMutationOptions<unknown, AxiosError, HttpAllocateImagesOptions>, 'mutationFn' | 'mutationKey'>
}) => {
    const photosetSpaces = usePhotosetStore(store => store.photosetSpacesDict)
    const allocateImages = usePhotosetStore(store => store.allocateImages)

    return useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.ALLOCATE_IMAGES],
        mutationFn: httpAllocateImages,
        onSuccess: (data, variables, context) => {
            allocateImages({
                imagesIds: variables.payload,
                photosetSpaceId: variables.urlParams.photosetSpaceId,
                keepSelected: false,
                trackInHistory: true
            })

            if (showToast) {
                const spaceName =
                    photosetSpaces[variables.urlParams.photosetSpaceId]?.space.key_name ??
                    raise(`No space found with id ${variables.urlParams.photosetSpaceId}`)

                const toastId = generateToastAllocationId(
                    'allocation',
                    variables.urlParams.photosetSpaceId,
                    variables.payload
                )

                toast.success(
                    <UndoMessage
                        operationType="allocation"
                        spaceKeyName={spaceName}
                        onUndo={() => onUndo(variables.payload, variables.urlParams.photosetSpaceId, false)}
                    />,
                    {duration: 8000, id: toastId}
                )
            }

            options?.onSuccess?.(data, variables, context)
        }
    })
}
