import {useTranslation} from 'react-i18next'
import {StyledServiceMerchandisingVettingWrapper, StyledServiceVettingFormsWrapper} from './style'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Button} from '@/components/ui/button/Button'
import {Settings01Icon, Stars01Icon, User01Icon} from '@/components/ui/icon/Icon'
import {ServiceVettingMerchInputForm} from '@/features/service-vetting/components/service-vetting-merch-input-form/ServiceVettingMerchInputForm'
import {ScrollArea} from '@/components/commons/scroll-area/ScrollArea'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {ServiceVettingMerchOutputForm} from '@/features/service-vetting/components/service-vetting-merch-output-form/ServiceVettingMerchOutputForm'
import {useRef, useState} from 'react'
import {SubmitHandler} from 'react-hook-form'
import {ServiceMerchandisingInputSchema, ServiceMerchandisingOutputSchema} from '@/features/service-vetting/formSchemas'
import {useMerchCopyAi} from '@/features/ops-vetting/hooks/useMerchCopyAi'
import {OpsVettingHero} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {Divider} from '@/components/ui/divider/Divider'
import {useOutletContext} from 'react-router-dom'
import {useGetService} from '@/features/service-vetting/query/useGetService'
import {OpsVettingURLParams} from '@/features/ops-vetting/types'
import {remapMerchCopyAiSubmittedForm, remapMerchCopyInputForm} from '@/features/service-vetting/utils'
import {ServiceVettingMerchFormSkeleton} from '@/features/service-vetting/components/service-vetting-merch-form-skeleton/ServiceVettingMerchFormSkeleton'
import {ServiceVettingMerchFormHeader} from '@/features/service-vetting/components/service-vetting-merch-form-header/ServiceVettingMerchFormHeader'
import {StyledServiceVettingMerchInputForm} from '@/features/service-vetting/components/service-vetting-merch-input-form/style'
import {ServiceVettingMerchAiSubmitModal} from '@/features/service-vetting/components/service-vetting-merch-ai-submit-modal/ServiceVettingSubmitModal'
import {ServicePromptModal} from '@/features/service-copy-training/ServicePromptModal'
import {SERVICES_PROMPT} from '@/features/ops-vetting/utils'
import {useSubmitService} from '@/features/service-vetting/query/useSubmitService'
import toast from 'react-hot-toast'

export const ServiceMerchandisingVetting = () => {
    const {t} = useTranslation()
    const {aiResponse, isLoading: isAiLoading, onMerchCopyAiCall, resetAiResponse} = useMerchCopyAi()
    const {urlParams} = useOutletContext<{urlParams: OpsVettingURLParams}>()
    const {remappedData: service, isFetching} = useGetService(urlParams.id)
    const isApprovalRequired = service?.status === 'archived_approval_required'
    const isServiceApproved = service?.status === 'approved'
    const isFormReadOnly = isServiceApproved || isApprovalRequired
    //prompt controls
    const [currentPrompt, setCurrentPrompt] = useState<string>(SERVICES_PROMPT)
    //submission refs
    const finalSubmitRef = useRef<HTMLButtonElement>(null)
    const aiSubmissionRef = useRef<HTMLButtonElement>(null)
    //ai submit utils
    const [isAiSubmitModalOpen, setIsAiSubmitModalOpen] = useState(false)

    const {mutate: submitMerchCopy, isPending: isSubmittingAiMerchCopy} = useSubmitService(
        {serviceId: service?.id ?? 0}, //shitfix
        {
            onSettled: () => setIsAiSubmitModalOpen(!isAiSubmitModalOpen),
            onSuccess: () => toast.success(t('vetting:merch:confirmation_modal:submit_success'))
        }
    )
    console.log('approval required', service?.status)
    const handleAiSubmission: SubmitHandler<ServiceMerchandisingInputSchema> = data => {
        resetAiResponse()
        onMerchCopyAiCall(data, currentPrompt)
        console.log('handleAiSubmission', data)
    }

    const handleFinalSubmission: SubmitHandler<ServiceMerchandisingOutputSchema> = data => {
        const payload = {
            ...data,
            is_host_approval_needed: true, //TODO: remove this after testing this should be mantained in a state
            is_waitlisted: false //TODO: remove this after testing
        }
        console.log('handleFinalSubmission', payload)
        submitMerchCopy(payload)
    }

    return (
        <ScrollArea scrollbar={<Scrollbar />}>
            <StyledServiceMerchandisingVettingWrapper fullWidth>
                <OpsVettingHero fullWidth justify="space-between" align="center">
                    <h3>{t('vetting:merch:service:title')}</h3>
                    {!isFormReadOnly && (
                        <Flexbox gap={2}>
                            <ServicePromptModal
                                trigger={
                                    <Button variant="tertiary" size="sm" disabled={isAiLoading}>
                                        <Settings01Icon />
                                        {t('vetting:merch:commons:customize_prompt')}
                                    </Button>
                                }
                                currentPrompt={currentPrompt}
                                onPromptChange={newPrompt => setCurrentPrompt(newPrompt)}
                                isLoading={isAiLoading}
                            />
                            <Button
                                variant="secondary"
                                size="sm"
                                onClick={() => aiSubmissionRef.current?.click()}
                                disabled={isAiLoading}
                            >
                                {isAiLoading ? <Spinner size={16} /> : <Stars01Icon />}
                                {t('vetting:merch:commons:improve_with_ai')}
                            </Button>
                            <Button
                                variant="primary"
                                size="sm"
                                disabled={isAiLoading}
                                onClick={() => setIsAiSubmitModalOpen(true)}
                            >
                                {t('vetting:merch:commons:submit')}
                            </Button>
                            {service && isAiSubmitModalOpen && (
                                <ServiceVettingMerchAiSubmitModal
                                    isSubmitting={isSubmittingAiMerchCopy}
                                    onClose={() => setIsAiSubmitModalOpen(false)}
                                    submissionRef={finalSubmitRef}
                                />
                            )}
                        </Flexbox>
                    )}
                </OpsVettingHero>

                <StyledServiceVettingFormsWrapper fullWidth>
                    {isFetching || !service ? (
                        <StyledServiceVettingMerchInputForm>
                            <ServiceVettingMerchFormHeader
                                icon={<User01Icon />}
                                title={t('vetting:merch:service:input_form_title')}
                                description={t('vetting:merch:service:input_form_description')}
                            />
                            <ServiceVettingMerchFormSkeleton />
                        </StyledServiceVettingMerchInputForm>
                    ) : (
                        <>
                            {!isServiceApproved && (
                                <ServiceVettingMerchInputForm
                                    isReadOnly={isFormReadOnly}
                                    defaultValues={remapMerchCopyInputForm(service)}
                                    aiSubmissionRef={aiSubmissionRef}
                                    handleAiSubmissionCb={handleAiSubmission}
                                />
                            )}
                            {isServiceApproved && (
                                <ServiceVettingMerchOutputForm
                                    isReadOnly={isFormReadOnly}
                                    defaultValues={remapMerchCopyAiSubmittedForm(service)}
                                    finalSubmitRef={finalSubmitRef}
                                    handleFinalSubmissionCb={handleFinalSubmission}
                                />
                            )}
                        </>
                    )}
                    <Divider direction="vertical" />
                    <ServiceVettingMerchOutputForm
                        isReadOnly={isFormReadOnly}
                        isLoading={isAiLoading || isFetching}
                        defaultValues={
                            isApprovalRequired
                                ? remapMerchCopyAiSubmittedForm(service)
                                : (aiResponse as ServiceMerchandisingOutputSchema)
                        }
                        finalSubmitRef={finalSubmitRef}
                        handleFinalSubmissionCb={handleFinalSubmission}
                    />
                </StyledServiceVettingFormsWrapper>
            </StyledServiceMerchandisingVettingWrapper>
        </ScrollArea>
    )
}

ServiceMerchandisingVetting.displayName = 'ServiceMerchandisingVetting'
