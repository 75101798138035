import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledOpsVettingSectionTitle = styled.h3(
    ({theme: {typography, palette}}) => css`
        ${typography.textLg};
        font-weight: 500;
        color: ${palette.neutral['900']};
    `
)
export const StyledOpsVettingCardTitle = styled.h3(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        font-weight: 500;
        color: ${palette.neutral['900']};
    `
)
export const StyledOpsVettingLabel = styled.span(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral['600']};
        font-weight: 500;
    `
)
export const StyledOpsVettingParagraph = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        word-break: break-word;
        color: ${palette.neutral['900']};
    `
)

export const StyledOpsVettingDisabledContainer = styled.div<{$isDisabled: boolean}>(
    ({$isDisabled}) => css`
        width: 100%;
        ${$isDisabled &&
        css`
            pointer-events: none;
            opacity: 0.4;
        `}
    `
)

export const StyledOpsVettingHero = styled(Flexbox)(
    ({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 4}px 0;
        & h3 {
            ${typography.textXl};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }
    `
)

export const StyledOpsVettingQuestionsGrid = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
        gap: ${spacing * 6}px;
        width: 100%;
    `
)

export const StyledOpsVettingSectionWrapper = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 6}px 0;
    `
)

export const StyledOpsVettingCardContainer = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px;
        border: 1px solid ${palette.neutral['300']};
        border-radius: 16px;
        width: 100%;
    `
)

export const StyledOpsVettingImage = styled.img`
    aspect-ratio: 1 / 1;
    object-fit: cover;
    width: 100%;
    border-radius: 8px;
`

export const StyledOpsVettingImageTitle = styled.h4(
    ({theme: {typography, palette}}) => css`
        ${typography.textXs};
        font-weight: 500;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        color: ${palette.neutral['900']};
    `
)

export const StyledOpsVettingCardGrid = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: ${spacing * 6}px;
        ${mediaQueries.xxl} {
            grid-template-columns: minmax(0, 1fr);
        }
    `
)

export const StyledOpsVettingGalleryGrid = styled.div(
    ({theme: {mediaQueries}}) => css`
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        width: 100%;
        ${mediaQueries.xxl} {
            grid-template-columns: repeat(4, 1fr);
        }
    `
)

export const StyledOpsVettingCardBodyContainer = styled.div(
    () => css`
        display: grid;
        grid-template-columns: minmax(0, 1fr) 264px;
        width: 100%;
        justify-items: end;
        gap: 16px;
    `
)

export const StyledOpsVettingImagePlaceholderBox = styled.div(
    ({theme: {palette}}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${palette.neutral['100']};
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: 8px;
        flex-shrink: 0;

        & > svg {
            color: ${palette.neutral['400']};
        }
    `
)

export const StyledOpsVettingGalleryItemsContainer = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 5}px;
        overflow: hidden;
    `
)
