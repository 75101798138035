import styled, {css} from 'styled-components'
import * as RadixScrollArea from '@radix-ui/react-scroll-area'
import {CSSProperties} from 'react'

export const StyledRoot = styled(RadixScrollArea.Root)<{$overflowX?: CSSProperties['overflowX']}>(
    ({$overflowX}) => css`
        overflow-x: ${$overflowX};
    `
)

export const StyledViewport = styled(RadixScrollArea.Viewport)`
    height: 100%;
    & > div {
        display: block !important;
    }
`
