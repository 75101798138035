import {zodResolver} from '@hookform/resolvers/zod'
import {useForm, useFieldArray, SubmitHandler} from 'react-hook-form'
import {ServiceMerchandisingInputSchema} from '@/features/service-vetting/formSchemas.ts'
import {
    StyledServiceMerchVettingFormHostSection,
    StyledServiceMerchVettingFormOfferingSection,
    StyledServiceVettingMerchInputForm
} from './style'
import {InputText} from '@/components/commons/input-text/InputText'
import {TextArea} from '@/components/commons/textarea/TextArea'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Divider} from '@/components/ui/divider/Divider'
import {AnnotationDotsIcon, PlusIcon, Trash01Icon} from '@/components/ui/icon/Icon'
import {ServiceVettingMerchFormHeader} from '../service-vetting-merch-form-header/ServiceVettingMerchFormHeader'
import {RefObject} from 'react'
import {Button} from '@/components/ui/button/Button'

interface ServiceVettingMercTrainingInputForm {
    aiSubmissionRef: RefObject<HTMLButtonElement>
    handleAiSubmissionCb: SubmitHandler<ServiceMerchandisingInputSchema>
}

export const ServiceVettingMercTrainingInputForm = ({
    aiSubmissionRef,
    handleAiSubmissionCb
}: ServiceVettingMercTrainingInputForm) => {
    const {
        register,
        control,
        formState: {errors},
        handleSubmit
    } = useForm<ServiceMerchandisingInputSchema>({
        resolver: zodResolver(ServiceMerchandisingInputSchema)
    })
    const {fields, append, remove} = useFieldArray({control, name: 'offerings'})
    console.log(errors)
    return (
        <StyledServiceVettingMerchInputForm onSubmit={handleSubmit(handleAiSubmissionCb)}>
            <ServiceVettingMerchFormHeader
                icon={<AnnotationDotsIcon />}
                title="Original content"
                description="Content submitted by the host"
            />

            <Flexbox direction={'column'} gap={2} fullWidth>
                <StyledServiceMerchVettingFormHostSection direction={'column'} gap={4} fullWidth>
                    <h4>Host</h4>
                    <InputText type={'text'} label="Host name" {...register(`host_name`)} />
                    <InputText type={'number'} label="Host experience" {...register(`host_experience`)} />
                    <TextArea
                        rows={4}
                        label="Experience summary"
                        {...register('host_experience_summary')}
                        errorMessage={errors?.host_experience_summary?.message}
                    />
                    <TextArea
                        rows={4}
                        label="Experience highlight"
                        {...register('host_experience_highlight')}
                        errorMessage={errors?.host_experience_highlight?.message}
                    />
                    <TextArea
                        rows={4}
                        label="Background"
                        {...register('host_experience_background')}
                        errorMessage={errors?.host_experience_background?.message}
                    />
                </StyledServiceMerchVettingFormHostSection>

                <Divider direction={'horizontal'} />

                <StyledServiceMerchVettingFormOfferingSection direction={'column'} gap={5} fullWidth>
                    <h4>Service</h4>
                    <InputText type={'text'} label="Listing title" {...register(`service_title`)} />
                    <InputText type={'text'} label="Byline" {...register(`service_byline`)} />

                    {fields.map((offering, index) => (
                        <Flexbox fullWidth direction={'column'} gap={2} key={offering.id}>
                            <Flexbox align={'center'} gap={4}>
                                <p>Offering {index + 1}</p>
                                {fields.length > 1 && (
                                    <Button variant="ghost" size="xs" onClick={() => remove(index)}>
                                        <Trash01Icon />
                                    </Button>
                                )}
                            </Flexbox>
                            <InputText type={'text'} label="Title" {...register(`offerings.${index}.title`)} />
                            <TextArea rows={4} label="Description" {...register(`offerings.${index}.description`)} />
                        </Flexbox>
                    ))}
                    {fields.length < 5 && (
                        <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => append({id: 0, title: '', description: '', type: 'other'})}
                        >
                            <PlusIcon />
                            Add offering
                        </Button>
                    )}
                </StyledServiceMerchVettingFormOfferingSection>
            </Flexbox>

            <button type="submit" ref={aiSubmissionRef} style={{display: 'none'}} />
        </StyledServiceVettingMerchInputForm>
    )
}

ServiceVettingMercTrainingInputForm.displayName = 'ServiceVettingMercTrainingInputForm'
