import i18n from '@/translations/i18n'
import {AxiosPromise, isAxiosError} from 'axios'
import dayjs, {ConfigType} from 'dayjs'
import i18next from 'i18next'
import toast from 'react-hot-toast'
import {z, ZodError, ZodSchema} from 'zod'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(LocalizedFormat)

export const getInitials = (name: string) => {
    return name.match(/\b\w/g) || []
}

export const megabytesToBytes = (megabytes: number) => megabytes * 1_000_000

export const errorHandler = (error: unknown) => {
    if (isAxiosError(error)) {
        const message =
            error.response?.data.message && error.status != 500
                ? i18next.t(`errors:${error.response.data.message}`)
                : i18next.t('errors:default')
        return toast.error(message)
    }

    toast.error(i18next.t('errors:default'))
    return error
}

/**
 * This function parses the api response using the provided shape and returns the response data or the ZodError if the parse fails
 * */
export const parseAxiosPromise = <T extends ZodSchema>({
    axiosPromise,
    responseShape,
    onZodError
}: {
    axiosPromise: AxiosPromise
    responseShape: T
    onZodError?: (error: ZodError) => void
}): Promise<z.infer<T>> =>
    axiosPromise.then(response => {
        const safeParsedResponseData = responseShape.safeParse(response.data)
        if (!safeParsedResponseData.success) {
            console.error(safeParsedResponseData.error.message + '\n\n\n' + JSON.stringify(response.data))
            onZodError?.(safeParsedResponseData.error)
            throw new Error(safeParsedResponseData.error.message + '\n\n\n' + JSON.stringify(response.data))
        }

        return safeParsedResponseData.data
    })

/**
 * Function that throws an error, it's useful to prevent impossible states in the application.
 * Example: const id = params.id ?? raise('No id provided'), in this case id is a string instead of string | undefined
 * @param error
 */
export const raise = (error: string): never => {
    throw new Error(error)
}

export const ObjectKeys = Object.keys as <T extends object>(object: T) => Array<keyof T>
export const ObjectValues = Object.values as <T extends object>(object: T) => Array<T[keyof T]>
export const ObjectEntries = Object.entries as <T extends object>(
    object: T
) => Array<{[K in keyof T]: [K, T[K]]}[keyof T]>

export const isWindows = /windows/i.test(navigator.userAgent)

/* transform an array of items into a dictionary by utilizing a key selector function. */
export function groupBy<K extends PropertyKey, T>(items: Iterable<T>, keySelector: (item: T) => K): Record<K, T[]> {
    const grouped = {} as Record<K, T[]>

    for (const item of items) {
        const key = keySelector(item)
        grouped[key] = grouped[key] || []
        grouped[key].push(item)
    }

    return grouped
}

/*date time helpers*/
export const formatLocaleDate = (date: NonNullable<ConfigType>, formatType?: string) =>
    dayjs(date)
        .locale(i18n.language)
        .format(formatType || 'll')

export const formatCurrency = (amount = 0, currency: string) =>
    new Intl.NumberFormat('en', {
        style: 'currency',
        currency
    }).format(amount)
