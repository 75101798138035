import {useTranslation} from 'react-i18next'
import {FC, ReactElement} from 'react'
import {IconContainer} from '@/components/ui/icon-container/IconContainer'
import {useGetFlags} from '@/features/photoset/queries/useGetFlags'
import {StyledEmptyFlagsResult} from '@/features/photoset/components/flags-popover/style'
import {Button} from '@/components/ui/button/Button'

interface EmptyFlagsResultProps {
    title: string
    hasRetry: boolean
    icon?: ReactElement
}

export const EmptyFlagsResult: FC<EmptyFlagsResultProps> = ({title, icon, hasRetry}) => {
    const {t} = useTranslation()
    const {refetch, isRefetching} = useGetFlags(
        {
            params: {
                response_type: 'extended'
            }
        },
        {enabled: false}
    )

    return (
        <StyledEmptyFlagsResult align="center" direction="column" gap={4}>
            {icon && <IconContainer>{icon}</IconContainer>}
            <h3>{title}</h3>
            {hasRetry && (
                <Button onClick={() => refetch()} disabled={isRefetching}>
                    {t('commons:retry')}
                </Button>
            )}
        </StyledEmptyFlagsResult>
    )
}
