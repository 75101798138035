import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ServiceVettingSteps} from '@/utilities/constants/routes'
import {NavLink, generatePath} from 'react-router-dom'
import {ReactNode} from 'react'
import {StyledOpsVettingHeader, StyledOpsVettingTabs} from './style'

export const OpsVettingHeader = ({endSlot, vettingId}: {endSlot: ReactNode; vettingId: number}) => {
    return (
        <StyledOpsVettingHeader gap={2} direction="column">
            <Flexbox fullWidth gap={2} justify="space-between" direction="row" align="center">
                <h1>Service vetting</h1>
                <span>{endSlot}</span>
            </Flexbox>
            <StyledOpsVettingTabs fullWidth gap={2}>
                {ServiceVettingSteps.map(step => (
                    <NavLink key={step.label} to={generatePath(step.path, {id: vettingId})}>
                        {step.label}
                    </NavLink>
                ))}
            </StyledOpsVettingTabs>
        </StyledOpsVettingHeader>
    )
}
