import {
    OpsVettingLabel,
    OpsVettingParagraph,
    OpsVettingQuestionsGrid,
    OpsVettingSectionTitle,
    OpsVettingSectionWrapper
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {useUpdateServiceExpertiseStatus} from '@/features/service-vetting/query/useUpdateServiceExpertiseStatus.ts'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ServiceVettingConceptStatusSelector} from '@/features/service-vetting/components/service-vetting-concept-status-selector/ServiceVettingConceptStatusSelector.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {Avatar} from '@components/ui/avatar/Avatar.tsx'
import {RemappedService} from '@/features/service-vetting/types.ts'
import {StyledServiceVettingExpertiseOrderedList} from '@/features/service-vetting/components/service-vetting-concept-host-expertise/style.ts'
import {ObjectEntries} from '@utilities/helpers.ts'
import {groupByCountry} from '@/features/service-vetting/utils.ts'
import {ServiceVettingConceptFlags} from '@/features/service-vetting/components/service-vetting-concept-flags/ServiceVettingConceptFlags.tsx'
import {useAddServiceExpertiseFlags} from '@/features/service-vetting/query/useAddServiceExpertiseFlags.ts'
import {useRemoveServiceExpertiseFlags} from '@/features/service-vetting/query/useRemoveServiceExpertiseFlags.ts'
import {useAddServiceExpertiseAnswerFlags} from '@/features/service-vetting/query/useAddServiceExpertiseAnswerFlags.ts'
import {useRemoveServiceExpertiseAnswerFlags} from '@/features/service-vetting/query/useRemoveServiceExpertiseAnswerFlags.ts'
import {useServiceVettingError} from '@/features/service-vetting/store/useServiceVettingError.ts'
import {Chip} from '@components/commons/chip/Chip.tsx'
import {AlertTriangleIcon} from '@components/ui/icon/Icon.tsx'

export const ServiceVettingConceptHostExpertise = ({service}: {service: RemappedService}) => {
    const {t} = useTranslation()
    const updateExpertiseStatus = useUpdateServiceExpertiseStatus({
        serviceId: service.id,
        expertiseId: service.expertise.id
    })
    const errors = useServiceVettingError(state => state.errors)
    const addExpertiseFlags = useAddServiceExpertiseFlags({serviceId: service.id, itemId: service.expertise.id})
    const removeExpertiseFlags = useRemoveServiceExpertiseFlags({serviceId: service.id, itemId: service.expertise.id})

    const addExpertiseQuestionFlags = useAddServiceExpertiseAnswerFlags({
        serviceId: service.id,
        sectionId: service.expertise.id
    })
    const removeExpertiseQuestionFlags = useRemoveServiceExpertiseAnswerFlags({
        serviceId: service.id,
        sectionId: service.expertise.id
    })

    const groupedCities = groupByCountry(service.submission_cities)

    const isDeclined = service.expertise.status == 'declined'
    const isBackToDraft = service.expertise.status == 'back_to_draft'
    const hideAction = service.status != 'under_review'
    return (
        <OpsVettingSectionWrapper fullWidth direction={'column'} gap={6}>
            <Flexbox fullWidth justify="space-between" align={'center'}>
                <Flexbox style={{flexShrink: 0}} gap={8} align={'center'}>
                    <OpsVettingSectionTitle>{t('vetting:concept:service:expertise:title')}</OpsVettingSectionTitle>
                    <ServiceVettingConceptStatusSelector
                        value={service.expertise.status}
                        onChange={status => updateExpertiseStatus.mutate({status})}
                        disabled={hideAction}
                    />
                </Flexbox>
                <Flexbox gap={4} align={'center'}>
                    {errors.expertiseErrors.map((error, index) => (
                        <Chip
                            key={index}
                            label={t(error)}
                            variant={'danger'}
                            isActive
                            startSlot={<AlertTriangleIcon />}
                        />
                    ))}
                    {isDeclined && (
                        <ServiceVettingConceptFlags
                            flags={service.expertise.flags}
                            addFlag={flag => addExpertiseFlags.mutate({flags: [flag]})}
                            removeFlag={flag => removeExpertiseFlags.mutate({flags: [flag]})}
                            flagPosition={'left'}
                            badgeVariant={'danger'}
                            types={['decline_expertise']}
                            hideAction={hideAction}
                        />
                    )}
                </Flexbox>
            </Flexbox>
            <Divider direction="horizontal" />
            <OpsVettingQuestionsGrid>
                <Flexbox direction={'column'} gap={6} fullWidth>
                    <Flexbox direction="column" gap={2}>
                        <OpsVettingLabel>{t('vetting:concept:service:expertise:experience_years')}</OpsVettingLabel>
                        <OpsVettingParagraph>
                            {service.expertise.question_answer.experience_years.answer}
                        </OpsVettingParagraph>
                        {isBackToDraft && (
                            <ServiceVettingConceptFlags
                                flags={service.expertise.question_answer.experience_years.flags}
                                addFlag={flag =>
                                    addExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: service.expertise.question_answer.experience_years.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: service.expertise.question_answer.experience_years.id
                                    })
                                }
                                types={['host_expertise']}
                                hideAction={hideAction}
                            />
                        )}
                    </Flexbox>

                    <Flexbox direction={'column'} gap={6} fullWidth>
                        <Flexbox direction="column" gap={2}>
                            <OpsVettingLabel>{t('vetting:concept:service:expertise:summary')}</OpsVettingLabel>
                            <OpsVettingParagraph>
                                {service.expertise.question_answer.summary.answer}
                            </OpsVettingParagraph>
                            {isBackToDraft && (
                                <ServiceVettingConceptFlags
                                    flags={service.expertise.question_answer.summary.flags}
                                    addFlag={flag =>
                                        addExpertiseQuestionFlags.mutate({
                                            flags: [flag],
                                            itemId: service.expertise.question_answer.summary.id
                                        })
                                    }
                                    removeFlag={flag =>
                                        removeExpertiseQuestionFlags.mutate({
                                            flags: [flag],
                                            itemId: service.expertise.question_answer.summary.id
                                        })
                                    }
                                    types={['general', 'host_expertise']}
                                    hideAction={hideAction}
                                />
                            )}
                        </Flexbox>
                    </Flexbox>
                    <Flexbox direction={'column'} gap={6} fullWidth>
                        <Flexbox direction="column" gap={2}>
                            <OpsVettingLabel>{t('vetting:concept:service:expertise:highlights')}</OpsVettingLabel>
                            <OpsVettingParagraph>
                                {service.expertise.question_answer.highlights.answer}
                            </OpsVettingParagraph>
                            {isBackToDraft && (
                                <ServiceVettingConceptFlags
                                    flags={service.expertise.question_answer.highlights.flags}
                                    addFlag={flag =>
                                        addExpertiseQuestionFlags.mutate({
                                            flags: [flag],
                                            itemId: service.expertise.question_answer.highlights.id
                                        })
                                    }
                                    removeFlag={flag =>
                                        removeExpertiseQuestionFlags.mutate({
                                            flags: [flag],
                                            itemId: service.expertise.question_answer.highlights.id
                                        })
                                    }
                                    types={['general', 'host_expertise']}
                                    hideAction={hideAction}
                                />
                            )}
                        </Flexbox>
                    </Flexbox>
                    <Flexbox direction={'column'} gap={6} fullWidth>
                        <Flexbox direction="column" gap={2}>
                            <OpsVettingLabel>{t('vetting:concept:service:expertise:listing_title')}</OpsVettingLabel>
                            <OpsVettingParagraph>
                                {service.expertise.question_answer.inspiration.answer}
                            </OpsVettingParagraph>
                            {isBackToDraft && (
                                <ServiceVettingConceptFlags
                                    flags={service.expertise.question_answer.inspiration.flags}
                                    addFlag={flag =>
                                        addExpertiseQuestionFlags.mutate({
                                            flags: [flag],
                                            itemId: service.expertise.question_answer.inspiration.id
                                        })
                                    }
                                    removeFlag={flag =>
                                        removeExpertiseQuestionFlags.mutate({
                                            flags: [flag],
                                            itemId: service.expertise.question_answer.inspiration.id
                                        })
                                    }
                                    types={['general', 'title', 'host_expertise']}
                                    hideAction={hideAction}
                                />
                            )}
                        </Flexbox>
                    </Flexbox>
                    <Flexbox direction={'column'} gap={6} fullWidth>
                        <Flexbox direction="column" gap={2}>
                            <OpsVettingLabel>{t('vetting:concept:service:expertise:background')}</OpsVettingLabel>
                            <OpsVettingParagraph>
                                {service.expertise.question_answer.background.answer}
                            </OpsVettingParagraph>
                            {isBackToDraft && (
                                <ServiceVettingConceptFlags
                                    flags={service.expertise.question_answer.background.flags}
                                    addFlag={flag =>
                                        addExpertiseQuestionFlags.mutate({
                                            flags: [flag],
                                            itemId: service.expertise.question_answer.background.id
                                        })
                                    }
                                    removeFlag={flag =>
                                        removeExpertiseQuestionFlags.mutate({
                                            flags: [flag],
                                            itemId: service.expertise.question_answer.background.id
                                        })
                                    }
                                    types={['general', 'host_expertise']}
                                    hideAction={hideAction}
                                />
                            )}
                        </Flexbox>
                    </Flexbox>
                </Flexbox>

                <Divider direction={'vertical'} />

                <Flexbox direction={'column'} gap={6} fullWidth>
                    <Flexbox direction="column" gap={2}>
                        <OpsVettingLabel>{t('vetting:concept:service:expertise:profile_picture')}</OpsVettingLabel>
                        <Avatar
                            size={'lg'}
                            name={service.host.first_name}
                            imageUrl={service.host.profile_photo_url || ''}
                        />
                        {isBackToDraft && (
                            <ServiceVettingConceptFlags
                                flags={service.expertise.question_answer.profile_photo.flags}
                                addFlag={flag =>
                                    addExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: service.expertise.question_answer.profile_photo.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: service.expertise.question_answer.profile_photo.id
                                    })
                                }
                                types={['profile_photo']}
                                hideAction={hideAction}
                            />
                        )}
                    </Flexbox>

                    {service.expertise.question_answer.links.answer.length > 0 && (
                        <Flexbox direction="column" gap={2}>
                            <OpsVettingLabel>{t('vetting:concept:service:expertise:links')}</OpsVettingLabel>
                            <StyledServiceVettingExpertiseOrderedList>
                                {service.expertise.question_answer.links.answer.map((link, index) => (
                                    <li key={index}>
                                        <a href={link} target="_blank">
                                            <OpsVettingParagraph>{link}</OpsVettingParagraph>
                                        </a>
                                    </li>
                                ))}
                            </StyledServiceVettingExpertiseOrderedList>
                            {isBackToDraft && (
                                <ServiceVettingConceptFlags
                                    flags={service.expertise.question_answer.links.flags}
                                    addFlag={flag =>
                                        addExpertiseQuestionFlags.mutate({
                                            flags: [flag],
                                            itemId: service.expertise.question_answer.links.id
                                        })
                                    }
                                    removeFlag={flag =>
                                        removeExpertiseQuestionFlags.mutate({
                                            flags: [flag],
                                            itemId: service.expertise.question_answer.links.id
                                        })
                                    }
                                    types={['host_expertise']}
                                    hideAction={hideAction}
                                />
                            )}
                        </Flexbox>
                    )}

                    <Flexbox direction="column" gap={2}>
                        <OpsVettingLabel>{t('vetting:concept:service:expertise:location')}</OpsVettingLabel>
                        <OpsVettingParagraph>{service.expertise.question_answer.location.answer}</OpsVettingParagraph>
                        {isBackToDraft && (
                            <ServiceVettingConceptFlags
                                flags={service.expertise.question_answer.location.flags}
                                addFlag={flag =>
                                    addExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: service.expertise.question_answer.location.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: service.expertise.question_answer.location.id
                                    })
                                }
                                types={['location']}
                                hideAction={hideAction}
                            />
                        )}
                    </Flexbox>

                    <Flexbox direction="column" gap={2}>
                        <OpsVettingLabel>{t('vetting:concept:service:expertise:areas')}</OpsVettingLabel>
                        <StyledServiceVettingExpertiseOrderedList>
                            {service.submission_places.length > 0
                                ? service.submission_places.map(place => (
                                      <li key={place.place.name}>
                                          <OpsVettingParagraph>{place.place.name}</OpsVettingParagraph>
                                      </li>
                                  ))
                                : ObjectEntries(groupedCities).map(([country, cities]) => (
                                      <li key={country}>
                                          <OpsVettingParagraph>{`${country}: ${cities.join(', ')}`}</OpsVettingParagraph>
                                      </li>
                                  ))}
                        </StyledServiceVettingExpertiseOrderedList>
                        {isBackToDraft && (
                            <ServiceVettingConceptFlags
                                flags={service.expertise.question_answer.cities.flags}
                                addFlag={flag =>
                                    addExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: service.expertise.question_answer.cities.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeExpertiseQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: service.expertise.question_answer.cities.id
                                    })
                                }
                                types={['location']}
                                hideAction={hideAction}
                            />
                        )}
                    </Flexbox>
                </Flexbox>
            </OpsVettingQuestionsGrid>
        </OpsVettingSectionWrapper>
    )
}
