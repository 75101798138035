import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'

export const StyledNoCoverPhotoContainer = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        background: ${palette.neutral[100]};
        aspect-ratio: 1 / 1;
        width: 100%;
        border-radius: 8px;
        & h4 {
            ${typography.textLg};
            color: ${palette.neutral[900]};
            font-weight: 500;
        }
        & svg {
            color: ${palette.neutral[900]};
        }
    `
)

export const StyledCoverPhotoWrapper = styled.div(
    () => css`
        position: relative;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        width: 100%;
        border-radius: 8px;
    `
)

export const StyledEditButton = styled(Button)(
    () => css`
        position: absolute;
        right: 10px;
        top: 10px;
    `
)
