import {FC, useEffect, useRef} from 'react'
import {StyledFlagsPopoverItem} from '@/features/photoset/components/flags-popover/style'
import {FlagWithLabel, Image, Photoset} from '@/features/photoset/types'
import {Checkbox} from '@/components/commons/checkbox/ChekBox'
import {HelpCircleIcon} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'
import {Tooltip} from '@/components/ui/tooltip/Tooltip'
import {useTranslation} from 'react-i18next'
import {useAddImagesFlag} from '@/features/photoset/queries/useAddImagesFlag'
import {RemoveImagesFlagData, useRemoveImagesFlag} from '@/features/photoset/queries/useRemoveImagesFlag'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {errorHandler} from '@/utilities/helpers'

interface FlagsPopoverItemProps {
    selectedImagesIds: Image['id'][]
    flag: FlagWithLabel
    stepId: Photoset['step_id']
    defaultChecked?: boolean
    isIndeterminate?: boolean
    photosetSpaceIds?: number[]
    isUpdateDisabled?: boolean
    hideDescription?: boolean
    onRemoveFlagCb?: (data: RemoveImagesFlagData) => void
}

export const FlagsPopoverItem: FC<FlagsPopoverItemProps> = ({
    selectedImagesIds,
    flag,
    stepId,
    defaultChecked = false,
    isIndeterminate = false,
    photosetSpaceIds,
    isUpdateDisabled = false,
    hideDescription = false,
    onRemoveFlagCb
}) => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const checkboxRef = useRef<HTMLInputElement | null>(null)
    const addImagesFlag = useAddImagesFlag({
        photosetSpaceIds,
        stepId,
        options: {
            onError: errorHandler
        }
    })

    const removeImagesFlag = useRemoveImagesFlag({
        photosetSpaceIds,
        stepId,
        options: {
            onError: errorHandler,
            onSuccess: onRemoveFlagCb
        }
    })

    useEffect(() => {
        if (checkboxRef?.current && isIndeterminate) {
            checkboxRef.current.indeterminate = true
        }
    }, [checkboxRef, isIndeterminate])

    const handleUpdateFlagToImages = () => {
        const mutationFunction = defaultChecked ? removeImagesFlag : addImagesFlag
        mutationFunction.mutate({flag, imageIds: selectedImagesIds})
    }

    return (
        <StyledFlagsPopoverItem key={flag.id} gap={2} justify="space-between">
            <Flexbox gap={2} align="center">
                <Checkbox
                    ref={checkboxRef}
                    defaultChecked={defaultChecked}
                    onChange={handleUpdateFlagToImages}
                    disabled={(addImagesFlag.isPending && !removeImagesFlag.isPending) || isUpdateDisabled}
                />
                <span>{flag.label}</span>
            </Flexbox>

            {!hideDescription && (
                <Tooltip
                    contentProps={{sideOffset: 8, align: 'end'}}
                    variant="light"
                    content={<p>{t(`photoset:flags:${flag.key_name}:description`)}</p>}
                >
                    <HelpCircleIcon color={palette.neutral[400]} size={16} />
                </Tooltip>
            )}
        </StyledFlagsPopoverItem>
    )
}
