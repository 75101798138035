import {zodResolver} from '@hookform/resolvers/zod'
import {useForm, useFieldArray, SubmitHandler} from 'react-hook-form'
import {ServiceMerchandisingInputSchema} from '@/features/service-vetting/formSchemas.ts'
import {
    StyledServiceMerchVettingFormHostSection,
    StyledServiceMerchVettingFormOfferingSection,
    StyledServiceVettingMerchInputForm
} from './style'
import {InputText} from '@/components/commons/input-text/InputText'
import {TextArea} from '@/components/commons/textarea/TextArea'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Divider} from '@/components/ui/divider/Divider'
import {User01Icon} from '@/components/ui/icon/Icon'
import {ServiceVettingMerchFormHeader} from '../service-vetting-merch-form-header/ServiceVettingMerchFormHeader'
import {RefObject} from 'react'
import {getOfferingTypeLabel} from '@/features/service-vetting/utils'
import {useTranslation} from 'react-i18next'

interface ServiceVettingMerchInputFormProps {
    isReadOnly?: boolean
    defaultValues: Partial<ServiceMerchandisingInputSchema>
    aiSubmissionRef: RefObject<HTMLButtonElement>
    handleAiSubmissionCb: SubmitHandler<ServiceMerchandisingInputSchema>
}

export const ServiceVettingMerchInputForm = ({
    isReadOnly = false,
    defaultValues,
    aiSubmissionRef,
    handleAiSubmissionCb
}: ServiceVettingMerchInputFormProps) => {
    const {t} = useTranslation()
    const {
        register,
        control,
        formState: {errors},
        handleSubmit
    } = useForm<ServiceMerchandisingInputSchema>({
        resolver: zodResolver(ServiceMerchandisingInputSchema),
        defaultValues: defaultValues
    })
    const {fields} = useFieldArray({control, name: 'offerings'})

    return (
        <StyledServiceVettingMerchInputForm onSubmit={handleSubmit(handleAiSubmissionCb)}>
            <ServiceVettingMerchFormHeader
                icon={<User01Icon />}
                title={t('vetting:merch:service:input_form_title')}
                description={t('vetting:merch:service:input_form_description')}
            />
            <Flexbox direction={'column'} gap={2} fullWidth>
                <StyledServiceMerchVettingFormHostSection direction={'column'} gap={4} fullWidth>
                    <h4>
                        {t('vetting:merch:service:host_years_of_experience', {
                            years: defaultValues?.host_experience ?? '-'
                        })}
                    </h4>
                    <TextArea
                        disabled={isReadOnly}
                        rows={4}
                        label={t('vetting:merch:service:experience_summary_label')}
                        {...register('host_experience_summary')}
                        errorMessage={errors?.host_experience_summary?.message}
                    />
                    <TextArea
                        disabled={isReadOnly}
                        rows={4}
                        label={t('vetting:merch:service:experience_highlight_label')}
                        {...register('host_experience_highlight')}
                        errorMessage={errors?.host_experience_highlight?.message}
                    />
                    <TextArea
                        disabled={isReadOnly}
                        rows={4}
                        label={t('vetting:merch:service:experience_background_label')}
                        {...register('host_experience_background')}
                        errorMessage={errors?.host_experience_background?.message}
                    />
                </StyledServiceMerchVettingFormHostSection>

                <Divider direction={'horizontal'} />

                <StyledServiceMerchVettingFormOfferingSection direction={'column'} gap={5} fullWidth>
                    <h4>Service</h4>
                    <InputText
                        disabled={isReadOnly}
                        type={'text'}
                        label="Listing title"
                        {...register(`service_title`)}
                    />
                    <InputText disabled={isReadOnly} type={'text'} label="Byline" {...register(`service_byline`)} />

                    {fields.map((offering, index) => (
                        <Flexbox fullWidth direction={'column'} gap={2} key={offering.id}>
                            <Flexbox align={'center'} gap={4}>
                                <p>
                                    {t(getOfferingTypeLabel(offering.type))}
                                    {t('vetting:merch:service:offering_duration', {
                                        duration: offering.duration ?? '-'
                                    })}
                                </p>
                            </Flexbox>
                            <InputText
                                disabled={isReadOnly}
                                type={'text'}
                                label="Title"
                                {...register(`offerings.${index}.title`)}
                            />
                            <TextArea
                                disabled={isReadOnly}
                                rows={4}
                                label={t('vetting:merch:service:offering_description_label')}
                                {...register(`offerings.${index}.description`)}
                            />
                        </Flexbox>
                    ))}
                </StyledServiceMerchVettingFormOfferingSection>
            </Flexbox>
            <button type="submit" ref={aiSubmissionRef} style={{display: 'none'}} />
        </StyledServiceVettingMerchInputForm>
    )
}

ServiceVettingMerchInputForm.displayName = 'ServiceVettingMerchInputForm'
