import {useDropzone} from 'react-dropzone'
import {useTranslation} from 'react-i18next'
import {StyledManualRetouchingDropzone, StyledManualRetouchingTrigger} from './style'
import {useEffect, useState} from 'react'
import {ManualRetouchingUploadCard} from '@/features/photoset/components/manual-retouching-upload-card/ManualRetouchingUploadCard'
import {useBulkUploadPhotos} from '@/features/photoset/queries/useBulkUploadPhotos'
import {PhotoPresignedData, Photoset} from '@/features/photoset/types'
import {useUploadPhoto} from '@/features/photoset/queries/useUploadPhoto'
import {QUERY_KEYS, queryClient} from '@/queryClient'
import {UseMutateAsyncFunction} from '@tanstack/react-query'
import {AxiosError, AxiosResponse} from 'axios'

export const ManualRetouchingUploader = ({
    photosetId,
    isDisabled,
    onUploadCb
}: {
    photosetId: number
    isDisabled: boolean
    onUploadCb: UseMutateAsyncFunction<AxiosResponse, AxiosError, Photoset['id']>
}) => {
    const {t} = useTranslation()
    const [uploadingFiles, setUploadingFiles] = useState<File[] | null>(null)
    const [uploadedImagesCounter, setUploadedImagesCounter] = useState(0)
    const [progress, setProgress] = useState(0)

    const uploadPhotoMutation = useUploadPhoto({
        photosetId,
        onUploadProgress: progressEvent => {
            if (!progressEvent.total) return
            setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        },
        options: {
            onSettled: () => setProgress(0),
            onSuccess: () => setUploadedImagesCounter(prev => prev + 1)
        }
    })

    const bulkUploadPhotosMutation = useBulkUploadPhotos({
        photosetId,
        options: {
            onSuccess: async (data: PhotoPresignedData[]) => {
                for (const [index, presignedItem] of data.entries()) {
                    if (uploadingFiles?.[index]) {
                        await uploadPhotoMutation.mutateAsync({
                            imageId: presignedItem.media.id,
                            payload: {
                                presignedUrl: presignedItem.presigned_url,
                                file: uploadingFiles[index]
                            }
                        })
                    }
                }
            },
            onError: () => {
                setUploadingFiles(null)
                setUploadedImagesCounter(0)
            }
        }
    })

    const dropzoneState = useDropzone({
        disabled: isDisabled,
        accept: {
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg']
        },
        multiple: true,
        maxFiles: 999,
        onDropAccepted: acceptedFiles => {
            setUploadingFiles(acceptedFiles)
            bulkUploadPhotosMutation.mutate({medias: acceptedFiles.map(file => file.name)})
        },
        onDropRejected: rejectedFiles => {
            console.error('Rejected files: ', rejectedFiles)
        }
    })

    const handleUpdateStep = async () => {
        await onUploadCb(photosetId)
        void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
        void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.MANUAL_RETOUCHING_SPACES]})
        void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET_SPACES]})
    }

    useEffect(() => {
        if (uploadingFiles && uploadingFiles.length == uploadedImagesCounter) {
            handleUpdateStep()
        }
    }, [uploadingFiles, uploadedImagesCounter])

    if (uploadingFiles) {
        return (
            <ManualRetouchingUploadCard
                progress={progress}
                totalUploadingImagesCounter={uploadingFiles?.length || 0}
                uploadedImagesCounter={uploadedImagesCounter}
                isBulkUploadPhotosPending={bulkUploadPhotosMutation.isPending}
                title={t('commons:generating_thumbnails')}
                subtitle={t('commons:generating_waiting_text')}
            />
        )
    }

    return (
        <>
            <StyledManualRetouchingDropzone state={dropzoneState} $isDisabled={isDisabled}>
                <StyledManualRetouchingTrigger direction="column" gap={1} align="center" justify="center" fullWidth>
                    <h3>{t('commons:drop_jpgs_here')}</h3>
                    <em>{t('commons:or')}</em>
                    <p>{t('commons:upload_from_your_device')}</p>
                </StyledManualRetouchingTrigger>
            </StyledManualRetouchingDropzone>
        </>
    )
}
