import {captureException} from '@sentry/react'
import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {MUTATION_KEYS} from 'src/queryClient'
import {httpUploadPhoto, httpUploadPhotoConfirm, HttpUploadPhotoOptions} from '../http/photoset.http'
import {parseAxiosPromise} from '@/axiosInstance'
import {errorHandler} from '@/utilities/helpers'
import {AxiosProgressEvent} from 'axios'
import {unknown} from 'zod'

export type useUploadPhoto = {
    photosetId: number
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void | undefined
    options?: UseMutationOptions<unknown, unknown, {payload: HttpUploadPhotoOptions['payload']; imageId: number}>
}

export const useUploadPhoto = ({photosetId, onUploadProgress, options}: useUploadPhoto) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.CONFIRM_UPLOAD_PHOTO],
        mutationFn: ({payload}) =>
            parseAxiosPromise({
                axiosPromise: httpUploadPhoto({
                    payload,
                    onUploadProgress
                }),
                responseShape: unknown(),
                onZodError: captureException
            }),
        ...options,
        onError: (error, variables, context) => {
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onSuccess: async (data, variables, context) => {
            await httpUploadPhotoConfirm({
                urlParams: {
                    photosetId,
                    imageId: variables.imageId
                }
            })

            options?.onSuccess?.(data, variables, context)
        }
    })
}
