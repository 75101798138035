import {FC, ReactNode, useRef, useState} from 'react'
import {Button} from '@components/ui/button/Button'
import {Divider} from '@components/ui/divider/Divider'
import {XCloseIcon} from '@components/ui/icon/Icon'
import {Modal} from '@components/ui/modal/Modal'
import {ModalHeader, ModalFooter} from '@/components/ui/modal-atoms/ModalAtoms'
import {TextArea} from '@/components/commons/textarea/TextArea'
import {StyledCopyPromptModalBody} from '@pages/experience-ai-copy-prep-training/style'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'

interface PromptModalProps {
    trigger: ReactNode
    currentPrompt: string
    onPromptChange: (prompt: string) => void
    isLoading?: boolean
}

export const PromptModal: FC<PromptModalProps> = ({trigger, currentPrompt, onPromptChange, isLoading = false}) => {
    const promptRef = useRef<HTMLTextAreaElement>(null)
    const [isOpen, setIsOpen] = useState(false)

    const handleSave = () => {
        if (promptRef.current) {
            onPromptChange(promptRef.current.value)
            setIsOpen(false)
        }
    }

    return (
        <>
            <div onClick={() => setIsOpen(true)}>{trigger}</div>
            {isOpen && (
                <Modal width={800} closeIconOffset="sm" onOverlayClick={() => setIsOpen(false)}>
                    <ModalHeader>
                        <Button iconOnly size="sm" variant="tertiary" onClick={() => setIsOpen(false)}>
                            <XCloseIcon size={20} />
                        </Button>
                        <h3>This is the prompt that will be sent to AI</h3>
                    </ModalHeader>

                    <Divider direction="horizontal" />

                    <StyledCopyPromptModalBody>
                        <Flexbox direction={'column'} gap={2}>
                            <p>
                                You can customize the prompt to fit your needs. To do so, you can refer to the form
                                fields using the following names:
                            </p>
                            <ul>
                                <li>
                                    <span>serviceTitle</span>: The main title of the service
                                </li>
                                <li>
                                    <span>serviceByline</span>: A brief description/tagline of the service
                                </li>
                                <li>
                                    <span>offerings</span>: An array of service offerings, each containing "title",
                                    "description" and "duration":
                                </li>
                                <li>
                                    <span>hostExperience</span>: Host's primary expertise
                                </li>
                                <li>
                                    <span>hostExperienceSummary</span>: Overview of host's background
                                </li>
                                <li>
                                    <span>hostExperienceHighlight</span>: Key achievements or specialties
                                </li>
                                <li>
                                    <span>hostExperienceBackground</span>: Detailed professional background
                                </li>
                            </ul>
                        </Flexbox>
                        <Divider direction="horizontal" startSpacing={4} endSpacing={4} />
                        <TextArea
                            ref={promptRef}
                            rows={20}
                            label=""
                            placeholder="Customize your prompt here..."
                            defaultValue={currentPrompt}
                        />
                    </StyledCopyPromptModalBody>

                    <ModalFooter>
                        <Button variant="tertiary" onClick={() => setIsOpen(false)} disabled={isLoading}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSave} disabled={isLoading}>
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    )
}
