import styled, {css} from 'styled-components'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'

export const StyledOpsVettingHeader = styled(Flexbox)(
    ({theme: {spacing, palette, typography}}) => css`
        position: relative;
        padding-top: ${spacing * 10}px;
        & h1 {
            color: ${palette.neutral['900']};
            ${typography.displayXs};
            font-weight: 700;
        }
    `
)

export const StyledOpsVettingTabs = styled(Flexbox)(
    ({theme: {spacing, palette, typography}}) => css`
        position: relative;
        padding-bottom: ${spacing * 3}px;
        border-bottom: 1px solid ${palette.neutral['200']};
        box-sizing: border-box;
        & > a {
            ${typography.textMd};
            color: ${palette.neutral['500']};
            padding: 0 ${spacing}px;
            font-weight: 600;
            text-decoration: underline;
            text-decoration-thickness: 2px;
            text-underline-offset: ${spacing * 4.5}px;
            text-decoration-color: transparent;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            &:disabled:hover {
                cursor: not-allowed;
            }
            &:hover {
                color: ${palette.neutral['600']};
            }
            &.active {
                color: ${palette.neutral['700']};
                text-decoration-color: ${palette.neutral['700']};
        }
    `
)
