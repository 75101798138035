import styled, {css} from 'styled-components'
import {Modal} from '@components/ui/modal/Modal.tsx'

export const StyledModal = styled(Modal)(
    () => css`
        display: grid;
        grid-template-rows: repeat(2, auto) minmax(0, 1fr) auto;
        overflow-y: hidden;
        max-height: 680px;
    `
)

export const StyledPhotoGrid = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        padding: ${spacing * 6}px;
        gap: ${spacing * 6}px;
    `
)

export const StyledPhotoWrapper = styled.div(
    () => css`
        position: relative;
        cursor: pointer;
    `
)

export const StyledCheckIconContainer = styled.div(
    ({theme: {palette, opacities}}) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 8px;
        right: 8px;
        background: ${palette.neutral['white']}${opacities[90]};
        height: 32px;
        width: 32px;
        border-radius: 16px;
    `
)
