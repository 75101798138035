import {useTranslation} from 'react-i18next'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {useOutletContext} from 'react-router-dom'
import {PhotosetURLParams} from '@/features/photoset/types.ts'
import {useGetPhotosetImagesList} from '@/features/photoset/queries/useGetPhotosetImagesList'
import {ImageCard} from '@components/commons/image-card/ImageCard.tsx'
import {StyledCurationElementImagesContainer} from '@/features/photoset/components/curation-photoset-space-images/style'
import {useGetPhotoset} from '@/features/photoset/queries/useGetPhotoset'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import {DownloadCloud02Icon} from '@components/ui/icon/Icon.tsx'
import {ExpandImageButton} from '@/components/commons/expand-image-button/ExpandImageButton'
import {useState} from 'react'
import {GalleryShowcaseModal} from '@/features/photoset/components/gallery-photoset-modal/gallery-showcase-modal/GalleryShowcaseModal'
import {StyledCompletedStepsContainer} from '@pages/photoset/photoset-initial-qa-step-v1/style.ts'
import {
    StyledCompletedCopyWrapper,
    StyledCompletedStepsParapraph,
    StyledCompletedStepsTitle
} from '@pages/photoset/photoset-completed-steps/style.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {StyledPublicShowcase} from '@pages/photoset/photoset-public-showcase/style'
import {
    PhotosetStepBodyContainer,
    PhotosetStepWrapper
} from '@/features/photoset/components/photoset-step-atoms/PhotosetStepAtoms'

export const PhotosetPublicShowcase = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const [currentDetailImageIndex, setCurrentDetailImageIndex] = useState<number | null>(null)
    //queries
    const {data: photoset, isFetching: isFetchingPhotoset} = useGetPhotoset({id: urlParams.id})
    const {data: images, isFetching: isFetchingImages} = useGetPhotosetImagesList({
        urlParams: {photosetId: urlParams.id}
    })
    const isFetching = isFetchingPhotoset || isFetchingImages

    if (!isFetching && !photoset?.thumbnail_generated_at) {
        return (
            <StyledCompletedStepsContainer>
                <StyledCompletedCopyWrapper direction="column" gap={3} align="center">
                    <Spinner size={32} color={'#000'} />
                    <StyledCompletedStepsTitle>{t('photoset:show:thumb_gen_title')}</StyledCompletedStepsTitle>
                    <StyledCompletedStepsParapraph>
                        {t('photoset:show:thumb_gen_subtitle')}
                    </StyledCompletedStepsParapraph>
                </StyledCompletedCopyWrapper>
            </StyledCompletedStepsContainer>
        )
    }

    return (
        <StyledPublicShowcase>
            <PageHero
                title={t('photoset:show:title')}
                subtitle={t('photoset:show:paragraph')}
                endSlot={
                    photoset?.zip_url && (
                        <ButtonLink size="sm" variant="secondary" to={photoset.zip_url} disabled={isFetching}>
                            {t('photoset:imagen_ai:completed:download_all_set')}
                            <DownloadCloud02Icon />
                        </ButtonLink>
                    )
                }
            />

            <PhotosetStepWrapper>
                <PhotosetStepBodyContainer fullWidth>
                    <StyledCurationElementImagesContainer>
                        {isFetching && [...Array(20).keys()].map(e => <ImageCard asSkeleton key={`${e}-id`} />)}
                        {!isFetching &&
                            images?.map((image, index) => (
                                <ImageCard
                                    hideCheckbox
                                    image={image}
                                    key={image.id}
                                    thumbnail={image.thumbnails?.xs}
                                    isSelected={false}
                                    onSelect={() => setCurrentDetailImageIndex(index)}
                                    endSlot={
                                        image.thumbnails?.xs && (
                                            <ExpandImageButton onClick={() => setCurrentDetailImageIndex(index)} />
                                        )
                                    }
                                />
                            ))}
                    </StyledCurationElementImagesContainer>
                </PhotosetStepBodyContainer>

                {currentDetailImageIndex != null && images && (
                    <GalleryShowcaseModal
                        currentDetailImageIndex={currentDetailImageIndex}
                        setCurrentDetailImageIndex={setCurrentDetailImageIndex}
                        selectableImages={images}
                        onClose={() => setCurrentDetailImageIndex(null)}
                    />
                )}
            </PhotosetStepWrapper>
        </StyledPublicShowcase>
    )
}

PhotosetPublicShowcase.displayName = 'PhotosetCurationStep'
