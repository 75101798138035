import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StyledSelectPopover} from '@components/ui/select-atoms/style.ts'

export const StyledOpsVettingFlagsSelectSearchInputWrapper = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 3}px ${spacing * 3.5}px;
        & svg {
            color: ${palette.neutral[500]};
        }
    `
)

export const StyledOpsVettingFlagsSelectSearchInput = styled.input(
    ({theme: {typography}}) => css`
        ${typography.textMd};
        width: 100%;
    `
)

export const StyledOpsVettingFlagsSelectPopover = styled(StyledSelectPopover)(
    () => css`
        padding: 0;
        width: 320px;
        display: grid;
        grid-template-rows: auto auto minmax(0, 1fr);
    `
)

export const StyledOpsVettingFlagsSelectItem = styled(Flexbox)(
    ({theme: {palette, spacing, typography}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral['700']};
        padding: ${spacing * 2}px ${spacing * 3}px;
        transition: ease-in-out 150ms;
        width: 100%;
        word-break: break-word;
    `
)
