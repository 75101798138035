import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpDeleteManualRetouchedImages} from '@/features/photoset/http/photoset.http'
import {errorHandler} from '@/utilities/helpers'

export const useDeleteManualRetouchedImages = ({
    photosetId,
    options
}: {
    photosetId: number
    options?: UseMutationOptions
}) => {
    return useMutation({
        ...options,
        mutationFn: () => httpDeleteManualRetouchedImages({urlParams: {photosetId}}),
        onError: errorHandler,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context)
        }
    })
}
