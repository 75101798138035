import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpUpdateStepToQaReviewFlags} from '@/features/photoset/http/photoset.http'
import {MUTATION_KEYS} from '@/queryClient.ts'
import {AxiosError} from 'axios'

export const useUpdateStepToQaReviewFlags = (
    options?: Omit<
        UseMutationOptions<
            unknown,
            AxiosError,
            {
                photosetId: number
            }
        >,
        'mutationFn' | 'mutationKey'
    >
) =>
    useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.UPDATE_STEP_REVIEW_QA_FLAGS],
        mutationFn: variables => httpUpdateStepToQaReviewFlags(variables.photosetId)
    })
