import {
    OpsVettingCardBodyContainer,
    OpsVettingCardContainer,
    OpsVettingCardTitle,
    OpsVettingImage,
    OpsVettingImgPlaceHolder,
    OpsVettingLabel,
    OpsVettingParagraph
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {RemappedOffering} from '@/features/service-vetting/types.ts'
import {formatCurrency} from '@utilities/helpers.ts'
import {AlertTriangleIcon, CheckIcon} from '@components/ui/icon/Icon.tsx'
import {useTheme} from 'styled-components'
import {ServiceVettingConceptStatusSelector} from '@/features/service-vetting/components/service-vetting-concept-status-selector/ServiceVettingConceptStatusSelector.tsx'
import {useUpdateAdminServiceOfferingStatus} from '@/features/service-vetting/query/useUpdateServiceOfferingsStatus.ts'
import {useAddServiceOfferingAnswerFlags} from '@/features/service-vetting/query/useAddServiceOfferingAnswerFlags.ts'
import {ServiceVettingConceptFlags} from '@/features/service-vetting/components/service-vetting-concept-flags/ServiceVettingConceptFlags.tsx'
import {useRemoveServiceOfferingAnswerFlags} from '@/features/service-vetting/query/useRemoveServiceOfferingAnswerFlags.ts'
import {useAddServiceOfferingFlags} from '@/features/service-vetting/query/useAddServiceOfferingFlags.ts'
import {useRemoveServiceOfferingFlags} from '@/features/service-vetting/query/useRemoveServiceOfferingFlags.ts'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {Chip} from '@components/commons/chip/Chip.tsx'
import {useServiceVettingError} from '@/features/service-vetting/store/useServiceVettingError.ts'
import {ServiceVettingConceptEditOfferingPhotoButton} from '@/features/service-vetting/components/service-vetting-concept-edit-offering-photo-button/ServiceVettingConceptEditOfferingPhotoButton.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useRestoreMainPhotoOffering} from '@/features/service-vetting/query/useRestoreMainPhotoOffering.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

export const ServiceVettingConceptOfferingCard = ({
    offering,
    index,
    hideAction
}: {
    offering: RemappedOffering
    index: number
    hideAction?: boolean
}) => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const updateOfferingStatus = useUpdateAdminServiceOfferingStatus({
        serviceId: offering.submission_id,
        offeringId: offering.id
    })

    const restoreMainPhotoOffering = useRestoreMainPhotoOffering({
        offeringId: offering.id,
        serviceId: offering.submission_id
    })

    const errors = useServiceVettingError(state => state.errors)

    const addOfferingQuestionFlags = useAddServiceOfferingAnswerFlags({
        serviceId: offering.submission_id,
        sectionId: offering.id
    })
    const removeOfferingQuestionFlags = useRemoveServiceOfferingAnswerFlags({
        serviceId: offering.submission_id,
        sectionId: offering.id
    })

    const addOfferingFlags = useAddServiceOfferingFlags({
        serviceId: offering.submission_id,
        itemId: offering.id
    })
    const removeOfferingFlags = useRemoveServiceOfferingFlags({
        serviceId: offering.submission_id,
        itemId: offering.id
    })

    const isBackToDraft = offering.status == 'back_to_draft'
    const isDeclined = offering.status == 'declined'

    const onClickRestore = () => {
        restoreMainPhotoOffering.mutate()
    }

    return (
        <OpsVettingCardContainer direction="column" gap={2}>
            <Flexbox fullWidth justify={'space-between'} align={'center'} gap={2}>
                <Flexbox gap={8} align={'center'}>
                    <OpsVettingCardTitle>
                        {t(`vetting:concept:service:offerings:offering_type:${offering.type}`, {count: index + 1})}
                    </OpsVettingCardTitle>
                    <ServiceVettingConceptStatusSelector
                        value={offering.status}
                        onChange={status => updateOfferingStatus.mutate({status})}
                        disabled={hideAction}
                    />
                </Flexbox>
                {errors.offeringErrors
                    .filter(error => error.offeringId == offering.id)
                    .map((error, index) => (
                        <Chip
                            key={index}
                            label={t(error.error)}
                            variant={'danger'}
                            isActive
                            startSlot={<AlertTriangleIcon />}
                        />
                    ))}
            </Flexbox>
            <OpsVettingCardBodyContainer>
                <Flexbox direction="column" fullWidth gap={2.5}>
                    <Flexbox direction="column" gap={2} fullWidth>
                        <OpsVettingLabel>{t('vetting:concept:service:offerings:offering_title')}</OpsVettingLabel>
                        <OpsVettingParagraph>{offering.question_answer.title.answer}</OpsVettingParagraph>
                        {isBackToDraft && (
                            <ServiceVettingConceptFlags
                                flags={offering.question_answer.title.flags}
                                addFlag={flag =>
                                    addOfferingQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: offering.question_answer.title.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeOfferingQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: offering.question_answer.title.id
                                    })
                                }
                                types={['general']}
                                hideAction={hideAction}
                            />
                        )}
                    </Flexbox>
                    <Flexbox direction="column" gap={2} fullWidth>
                        <OpsVettingLabel>{t('vetting:concept:service:offerings:price')}</OpsVettingLabel>
                        <OpsVettingParagraph>
                            {t('vetting:concept:service:offerings:price_per_guest', {
                                price: formatCurrency(
                                    parseFloat(offering.question_answer.price_per_guest.answer),
                                    offering.question_answer.currency.answer
                                )
                            })}
                        </OpsVettingParagraph>
                        {isBackToDraft && (
                            <ServiceVettingConceptFlags
                                flags={offering.question_answer.price_per_guest.flags}
                                addFlag={flag =>
                                    addOfferingQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: offering.question_answer.price_per_guest.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeOfferingQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: offering.question_answer.price_per_guest.id
                                    })
                                }
                                types={['general']}
                                hideAction={hideAction}
                            />
                        )}
                    </Flexbox>
                    {offering.question_answer.price_per_group && (
                        <Flexbox direction="column" gap={2} fullWidth>
                            <OpsVettingLabel>{t('vetting:concept:service:offerings:private_group')}</OpsVettingLabel>
                            <Flexbox gap={2} align={'center'}>
                                <CheckIcon size={16} color={palette.success[600]} />

                                <OpsVettingParagraph>
                                    {t('vetting:concept:service:offerings:price_per_guest', {
                                        price: formatCurrency(
                                            parseFloat(offering.question_answer.price_per_group.answer),
                                            offering.question_answer.currency.answer
                                        )
                                    })}
                                </OpsVettingParagraph>
                            </Flexbox>
                            {isBackToDraft && (
                                <ServiceVettingConceptFlags
                                    flags={offering.question_answer.price_per_group.flags}
                                    addFlag={flag =>
                                        offering.question_answer.price_per_group &&
                                        addOfferingQuestionFlags.mutate({
                                            flags: [flag],
                                            itemId: offering.question_answer.price_per_group.id
                                        })
                                    }
                                    removeFlag={flag =>
                                        offering.question_answer.price_per_group &&
                                        removeOfferingQuestionFlags.mutate({
                                            flags: [flag],
                                            itemId: offering.question_answer.price_per_group.id
                                        })
                                    }
                                    types={['general']}
                                    hideAction={hideAction}
                                />
                            )}
                        </Flexbox>
                    )}
                    <Flexbox direction="column" gap={2} fullWidth>
                        <OpsVettingLabel>{t('vetting:concept:service:offerings:duration')}</OpsVettingLabel>
                        <OpsVettingParagraph>{offering.question_answer.duration.answer}</OpsVettingParagraph>
                        {isBackToDraft && (
                            <ServiceVettingConceptFlags
                                flags={offering.question_answer.duration.flags}
                                addFlag={flag =>
                                    addOfferingQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: offering.question_answer.duration.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeOfferingQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: offering.question_answer.duration.id
                                    })
                                }
                                types={['general']}
                                hideAction={hideAction}
                            />
                        )}
                    </Flexbox>
                    <Flexbox direction="column" gap={2} fullWidth>
                        <OpsVettingLabel>{t('vetting:concept:service:offerings:description')}</OpsVettingLabel>
                        <OpsVettingParagraph>{offering.question_answer.description.answer}</OpsVettingParagraph>
                        {isBackToDraft && (
                            <ServiceVettingConceptFlags
                                flags={offering.question_answer.description.flags}
                                addFlag={flag =>
                                    addOfferingQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: offering.question_answer.description.id
                                    })
                                }
                                removeFlag={flag =>
                                    removeOfferingQuestionFlags.mutate({
                                        flags: [flag],
                                        itemId: offering.question_answer.description.id
                                    })
                                }
                                types={['general', 'description']}
                                hideAction={hideAction}
                            />
                        )}
                    </Flexbox>
                </Flexbox>
                <Flexbox fullWidth direction={'column'} gap={2}>
                    {offering.question_answer.main_photo?.answer ? (
                        <>
                            <OpsVettingImage src={offering.question_answer.main_photo.answer} />
                            {isBackToDraft && (
                                <ServiceVettingConceptFlags
                                    flags={offering.question_answer.main_photo.flags}
                                    addFlag={flag =>
                                        offering.question_answer.main_photo &&
                                        addOfferingQuestionFlags.mutate({
                                            flags: [flag],
                                            itemId: offering.question_answer.main_photo.id
                                        })
                                    }
                                    removeFlag={flag =>
                                        offering.question_answer.main_photo &&
                                        removeOfferingQuestionFlags.mutate({
                                            flags: [flag],
                                            itemId: offering.question_answer.main_photo.id
                                        })
                                    }
                                    types={['gallery_image']}
                                    hideAction={hideAction}
                                />
                            )}
                        </>
                    ) : (
                        <OpsVettingImgPlaceHolder />
                    )}
                    {offering.status == 'accepted' && !hideAction && (
                        <Flexbox justify={'space-between'} fullWidth>
                            <ServiceVettingConceptEditOfferingPhotoButton
                                offeringTitle={t(`vetting:concept:service:offerings:offering_type:${offering.type}`, {
                                    count: index + 1
                                })}
                                offeringId={offering.id}
                                serviceId={offering.submission_id}
                            />
                            {offering.image_id != offering.original_image_id && (
                                <Button
                                    variant={'tertiary'}
                                    onClick={onClickRestore}
                                    disabled={restoreMainPhotoOffering.isPending}
                                >
                                    {t('vetting:concept:service:offerings:restore_original')}
                                    {restoreMainPhotoOffering.isPending && <Spinner size={20} />}
                                </Button>
                            )}
                        </Flexbox>
                    )}
                </Flexbox>
            </OpsVettingCardBodyContainer>
            {isDeclined && (
                <>
                    <Divider direction={'horizontal'} />
                    <ServiceVettingConceptFlags
                        flags={offering.flags}
                        addFlag={flag => addOfferingFlags.mutate({flags: [flag]})}
                        removeFlag={flag => removeOfferingFlags.mutate({flags: [flag]})}
                        badgeVariant={'danger'}
                        types={['decline_offering']}
                        hideAction={hideAction}
                    />
                </>
            )}
        </OpsVettingCardContainer>
    )
}
