import {UseQueryOptions} from '@tanstack/react-query'
import {HttpGetPhotosetImagesOptions, HttpGetPhotosetImagesResponse} from '@/features/photoset/http/photoset.http'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {PhotosetImagesSearchParams} from '@/features/photoset/types'
import {useGetPhotosetImages} from '@/features/photoset/queries/useGetPhotosetImages'

export const GET_PHOTOSET_IMAGES_LIST_RESPONSE_TYPE = 'extended'

export const useGetPhotosetImagesList = (
    promiseArgs: Omit<HttpGetPhotosetImagesOptions, 'params'>,
    options?: Omit<UseQueryOptions<HttpGetPhotosetImagesResponse>, 'queryFn' | 'queryKey'>
) => {
    const {searchParams} = useParsedSearchParams([], PhotosetImagesSearchParams)
    return useGetPhotosetImages(
        {...promiseArgs, params: {...searchParams, response_type: GET_PHOTOSET_IMAGES_LIST_RESPONSE_TYPE}},
        options
    )
}
