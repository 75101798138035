import {ButtonLink} from '@/components/ui/button-link/ButtonLink'
import {DownloadCloud02Icon, ImageCheckIcon} from '@/components/ui/icon/Icon'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {PhotosetFinal} from '@/features/photoset/components/photoset-final/PhotosetFinal'
import {useGetPhotoset} from '@/features/photoset/queries/useGetPhotoset'
import {PhotosetURLParams} from '@/features/photoset/types'
import {useTranslation} from 'react-i18next'
import {useOutletContext} from 'react-router-dom'
import {useTheme} from 'styled-components'

export const PhotosetRetouchingRevisionApprovedStep = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const {palette} = useTheme()
    const photosetQuery = useGetPhotoset(
        {
            id: urlParams.id
        },
        {enabled: false}
    )

    return (
        <PhotosetFinal
            title={t('photoset:retouching_revision_approved:title')}
            subtitle={t('photoset:retouching_revision_approved:subtitle')}
            icon={<ImageCheckIcon color={palette.neutral[900]} size={38} />}
            isLoading={photosetQuery.isFetching}
            endSlot={
                <ButtonLink
                    to={photosetQuery?.data?.revised_zip_url || ''}
                    disabled={!photosetQuery?.data?.revised_zip_url}
                    size="lg"
                    target="_blank"
                >
                    {photosetQuery?.data?.revised_zip_url ? (
                        <>
                            <DownloadCloud02Icon size={20} />
                            <span>{t('commons:download_zip')}</span>
                        </>
                    ) : (
                        <>
                            <Spinner size={20} />
                            <span>{t('commons:creating_zip')}</span>
                        </>
                    )}
                </ButtonLink>
            }
        />
    )
}
