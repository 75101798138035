import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {
    httpDeleteServicePhoto,
    HttpDeleteServicePhotoOptions
} from '@/features/service-vetting/http/servicesVetting.http.ts'
import {errorHandler} from '@utilities/helpers.ts'

export const useDeletePhotos = (urlParams: HttpDeleteServicePhotoOptions, options?: UseMutationOptions) => {
    return useMutation({
        ...options,
        mutationFn: () => httpDeleteServicePhoto(urlParams),
        onError: (error, variables, context) => {
            errorHandler(error)
            options?.onError?.(error, variables, context)
        }
    })
}
