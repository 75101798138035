import {errorHandler} from '@/utilities/helpers'
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {httpAddPhotosetSpaceComment, HttpAddPhotosetSpaceCommentOptions} from '../http/photoset.http'
import {PhotosetSpace} from '../types'

export const useAddPhotosetSpaceComment = (
    urlParams: HttpAddPhotosetSpaceCommentOptions['urlParams'],
    options?: UseMutationOptions<unknown, unknown, HttpAddPhotosetSpaceCommentOptions['payload']>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationFn: payload => httpAddPhotosetSpaceComment({urlParams, payload}),
        onError: errorHandler,
        onSuccess: (data, variables, context) => {
            // Update cached data
            queryClient.setQueriesData(
                {queryKey: [QUERY_KEYS.PHOTOSET_SPACES]},
                (currentPhotosetSpaces: PhotosetSpace[] | undefined) => {
                    if (!currentPhotosetSpaces) return currentPhotosetSpaces

                    return (
                        currentPhotosetSpaces.map(photosetSpace =>
                            Object.assign(
                                {},
                                photosetSpace.id == urlParams.photosetSpaceId
                                    ? {
                                          ...photosetSpace,
                                          comment: variables.comment
                                      }
                                    : photosetSpace
                            )
                        ) ?? []
                    )
                }
            )

            options?.onSuccess?.(data, variables, context)
        }
    })
}
