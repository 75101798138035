import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpAddPhotosetSpaceFlag} from '@/features/photoset/http/photoset.http'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'
import {PhotosetSpace, PhotosetSpaceFlag} from '@/features/photoset/types'
import {overridePhotosetSpaceFlagToAdd} from '@/features/photoset/utils'

type AddPhotosetSpaceFlagData = {
    flag: PhotosetSpaceFlag
    photosetSpaceId: PhotosetSpace['id']
}

type UseAddPhotosetSpaceFlag = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useAddPhotosetSpaceFlag = (options?: UseAddPhotosetSpaceFlag) => {
    const queryClient = useQueryClient()
    const queryKey = [QUERY_KEYS.PHOTOSET_SPACES]

    return useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.ASSIGN_PHOTOSET_SPACE_FLAG],
        mutationFn: (data: AddPhotosetSpaceFlagData) =>
            httpAddPhotosetSpaceFlag({
                urlParams: {photosetSpaceId: data.photosetSpaceId},
                payload: {flag: data.flag}
            }),
        onMutate: async (vars: AddPhotosetSpaceFlagData) => {
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({queryKey})
            // Snapshot the previous value
            const previousPhotosetSpaces = queryClient.getQueryData<PhotosetSpace[]>(queryKey)

            // Optimistically update to the new value
            queryClient.setQueriesData({queryKey}, (currentPhotosetSpaces: PhotosetSpace[] | undefined) => {
                if (!currentPhotosetSpaces) return currentPhotosetSpaces

                return overridePhotosetSpaceFlagToAdd(currentPhotosetSpaces, vars.photosetSpaceId, vars.flag)
            })

            // Return a context object with the snapshot value
            return {previousPhotosetSpaces}
        },
        onError: (error: AxiosError<ErrorResponseData>, _, context) => {
            options?.onError?.(error)
            queryClient.setQueriesData({queryKey}, context?.previousPhotosetSpaces)
        },
        onSuccess: () => {
            options?.onSuccess?.()
        }
    })
}
