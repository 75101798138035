import {captureException} from '@sentry/react'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {httpGetService} from '@/features/service-vetting/http/servicesVetting.http.ts'
import {Service} from '@/features/service-vetting/types.ts'
import {remapServiceForVetting} from '@/features/service-vetting/utils.ts'

export const useGetService = (serviceId: number, options?: Omit<UseQueryOptions<Service>, 'queryFn' & 'queryKey'>) => {
    const query = useQuery({
        ...options,
        queryKey: [QUERY_KEYS.SERVICE_VETTING, serviceId],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetService({
                    urlParams: {serviceId},
                    params: {response_type: 'extended'}
                }),
                responseShape: Service,
                onZodError: captureException
            })
    })

    return {...query, remappedData: query.data ? remapServiceForVetting(query.data) : undefined}
}
