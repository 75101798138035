import {Button} from '@components/ui/button/Button'
import styled, {css} from 'styled-components'
import {ComboboxList, SelectArrow, SelectItem, SelectList, SelectPopover} from '@ariakit/react'
export const StyledSelectPopover = styled(SelectPopover)(
    ({theme: {palette, shadows, zIndex, spacing}}) => css`
        background-color: ${palette.neutral.white};
        border: 1px solid ${palette.neutral[200]};
        border-radius: 8px;
        box-shadow: ${shadows.lg};
        padding: ${spacing}px ${spacing * 1.5}px;
        max-height: 328px;
        z-index: ${zIndex.dropdown};
    `
)

export const StyledSelectItem = styled(SelectItem)(
    ({theme: {palette, spacing, typography}}) => css`
        ${typography.textSm};
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: ${spacing * 2}px;
        padding: ${spacing * 2.5}px ${spacing * 2}px;
        cursor: pointer;
        border-radius: 6px;
        font-weight: 500;
        margin: 1px 0; /* used to space each list item */
        color: ${palette.neutral[900]};
        min-width: 100%;

        & span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        & svg {
            fill: ${palette.primary[600]};
        }

        &[aria-selected='true'] {
            background-color: ${palette.neutral[100]};
        }

        &:not([aria-selected='true']):hover {
            background-color: ${palette.neutral[50]};
        }
    `
)

export const StyledSelectComboboxPopover = styled(StyledSelectPopover)(
    () => css`
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
    `
)

export const StyledSelectList = styled(SelectList)`
    overflow-y: auto;
`

export const StyledSelectComboboxList = styled(ComboboxList)`
    overflow-y: auto;
`

export const StyledSelectTrigger = styled(Button)<{$hasChildren: boolean}>(
    ({theme: {palette}, $hasChildren}) => css`
        justify-content: space-between;
        ${!$hasChildren &&
        css`
            font-weight: 400;
            color: ${palette.neutral['400']};
        `};

        &:not(:disabled):hover,
        &:focus,
        &[aria-expanded='true'] {
            outline: 1px solid ${palette.primary[300]};
            box-shadow: 0 0 0 4px ${palette.primary[100]};
        }
        &:disabled {
            background-color: ${palette.neutral['100']};
        }
    `
)

export const StyledSelectArrow = styled(SelectArrow)<{$isOpen: boolean}>(
    ({theme: {transition, palette}, $isOpen}) => css`
        fill: ${palette.neutral[500]};
        transition: ${transition};
        margin-left: auto;
        ${$isOpen &&
        css`
            transform: rotate(180deg);
        `}
    `
)
