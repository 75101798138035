import {
    OpsVettingCardGrid,
    OpsVettingDisabledContainer,
    OpsVettingParagraph,
    OpsVettingSectionTitle,
    OpsVettingSectionWrapper
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {useTranslation} from 'react-i18next'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {ServiceVettingConceptOfferingCard} from '@/features/service-vetting/components/service-vetting-concept-offering-card/ServiceVettingConceptOfferingCard.tsx'
import {RemappedOffering, RemappedService} from '@/features/service-vetting/types.ts'
import {useMemo} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {CheckIcon} from '@components/ui/icon/Icon.tsx'
import {useTheme} from 'styled-components'

export const ServiceVettingConceptOfferings = ({service}: {service: RemappedService}) => {
    const {t} = useTranslation()

    const primaryOfferings: RemappedOffering[] = useMemo(
        () => service.offerings.filter(offering => offering.type != 'other'),
        [service.offerings]
    )

    const otherOfferings: RemappedOffering[] = useMemo(
        () => service.offerings.filter(offering => offering.type == 'other'),
        [service.offerings]
    )

    const {palette} = useTheme()

    const hideAction = service.status != 'under_review'

    return (
        <OpsVettingSectionWrapper fullWidth direction={'column'} gap={6}>
            <Flexbox fullWidth justify="space-between" align="center">
                <OpsVettingSectionTitle>{t('vetting:concept:service:offerings:title')}</OpsVettingSectionTitle>
                <OpsVettingParagraph>
                    {service.offerings.filter(offering => !offering.status).length > 0 ? (
                        t('vetting:concept:service:offerings:offerings_remaining', {
                            value: service.offerings.filter(offering => !offering.status).length
                        })
                    ) : (
                        <Flexbox render={'span'} gap={1}>
                            <CheckIcon size={20} color={palette.success[600]} />
                            {t('vetting:concept:service:offerings:reviewed')}
                        </Flexbox>
                    )}
                </OpsVettingParagraph>
            </Flexbox>
            <Divider direction="horizontal" />
            <OpsVettingDisabledContainer $isDisabled={service.expertise.status == 'declined'}>
                <OpsVettingCardGrid>
                    {primaryOfferings.map((offering, index) => (
                        <ServiceVettingConceptOfferingCard
                            offering={offering}
                            key={index}
                            index={index}
                            hideAction={hideAction}
                        />
                    ))}
                    {otherOfferings.map((offering, index) => (
                        <ServiceVettingConceptOfferingCard
                            offering={offering}
                            key={index}
                            index={index}
                            hideAction={hideAction}
                        />
                    ))}
                </OpsVettingCardGrid>
            </OpsVettingDisabledContainer>
        </OpsVettingSectionWrapper>
    )
}
