import {zodResolver} from '@hookform/resolvers/zod'
import {FormProvider, useForm, useFieldArray, SubmitHandler} from 'react-hook-form'
import {
    SERVICE_OUTPUT_CHARACTER_LIMITS,
    ServiceMerchandisingOutputSchema
} from '@/features/service-vetting/formSchemas.ts'
import {
    StyledServiceMerchVettingFormHostSection,
    StyledServiceMerchVettingFormOfferingSection,
    StyledServiceVettingMerchOutputForm
} from './style'
import {InputText} from '@/components/commons/input-text/InputText'
import {TextArea} from '@/components/commons/textarea/TextArea'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Divider} from '@/components/ui/divider/Divider'
import {Stars01Icon} from '@/components/ui/icon/Icon'
import {ServiceVettingMerchFormHeader} from '../service-vetting-merch-form-header/ServiceVettingMerchFormHeader'
import {RefObject, useEffect} from 'react'
import {ServiceVettingMerchFormSkeleton} from '../service-vetting-merch-form-skeleton/ServiceVettingMerchFormSkeleton'
import {useTranslation} from 'react-i18next'
import {getOfferingTypeLabel} from '../../utils'
import {CharCounter} from '@/components/commons/char-counter/CharCounter'

interface ServiceVettingMerchOutputFormProps {
    isLoading?: boolean
    isReadOnly?: boolean
    defaultValues?: ServiceMerchandisingOutputSchema
    finalSubmitRef: RefObject<HTMLButtonElement>
    handleFinalSubmissionCb: SubmitHandler<ServiceMerchandisingOutputSchema>
}

export const ServiceVettingMerchOutputForm = ({
    isLoading = false,
    isReadOnly = false,
    defaultValues,
    finalSubmitRef,
    handleFinalSubmissionCb
}: ServiceVettingMerchOutputFormProps) => {
    const {t} = useTranslation()
    const methods = useForm<ServiceMerchandisingOutputSchema>({
        resolver: zodResolver(ServiceMerchandisingOutputSchema),
        defaultValues: defaultValues
    })

    const {
        register,
        control,
        reset,
        formState: {errors}
    } = methods
    const {fields} = useFieldArray({control, name: 'offerings'})

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues)
        }
    }, [defaultValues])

    console.log(defaultValues)

    return (
        <FormProvider {...methods}>
            <StyledServiceVettingMerchOutputForm onSubmit={methods.handleSubmit(handleFinalSubmissionCb)}>
                <ServiceVettingMerchFormHeader
                    variant={'purple'}
                    icon={<Stars01Icon />}
                    title={t('vetting:merch:service:output_form_title')}
                    description={t('vetting:merch:service:output_form_description')}
                />
                {isLoading ? (
                    <ServiceVettingMerchFormSkeleton />
                ) : (
                    <Flexbox direction={'column'} gap={2} fullWidth>
                        <StyledServiceMerchVettingFormHostSection direction={'column'} gap={4} fullWidth>
                            <h4>Host</h4>
                            <Flexbox direction="column" fullWidth>
                                <InputText
                                    disabled={isReadOnly}
                                    type={'text'}
                                    label={t('vetting:merch:service:host_experience_title')}
                                    {...register('host_experience_title')}
                                    errorMessage={errors?.host_experience_title?.message}
                                />
                                <CharCounter<ServiceMerchandisingOutputSchema>
                                    name="host_experience_title"
                                    maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.EXPERIENCE.TITLE}
                                />
                            </Flexbox>

                            <Flexbox direction="column" fullWidth>
                                <TextArea
                                    disabled={isReadOnly}
                                    rows={3}
                                    label={t('vetting:merch:service:host_experience_description')}
                                    {...register('host_experience_description')}
                                    errorMessage={errors?.host_experience_description?.message}
                                />
                                <CharCounter<ServiceMerchandisingOutputSchema>
                                    name="host_experience_description"
                                    maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.EXPERIENCE.DESCRIPTION}
                                />
                            </Flexbox>

                            <Flexbox direction="column" fullWidth>
                                <InputText
                                    disabled={isReadOnly}
                                    type={'text'}
                                    label={t('vetting:merch:service:host_education_title')}
                                    {...register('host_education_title')}
                                    errorMessage={errors?.host_education_title?.message}
                                />
                                <CharCounter<ServiceMerchandisingOutputSchema>
                                    name="host_education_title"
                                    maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.EDUCATION.TITLE}
                                />
                            </Flexbox>

                            <Flexbox direction="column" fullWidth>
                                <TextArea
                                    disabled={isReadOnly}
                                    rows={3}
                                    label={t('vetting:merch:service:host_education_description')}
                                    {...register('host_education_description')}
                                    errorMessage={errors?.host_education_description?.message}
                                />
                                <CharCounter<ServiceMerchandisingOutputSchema>
                                    name="host_education_description"
                                    maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.EDUCATION.DESCRIPTION}
                                />
                            </Flexbox>

                            <Flexbox direction="column" fullWidth>
                                <InputText
                                    disabled={isReadOnly}
                                    type={'text'}
                                    label={t('vetting:merch:service:host_career_highlight_title')}
                                    {...register('host_career_highlight_title')}
                                    errorMessage={errors?.host_career_highlight_title?.message}
                                />
                                <CharCounter<ServiceMerchandisingOutputSchema>
                                    name="host_career_highlight_title"
                                    maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.CAREER_HIGHLIGHT.TITLE}
                                />
                            </Flexbox>

                            <Flexbox direction="column" fullWidth>
                                <TextArea
                                    disabled={isReadOnly}
                                    rows={3}
                                    label={t('vetting:merch:service:host_career_highlight_description')}
                                    {...register('host_career_highlight_description')}
                                    errorMessage={errors?.host_career_highlight_description?.message}
                                />
                                <CharCounter<ServiceMerchandisingOutputSchema>
                                    name="host_career_highlight_description"
                                    maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.CAREER_HIGHLIGHT.DESCRIPTION}
                                />
                            </Flexbox>
                        </StyledServiceMerchVettingFormHostSection>

                        <Divider direction={'horizontal'} />

                        <StyledServiceMerchVettingFormOfferingSection direction={'column'} gap={5} fullWidth>
                            <h4>Service</h4>
                            <Flexbox direction="column" fullWidth>
                                <Flexbox direction="column" fullWidth>
                                    <InputText
                                        disabled={isReadOnly}
                                        type={'text'}
                                        label={t('vetting:merch:service:service_title')}
                                        {...register('service_title')}
                                    />
                                    <CharCounter<ServiceMerchandisingOutputSchema>
                                        name="service_title"
                                        maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.SERVICE.TITLE}
                                    />
                                </Flexbox>
                            </Flexbox>

                            <Flexbox direction="column" fullWidth>
                                <TextArea
                                    disabled={isReadOnly}
                                    rows={2}
                                    label={t('vetting:merch:service:service_description')}
                                    {...register('service_description')}
                                    errorMessage={errors?.service_description?.message}
                                />
                                <CharCounter<ServiceMerchandisingOutputSchema>
                                    name="service_description"
                                    maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.SERVICE.DESCRIPTION}
                                />
                            </Flexbox>

                            {fields.map((offering, index) => (
                                <Flexbox fullWidth direction={'column'} gap={2} key={offering.id}>
                                    <p>
                                        {t(getOfferingTypeLabel(offering.type))}
                                        {t('vetting:merch:service:offering_duration', {
                                            duration: offering.duration ?? '-'
                                        })}
                                    </p>
                                    <Flexbox direction="column" fullWidth>
                                        <InputText
                                            disabled={isReadOnly}
                                            type={'text'}
                                            label={t('vetting:merch:service:offering_title')}
                                            {...register(`offerings.${index}.improved_title`)}
                                        />
                                        <CharCounter<ServiceMerchandisingOutputSchema>
                                            name={`offerings.${index}.improved_title`}
                                            maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.OFFERING.TITLE}
                                        />
                                    </Flexbox>

                                    <Flexbox direction="column" fullWidth>
                                        <TextArea
                                            disabled={isReadOnly}
                                            rows={4}
                                            label={t('vetting:merch:service:offering_description_label')}
                                            {...register(`offerings.${index}.improved_description`)}
                                        />
                                        <CharCounter<ServiceMerchandisingOutputSchema>
                                            name={`offerings.${index}.improved_description`}
                                            maxLength={SERVICE_OUTPUT_CHARACTER_LIMITS.OFFERING.DESCRIPTION}
                                        />
                                    </Flexbox>
                                </Flexbox>
                            ))}
                        </StyledServiceMerchVettingFormOfferingSection>
                    </Flexbox>
                )}
                <button type="submit" ref={finalSubmitRef} style={{display: 'none'}} />
            </StyledServiceVettingMerchOutputForm>
        </FormProvider>
    )
}
