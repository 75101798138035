import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Skeleton} from '@/components/ui/skeleton/Skeleton'
import {StyledServiceVettingMerchFormSkeletonWrapper} from './style'

export const ServiceVettingMerchFormSkeleton = () => {
    return (
        <StyledServiceVettingMerchFormSkeletonWrapper>
            <Flexbox direction={'column'} gap={10} fullWidth>
                <Flexbox direction="column" gap={4} fullWidth>
                    <Skeleton width={80} height={30} />
                    <Skeleton width={100} height={20} />
                    <Skeleton height={42} />
                </Flexbox>

                {[1, 2, 3].map((_, index) => (
                    <Flexbox key={index} direction="column" gap={4} fullWidth>
                        <Skeleton width={100} height={20} />
                        <Skeleton height={100} />
                    </Flexbox>
                ))}

                <Flexbox direction="column" gap={4} fullWidth>
                    <Skeleton width={100} height={30} />
                    <Skeleton width={100} height={20} />
                    <Skeleton height={42} />
                </Flexbox>

                {[1, 2].map((_, index) => (
                    <Flexbox key={index} direction="column" gap={4} fullWidth>
                        <Skeleton width={100} height={20} />
                        <Skeleton height={100} />
                    </Flexbox>
                ))}
            </Flexbox>
        </StyledServiceVettingMerchFormSkeletonWrapper>
    )
}

ServiceVettingMerchFormSkeleton.displayName = 'ServiceVettingMerchFormSkeleton'
