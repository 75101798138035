import {QueryKey, useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {httpImageSelectionApproval, HttpImageSelectionApprovalParams} from '@/features/photoset/http/photoset.http'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'
import {Image, Photoset, PhotosetSpace} from '@/features/photoset/types'
import {updateImageSelection} from '@/features/photoset/utils.ts'

export const useImageSelectionApproval = ({
    photosetId,
    photosetSpaceId,
    queryKey = [QUERY_KEYS.PHOTOSET_SPACES],
    options
}: {
    photosetId: Photoset['id']
    photosetSpaceId: PhotosetSpace['id']
    queryKey?: QueryKey
    options?: UseMutationOptions<
        unknown,
        unknown,
        HttpImageSelectionApprovalParams['payload'] & {
            imageId: Image['id']
        }
    >
}) => {
    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.IMAGE_SELECTION_APPROVAL],
        mutationFn: ({imageId, ...rest}) =>
            httpImageSelectionApproval({
                urlParams: {photosetId, imageId},
                payload: rest
            }),
        onMutate: async vars => {
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({queryKey})
            // Snapshot the previous value
            const previousPhotosetSpaces = queryClient.getQueryData<PhotosetSpace[]>(queryKey)
            // Optimistically update to the new value
            queryClient.setQueriesData({queryKey}, (currentPhotosetSpaces: PhotosetSpace[] | undefined) => {
                if (!currentPhotosetSpaces) return currentPhotosetSpaces
                return updateImageSelection(currentPhotosetSpaces, vars.imageId, photosetSpaceId, {
                    curation_approved: vars?.curation_approved,
                    need_manual_retouching: vars?.need_manual_retouching,
                    vetting_qa_approved: vars?.vetting_qa_approved,
                    needs_to_review: vars?.needs_to_review
                })
            })
            // Return a context object with the snapshot value
            return {previousPhotosetSpaces}
        },
        onError: (error: AxiosError<ErrorResponseData>, vars, context) => {
            options?.onError?.(error, vars, context)
            queryClient.setQueriesData({queryKey: queryKey}, context?.previousPhotosetSpaces)
        },
        onSuccess: (data, vars, context) => {
            options?.onSuccess?.(data, vars, context)
        }
    })
}
