import {captureException} from '@sentry/react'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {z} from 'zod'
import {HttpGetServiceFlags, httpGetServiceFlags} from '@/features/service-vetting/http/servicesVetting.http.ts'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {ServiceFlag} from '@/features/service-vetting/types.ts'

export const useGetServiceFlags = (params: HttpGetServiceFlags['params'], options?: UseQueryOptions<ServiceFlag[]>) => {
    return useQuery({
        queryKey: [QUERY_KEYS.SERVICE_FLAGS, params],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetServiceFlags({
                    params
                }),
                responseShape: z.array(ServiceFlag),
                onZodError: captureException
            }),
        ...options
    })
}
