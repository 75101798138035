import {z} from 'zod'
import {Countries} from 'country-to-currency'

export const ServiceVettingStatus = z.enum(['accepted', 'declined', 'back_to_draft'])
export type ServiceVettingStatus = z.infer<typeof ServiceVettingStatus>

export const OfferingTypes = z.enum(['standard', 'basic', 'luxe', 'exclusive', 'other'])
export type OfferingTypes = z.infer<typeof OfferingTypes>

export const ServiceStatus = z.enum([
    'archived_approval_required',
    'approval_required',
    'approved',
    'declined',
    'in_progress',
    'under_review',
    'pending_id_verification',
    'update_required',
    'waitlisted',
    'archived_update_required',
    'completed'
])
export type ServiceStatus = z.infer<typeof ServiceStatus>

export const ServiceFlag = z.object({
    id: z.number(),
    type: z.enum([
        'description',
        'general',
        'location',
        'title',
        'gallery',
        'gallery_image',
        'host_expertise',
        'profile_photo',
        'decline_expertise',
        'decline_offering'
    ]),
    key_name: z.enum([
        'alcohol_without_license',
        'blurry_profile_picture',
        'not_enough_online_presence',
        'not_enough_experience_years',
        'policy_violation',
        'expertise_unrelated',
        'alcohol_smoking',
        'animal_welfare_violations',
        'black_white',
        'blurry',
        'cannot_be_cropped_vertically',
        'collages',
        'duplicate',
        'heavy_filters_applied',
        'hosts_experience_unclear',
        'inappropriate_content',
        'insufficient_formal_experience',
        'irrelevant_lacking_context',
        'lack_photo_variety',
        'misleading_inaccurate',
        'missing_career_achievements',
        'multiple_countries_selected',
        'passport_or_logo',
        'photo_angle_awkward',
        'poor_lighting_background',
        'reputation_proof_insufficient',
        'selfie_style',
        'service_description_unclear',
        'spelling_grammatical_errors',
        'stock_photos_or_screenshots',
        'stretched_distorted',
        'text_graphics_overlaid',
        'title_needs_update',
        'too_dark'
    ])
})
export type ServiceFlag = z.infer<typeof ServiceFlag>

export type ServiceFlagWithLabel = ServiceFlag & {label: string}

export const OfferingQuestionAnswer = z.object({
    id: z.number().positive(),
    answer: z.string(),
    flags: ServiceFlag.array(),
    question_key_name: z.enum([
        'currency',
        'description',
        'duration',
        'price_per_guest',
        'title',
        'main_photo',
        'price_per_group',
        'meeting_point',
        'improved_title',
        'improved_description'
    ])
})

export type OfferingQuestionAnswer = z.infer<typeof OfferingQuestionAnswer>

export const Offering = z.object({
    id: z.number().positive(),
    status: ServiceVettingStatus.nullable(),
    submission_id: z.number(),
    flags: ServiceFlag.array(),
    type: OfferingTypes,
    question_answer: OfferingQuestionAnswer.array(),
    image_id: z.number().nullable(),
    original_image_id: z.number().nullable()
})
export type Offering = z.infer<typeof Offering>

export const ExpertiseQuestionAnswer = z
    .object({
        id: z.number(),
        answer: z.string(),
        flags: ServiceFlag.array(),
        question_key_name: z.enum([
            'experience_years',
            'summary',
            'highlights',
            'hosting_modality',
            'co_hosts',
            'inspiration',
            'background',
            'accolades',
            'category',
            'career',
            'expertise',
            'background',
            'about_yourself',
            'location',
            'location_notes',
            'age_limit',
            'age',
            'bring_anything',
            'what_bring',
            'language',
            'main_language',
            'extra_languages',
            'group_min_size',
            'group_max_size',
            'main_language',
            'group_min_size',
            'group_max_size',
            'profile_photo',
            'host_experience_title',
            'host_experience_description',
            'host_education_title',
            'host_education_description',
            'host_career_highlight_title',
            'host_career_highlight_description',
            'service_title',
            'service_description'
        ])
    })
    .or(
        z.object({
            id: z.number(),
            answer: z.enum(['individual', 'business']),
            question_key_name: z.literal('business_type'),
            flags: ServiceFlag.array()
        })
    )
    .or(
        z.object({
            id: z.number(),
            flags: ServiceFlag.array(),
            answer: z.string().array(),
            question_key_name: z.literal('links')
        })
    )
    .or(
        z.object({
            id: z.number(),
            answer: z.string().array(),
            flags: ServiceFlag.array(),
            question_key_name: z.literal('extra_languages')
        })
    )
    .or(
        z.object({
            id: z.number(),
            answer: z.enum(['24_hours', '7_days']),
            question_key_name: z.literal('cancellation_policy'),
            flags: ServiceFlag.array()
        })
    )
    .or(
        z.object({
            id: z.number(),
            answer: z.string().array(),
            flags: ServiceFlag.array(),
            question_key_name: z.literal('cities')
        })
    )
export type ExpertiseQuestionAnswer = z.infer<typeof ExpertiseQuestionAnswer>

export const Service = z.object({
    submission_places: z.array(
        z.object({
            place: z.object({
                id: z.number().positive().int(),
                name: z.string(),
                airbnb_place_id: z.string()
            })
        })
    ),
    submission_cities: z.array(
        z.object({
            city: z.object({
                id: z.number().positive().int(),
                name: z.string(),
                country: z.custom<Countries>(value => {
                    return typeof value === 'string'
                }),
                country_iso: z.string(),
                state: z.string().nullable()
            })
        })
    ),
    gallery: z.object({
        id: z.number(),
        flags: ServiceFlag.array(),
        images: z
            .object({
                submission_id: z.number(),
                id: z.number().int().positive(),
                name: z.string(),
                url: z.string().url(),
                is_approved: z.boolean().nullable(),
                is_cover: z.boolean(),
                is_host_approval_needed: z.boolean(),
                is_host_approved: z.boolean().nullable(),
                is_offering_image: z.boolean(),
                flags: ServiceFlag.array()
            })
            .array()
    }),
    category: z
        .object({
            key_name: z.enum([
                'photography',
                'boudoir_photography',
                'private_chef',
                'prepared_meals',
                'catering',
                'personal_training',
                'massage',
                'spa_treatments',
                'haircare',
                'makeup',
                'nails',
                'generic' //TODO remove 'generic' when the category step is enabled again
            ])
        })
        .nullable(),
    expertise: z.object({
        id: z.number().positive(),
        status: ServiceVettingStatus.nullable(),
        flags: ServiceFlag.array(),
        question_answer: z.array(ExpertiseQuestionAnswer)
    }),
    id: z.number().int().positive(),
    status: ServiceStatus,
    host: z.object({
        first_name: z.string(),
        profile_photo_url: z.string().url().nullable()
    }),
    offerings: z.array(Offering)
})
export type Service = z.infer<typeof Service>

export const ServiceBaseAnswer = z.object({answer: z.string(), id: z.number(), flags: ServiceFlag.array()})
export type ServiceBaseAnswer = z.infer<typeof ServiceBaseAnswer>

export const RemappedOffering = z.object({
    ...Offering['shape'],
    question_answer: z.object({
        title: ServiceBaseAnswer,
        price_per_guest: ServiceBaseAnswer,
        price_per_group: ServiceBaseAnswer.optional(),
        currency: ServiceBaseAnswer,
        duration: ServiceBaseAnswer,
        description: ServiceBaseAnswer,
        main_photo: ServiceBaseAnswer.optional(),
        improved_title: ServiceBaseAnswer,
        improved_description: ServiceBaseAnswer
    })
})
export type RemappedOffering = z.infer<typeof RemappedOffering>

export const RemappedService = z.object({
    ...Service.omit({offerings: true, expertise: true}).shape,
    expertise: Service.shape['expertise'].omit({question_answer: true}).and(
        z.object({
            question_answer: z.object({
                experience_years: ServiceBaseAnswer,
                summary: ServiceBaseAnswer,
                highlights: ServiceBaseAnswer,
                inspiration: ServiceBaseAnswer,
                background: ServiceBaseAnswer,
                links: z.object({answer: z.string().array(), id: z.number(), flags: ServiceFlag.array()}),
                location: ServiceBaseAnswer,
                profile_photo: ServiceBaseAnswer,
                cities: z.object({answer: z.string().array(), id: z.number(), flags: ServiceFlag.array()}),
                host_experience_title: ServiceBaseAnswer,
                host_experience_description: ServiceBaseAnswer,
                host_education_title: ServiceBaseAnswer,
                host_education_description: ServiceBaseAnswer,
                host_career_highlight_title: ServiceBaseAnswer,
                host_career_highlight_description: ServiceBaseAnswer,
                service_title: ServiceBaseAnswer,
                service_description: ServiceBaseAnswer
            })
        })
    ),
    offerings: RemappedOffering.array()
})
export type RemappedService = z.infer<typeof RemappedService>

export const EditFlagsSectionUrlParam = z.enum(['expertise', 'offering', 'gallery'])
export type EditFlagsSectionUrlParam = z.infer<typeof EditFlagsSectionUrlParam>

export const EditFlagsItemUrlParam = z.enum(['images', 'question-answer'])
export type EditFlagsItemUrlParam = z.infer<typeof EditFlagsItemUrlParam>

export const ServiceVersion = z.object({
    id: z.number(),
    status: ServiceStatus,
    created_at: z.string()
})
export type ServiceVersion = z.infer<typeof ServiceVersion>

export const Media = z.object({
    id: z.number().int().positive(),
    name: z.string().min(1)
})
export type Media = z.infer<typeof Media>
