import styled, {css} from 'styled-components'
import {Button} from '@components/ui/button/Button.tsx'
import {Chip} from '@components/commons/chip/Chip.tsx'

export const StyledULoadedPhotoWrapper = styled.div(
    () => css`
        position: relative;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        width: 100%;
        border-radius: 8px;
    `
)

export const StyledDeleteButton = styled(Button)(
    () => css`
        position: absolute;
        right: 10px;
        top: 10px;
    `
)

export const StyledInfoChipButton = styled(Chip)(
    () => css`
        position: absolute;
        left: 12px;
        bottom: 12px;
    `
)
export const StyledBlurWrapper = styled.div(
    () => css`
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
    `
)

export const StyledErrorWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        gap: ${spacing * 6}px;
        max-width: 1024px;

        & h4 {
            font-weight: 600;
            color: ${palette.neutral['white']};
            text-align: center;
        }
    `}
`
