import {
    OpsVettingGalleryItemsContainer,
    OpsVettingImage
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {GalleryItem, useServicePhotoUpload} from '@/features/service-vetting/store/servicesPhotoUpload.ts'
import {
    StyledBlurWrapper,
    StyledDeleteButton,
    StyledErrorWrapper,
    StyledInfoChipButton,
    StyledULoadedPhotoWrapper
} from '@/features/service-vetting/components/service-vetting-concept-upload-item/style.ts'
import {CircularProgressBar} from '@/features/service-vetting/components/circular-progress-bar/CircularProgressBar.tsx'
import {AlertTriangleIcon, Trash01Icon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'
import {useDeletePhotos} from '@/features/service-vetting/query/useDeleteServicePhotos.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'

export const ServiceVettingConceptUploadItem = ({
    image,
    galleryId,
    serviceId,
    hideAction
}: {
    image: GalleryItem
    serviceId: number
    galleryId: number
    hideAction: boolean
}) => {
    const {t} = useTranslation()
    const uploadProgressValues = useServicePhotoUpload(store => store.uploadProgressValues)
    const deleteGalleryItem = useServicePhotoUpload(store => store.deleteGalleryItem)
    const deletePhoto = useDeletePhotos(
        {mediaId: image.id, serviceId, galleryId},
        {
            onSuccess: () => {
                deleteGalleryItem(image.id)
                URL.revokeObjectURL(image.src)
            }
        }
    )
    const onClickDelete = () => {
        if (image.status == 'uploaded') {
            deletePhoto.mutate()
        } else {
            deleteGalleryItem(image.id)
            URL.revokeObjectURL(image.src)
        }
    }

    return (
        <OpsVettingGalleryItemsContainer>
            <StyledULoadedPhotoWrapper>
                <OpsVettingImage src={image.src} />
                {image.status == 'pending' && (
                    <StyledBlurWrapper>
                        <CircularProgressBar progress={uploadProgressValues[image.id] || 0} />
                    </StyledBlurWrapper>
                )}
                {image.status == 'uploaded' && !hideAction && (
                    <>
                        <StyledInfoChipButton
                            label={t('vetting:concept:service:gallery:internal_upload')}
                            variant={'neutral'}
                            shape={'circle'}
                        />
                        <StyledDeleteButton
                            iconOnly
                            variant={'secondary'}
                            disabled={deletePhoto.isPending}
                            onClick={onClickDelete}
                        >
                            {deletePhoto.isPending ? <Spinner size={20} /> : <Trash01Icon />}
                        </StyledDeleteButton>
                    </>
                )}
                {image.status == 'error' && (
                    <StyledBlurWrapper>
                        <StyledDeleteButton
                            iconOnly
                            variant={'secondary'}
                            disabled={deletePhoto.isPending}
                            onClick={onClickDelete}
                        >
                            {deletePhoto.isPending ? <Spinner size={20} /> : <Trash01Icon />}
                        </StyledDeleteButton>

                        <StyledErrorWrapper>
                            <IconContainer>
                                <AlertTriangleIcon />
                            </IconContainer>
                            <h4>{t('errors:default')}</h4>
                        </StyledErrorWrapper>
                    </StyledBlurWrapper>
                )}
            </StyledULoadedPhotoWrapper>
        </OpsVettingGalleryItemsContainer>
    )
}
