import {StyledButton} from './style'
import {ButtonHTMLAttributes, forwardRef} from 'react'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    fullWidth?: boolean
    iconOnly?: boolean
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    variant?:
        | 'primary'
        | 'primaryDanger'
        | 'secondary'
        | 'secondaryDanger'
        | 'tertiary'
        | 'ghost'
        | 'primarySuccess'
        | 'primaryBlue'
        | 'secondaryBlue'
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({children, fullWidth, iconOnly, size = 'md', type = 'button', variant = 'primary', ...rest}, ref) => (
        <StyledButton
            $fullWidth={fullWidth}
            $iconOnly={iconOnly}
            ref={ref}
            $size={size}
            type={type}
            $variant={variant}
            {...rest}
        >
            {children}
        </StyledButton>
    )
)

Button.displayName = 'Button'
