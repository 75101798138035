import {httpLogout, httpUserMe, HttpUserMeResponse} from '@/features/auth/http/auth.http'
import toast from 'react-hot-toast'
import i18next from 'i18next'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'

export const logout = async () => {
    try {
        await httpLogout()
        useAuthStore.getState().reset()
    } catch (e) {
        toast.error(i18next.t('errors:default'))
    }
}

export const userMe = async () => {
    try {
        const user = await parseAxiosPromise({
            axiosPromise: httpUserMe(),
            onZodError: captureException,
            responseShape: HttpUserMeResponse
        })
        useAuthStore.setState(() => ({user}))
    } catch (e) {
        toast.error(i18next.t('errors:default'))
    }
}
