import {QueryClient} from '@tanstack/react-query'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            networkMode: 'online',
            retry: 1,
            retryOnMount: true,
            retryDelay: 1000,
            staleTime: 600000, //10 min
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true
        }
    }
})

export const QUERY_KEYS = {
    FLAGS: 'flags',
    IMAGES: 'images',
    IMAGE_METADATA: 'images-metadata',
    PHOTOSET: 'photoset',
    PHOTOSET_SPACES: 'photoset-spaces',
    REASONS_DECLINE: 'reasons-decline',
    MANUAL_RETOUCHING_SPACES: 'manually-retouched-spaces',
    NEEDS_REVIEW_SPACES: 'needs-review-spaces',
    SERVICE_VETTING: 'service-vetting',
    SERVICE_FLAGS: 'service-flags'
} as const

export const MUTATION_KEYS = {
    ALLOCATE_IMAGES: 'allocate-images',
    ASSIGN_IMAGES_FLAG: 'assign-images-flag',
    ASSIGN_PHOTOSET_SPACE_FLAG: 'assign-photoset-space-flags',
    REMOVE_IMAGES_FLAG: 'remove-images-flag',
    DEALLOCATE_IMAGES: 'deallocate-images',
    REJECT_PHOTOSET: 'reject-photoset',
    UPDATE_STEP_ALLOCATION: 'update-step-allocation',
    UPDATE_STEP_COMPLETED: 'update-step-completed',
    UPDATE_STEP_CURATION: 'update-step-curation',
    UPDATE_STEP_INITIAL_QA: 'update-step-initial-qa',
    UPDATE_STEP_REVIEW_QA_FLAGS: 'update-step-review-qa-flags',
    UPDATE_STEP_REVIEW_QA_COMPLETED: 'update-step-review-qa-completed',
    UPDATE_STEP_AI_VETTING_COMPLETED: 'update-step-ai-vetting-completed',
    UPLOAD_MANUAL_RETOUCHED_PHOTOS: 'upload-manual-retouched-photos',
    BULK_UPLOAD_PHOTOS: 'bulk-upload-photos',
    CONFIRM_UPLOAD_PHOTO: 'confirm-upload-photo',
    UPDATE_STEP_AI_MANUAL_RETOUCHING_UPLOAD: 'update-step-ai-manual-retouching-upload',
    UPDATE_STEP_AI_MANUAL_RETOUCHING: 'update-step-ai-manual-retouching',
    UPDATE_STEP_AI_MANUAL_RETOUCHING_COMPLETED: 'update-step-ai-manual-retouching-completed',
    UPDATE_STEP_AI_VETTING: 'update-step-ai-vetting',
    UPDATE_STEP_VETTING_QA: 'update-step-vetting-qa',
    UPDATE_STEP_RETOUCHING_QA: 'update-step-retouching-qa',
    UPDATE_STEP_RETOUCHING_QA_COMPLETED: 'update-step-retouching-qa-completed',
    UPDATE_STEP_NEEDS_REVIEW: 'update-step-needs-review',
    UPDATE_STEP_RETOUCHING_REVISION_UPLOAD: 'update-step-retouching-revision-upload',
    UPDATE_STEP_RETOUCHING_REVISION: 'update-step-retouching-revision',
    UPDATE_STEP_RETOUCHING_APPROVED: 'update-step-retouching-approved',
    IMAGE_SELECTION_APPROVAL: 'image-selection-approval',
    LOGIN: 'login',
    SET_PASSWORD: 'set-password',
    RESET_PASSWORD: 'reset-password',
    SERVICES_PHOTO_PRESIGNED: 'services-photo-presigned'
} as const
