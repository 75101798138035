import {CSSProperties, forwardRef, ReactNode, RefAttributes} from 'react'
import {ScrollAreaProps as RadixScrollAreaProps, ScrollAreaViewportProps} from '@radix-ui/react-scroll-area'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {StyledRoot, StyledViewport} from '@components/commons/scroll-area/style.ts'

type ScrollAreaProps = {
    children: ReactNode
    scrollbar: ReturnType<typeof Scrollbar>
    viewportProps?: ScrollAreaViewportProps & RefAttributes<HTMLDivElement>
    overflowX?: CSSProperties['overflowX']
} & RadixScrollAreaProps

export const ScrollArea = forwardRef<HTMLDivElement, ScrollAreaProps>(
    ({children, scrollbar, viewportProps, overflowX, ...rest}, ref) => {
        return (
            <StyledRoot $overflowX={overflowX} scrollHideDelay={500} ref={ref} {...rest}>
                <StyledViewport {...viewportProps}>{children}</StyledViewport>
                {scrollbar}
            </StyledRoot>
        )
    }
)
