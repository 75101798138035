import {useOutletContext} from 'react-router-dom'
import {StyledEditingQaFlagsSpaces, StyledEditingQaListHeaderFlags} from './style'
import {FC, useMemo} from 'react'
import {PhotosetSpace, PhotosetURLParams, StepKey} from '@/features/photoset/types'
import {ListHeader} from '@/components/ui/list-header/ListHeader'
import {useTranslation} from 'react-i18next'
import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {AlertCircleIcon, LayoutRightIcon} from '@/components/ui/icon/Icon'
import {ScrollArea} from '@/components/commons/scroll-area/ScrollArea'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {EditingQaFlagsSpaceImages} from '@/features/photoset/components/editing-qa-flags-images/EditingQaFlagsImages'
import {Skeleton} from '@/components/ui/skeleton/Skeleton'
import {useGetPhotoset} from '@/features/photoset/queries/useGetPhotoset'
import {Button} from '@/components/ui/button/Button'
import {GalleryEditingQaFlagsModal} from '@/features/photoset/components/gallery-photoset-modal/gallery-editing-qa-flags-modal/GalleryEditingQaFlagsModal'
import {useGetPhotosetSpacesList} from '@/features/photoset/queries/useGetPhotosetSpacesList'
import {usePhotosetStore} from '@/features/photoset/store/store.ts'
import {FlagsPopover} from '@/features/photoset/components/flags-popover/FlagsPopover'
import {MultipleFlagsPopoverTrigger} from '@/features/photoset/components/flags-popover/MultipleFlagsPopoverTrigger'
import {ImagesCounter} from '@/features/photoset/components/images-counter/ImagesCounter'
import {PhotosetSpaceElement} from '@/features/photoset/components/photoset-space-element/PhotosetSpaceElement'
import {useExpandPhotosetImage} from '@/features/photoset/hooks/useExpandPhotosetImage'
import {retrievePhotosetSpacesFromSelectedImages} from '@/features/photoset/utils'
import {useCurrentPhotosetSubRoute} from '../../hooks/useCurrentPhotosetSubRoute'

interface EditingQaFlagsSpacesProps {
    hasSidebar: boolean
    showSidebar: () => void
}

export const EditingQaFlagsSpaces: FC<EditingQaFlagsSpacesProps> = ({hasSidebar, showSidebar}) => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const currentPhotosetSubRoute = useCurrentPhotosetSubRoute()
    const selectedImages = usePhotosetStore(state => state.selectedImages)
    const images = usePhotosetStore(state => state.images)
    const selectImages = usePhotosetStore(state => state.selectImages)
    const unselectAll = usePhotosetStore(state => state.unselectAll)

    const photosetQuery = useGetPhotoset(
        {
            id: urlParams.id
        },
        {enabled: false}
    )

    const photosetSpacesQuery = useGetPhotosetSpacesList(
        {
            urlParams: {
                photosetId: urlParams.id
            }
        },
        {enabled: false}
    )

    // Select all the images currently listed in each space
    const onSelectAll = (spaces: PhotosetSpace[]) => {
        spaces.forEach(space => {
            selectImages(space.images.map(image => image.id))
        })
    }

    // Force to return an array if photoset spaces is undefined
    const photosetSpaces = useMemo(() => photosetSpacesQuery.data ?? [], [photosetSpacesQuery])

    // Retrieve selected photoset spaces from selected images
    const selectedPhotosetSpaces = useMemo(() => {
        return retrievePhotosetSpacesFromSelectedImages(images, selectedImages)
    }, [photosetSpaces, images, selectedImages])

    const photosetSpacesImagesCount = useMemo(
        () =>
            photosetSpaces
                .filter(photosetSpaces => photosetSpaces.images)
                .map(photosetSpace => photosetSpace.images)
                .flat(1).length,
        [photosetSpaces]
    )

    // Handle gallery modal view
    const {currentDetailImageIndex, setCurrentDetailImageIndex, currentDetailSpace, onExpandImage, onReduceImage} =
        useExpandPhotosetImage()

    return (
        <>
            <StyledEditingQaFlagsSpaces>
                <ListHeader
                    size="sm"
                    title={
                        photosetSpacesQuery.isLoading ? (
                            <Skeleton height={30} width={96} />
                        ) : selectedImages.length >= 1 ? (
                            t('commons:x_selected_images', {
                                selected: selectedImages.length,
                                count: photosetSpacesImagesCount
                            })
                        ) : (
                            t('commons:x_images', {
                                count: photosetSpacesImagesCount
                            })
                        )
                    }
                    startSlot={
                        !photosetSpacesQuery.isLoading && (
                            <p>
                                {t('commons:x_bedrooms', {
                                    count: photosetQuery.data?.assignment.task.entity.bedrooms ?? 0
                                })}
                            </p>
                        )
                    }
                    endSlot={
                        <StyledEditingQaListHeaderFlags align="center" gap={2} justify="flex-end">
                            {currentPhotosetSubRoute == 'qa' &&
                                (selectedImages.length >= 1 ? (
                                    <>
                                        <ImagesCounter count={selectedImages.length} />
                                        <FlagsPopover
                                            stepId={StepKey['editing-qa-flags']}
                                            photosetSpaceIds={selectedPhotosetSpaces}
                                            selectedImagesIds={selectedImages}
                                            trigger={<MultipleFlagsPopoverTrigger />}
                                        />
                                        <Button
                                            size="sm"
                                            variant="tertiary"
                                            onClick={() => {
                                                unselectAll()
                                            }}
                                        >
                                            {t('commons:unselect_all')}
                                        </Button>
                                    </>
                                ) : (
                                    photosetSpacesQuery?.data?.length && (
                                        <Button
                                            variant={'tertiary'}
                                            size={'sm'}
                                            onClick={() => onSelectAll(photosetSpacesQuery.data)}
                                        >
                                            {t('commons:select_all')}
                                        </Button>
                                    )
                                ))}

                            {!hasSidebar && (
                                <Button variant="tertiary" onClick={showSidebar} iconOnly>
                                    <LayoutRightIcon />
                                </Button>
                            )}
                        </StyledEditingQaListHeaderFlags>
                    }
                />

                {photosetSpacesQuery.isError ? (
                    <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
                ) : (
                    <ScrollArea scrollbar={<Scrollbar />}>
                        {photosetSpacesQuery.isFetching
                            ? Array.from({length: 3}).map((_, index) => <PhotosetSpaceElement asSkeleton key={index} />)
                            : photosetSpaces
                                  .filter(photosetSpace => photosetSpace.images.length > 0)
                                  .map(currentPhotosetSpace => (
                                      <PhotosetSpaceElement
                                          key={currentPhotosetSpace.id}
                                          spaceName={currentPhotosetSpace.space.key_name}
                                          endSlot={
                                              <EditingQaFlagsSpaceImages
                                                  images={currentPhotosetSpace.images}
                                                  isQaView={currentPhotosetSubRoute == 'qa'}
                                                  spaceIndex={photosetSpaces.findIndex(
                                                      photosetSpace => photosetSpace.id == currentPhotosetSpace.id
                                                  )}
                                                  spaceId={currentPhotosetSpace.id}
                                                  onExpandImage={onExpandImage}
                                              />
                                          }
                                      />
                                  ))}
                    </ScrollArea>
                )}
            </StyledEditingQaFlagsSpaces>

            {currentDetailImageIndex != null && currentDetailSpace && (
                <GalleryEditingQaFlagsModal
                    selectableImages={currentDetailSpace.images}
                    currentDetailImageIndex={currentDetailImageIndex}
                    setCurrentDetailImageIndex={setCurrentDetailImageIndex}
                    onClose={onReduceImage}
                    currentSpaceId={currentDetailSpace.id}
                    currentSpaceName={currentDetailSpace.space.key_name}
                    isQaView={currentPhotosetSubRoute == 'qa'}
                />
            )}
        </>
    )
}
