import {SelectProvider, useSelectStore} from '@ariakit/react'
import {SelectItem, SelectList, SelectPopover} from '@components/ui/select-atoms/SelectAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {useGetServiceVersions} from '@/features/service-vetting/query/useGetServiceVersions.ts'
import {generatePath, useNavigate} from 'react-router-dom'
import dayjs from 'dayjs'
import {ReactElement} from 'react'
import {ServiceStatus} from '@/features/service-vetting/types.ts'
import {Chip} from '@components/commons/chip/Chip.tsx'
import {StyledSelectTrigger} from '@/features/service-vetting/components/service-vetting-concept-version-select/style.ts'
import {routes} from '@utilities/constants/routes.tsx'

export const ServiceVettingConceptVersionSelect = ({serviceId}: {serviceId: number}) => {
    const {t} = useTranslation()
    const versionQuery = useGetServiceVersions({
        serviceId: serviceId
    })
    const navigate = useNavigate()

    const select = useSelectStore({
        defaultValue: serviceId.toString(),
        setValue: value => navigate(generatePath(routes.SERVICE_VETTING_CONCEPT.path, {id: value}))
    })

    const versionStatusToBadge = (status: ServiceStatus): ReactElement => {
        switch (status) {
            case 'approved':
                return (
                    <Chip
                        label={t(`vetting:concept:service:service_status:${status}`)}
                        variant={'success'}
                        isActive
                        shape={'circle'}
                    />
                )
            case 'declined':
                return (
                    <Chip
                        label={t(`vetting:concept:service:service_status:${status}`)}
                        variant={'danger'}
                        isActive
                        shape={'circle'}
                    />
                )
            case 'update_required':
                return (
                    <Chip
                        label={t(`vetting:concept:service:service_status:${status}`)}
                        variant={'warning'}
                        isActive
                        shape={'circle'}
                    />
                )
            case 'approval_required':
                return (
                    <Chip
                        label={t(`vetting:concept:service:service_status:${status}`)}
                        variant={'warning'}
                        isActive
                        shape={'circle'}
                    />
                )
            case 'under_review':
                return (
                    <Chip
                        label={t(`vetting:concept:service:service_status:${status}`)}
                        variant={'info'}
                        isActive
                        shape={'circle'}
                    />
                )
            default:
                return (
                    <Chip
                        label={t(`vetting:concept:service:service_status:${status}`)}
                        variant={'neutral'}
                        isActive
                        shape={'circle'}
                    />
                )
        }
    }

    return (
        <SelectProvider store={select}>
            {versionQuery.isLoading ? (
                <Flexbox gap={2} align={'center'}>
                    <Flexbox gap={2}>
                        <Skeleton height={20} width={100} />
                        <Skeleton height={20} width={100} />
                    </Flexbox>
                    <Skeleton height={14} width={14} />
                </Flexbox>
            ) : (
                <StyledSelectTrigger
                    variant={'ghost'}
                    displayValue={value => {
                        const version = versionQuery.data?.find(version => version.id.toString() == value)
                        const index = versionQuery.data?.findIndex(version => version.id.toString() == value)
                        return (
                            <Flexbox>
                                {version && index != undefined && (
                                    <Flexbox gap={2} align={'center'}>
                                        <h4>{t('vetting:concept:service:submission_number', {value: index + 1})}</h4>
                                        {versionStatusToBadge(version.status)}
                                    </Flexbox>
                                )}
                            </Flexbox>
                        )
                    }}
                ></StyledSelectTrigger>
            )}
            <SelectPopover sameWidth={false} store={select} unmountOnHide>
                <SelectList store={select}>
                    {versionQuery.remappedData.map((value, index) => (
                        <SelectItem value={value.id.toString()} key={value.id}>
                            <Flexbox gap={2}>
                                <h4>{t('vetting:concept:service:submission_number', {value: index + 1})}</h4>
                                <p>{dayjs(value.created_at).format('ll')}</p>
                            </Flexbox>
                            {versionStatusToBadge(value.status)}
                        </SelectItem>
                    ))}
                </SelectList>
            </SelectPopover>
        </SelectProvider>
    )
}
