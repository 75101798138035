import {FC} from 'react'
import {Control, UseFormRegister, FieldErrors} from 'react-hook-form'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {CopyPreparationValidationSchema} from '@/features/exp-copy-training/types.ts'
import {TextArea} from '@components/commons/textarea/TextArea.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

interface CopyFormProps {
    register: UseFormRegister<CopyPreparationValidationSchema>
    control: Control<CopyPreparationValidationSchema>
    errors?: FieldErrors<CopyPreparationValidationSchema>
    values?: CopyPreparationValidationSchema
    onValueChange?: (values: Partial<CopyPreparationValidationSchema>) => void
}

export const ExpCopyStaticForm: FC<CopyFormProps> = ({register, errors, values, onValueChange}) => {
    const handleChange = (field: keyof CopyPreparationValidationSchema, value: string) => {
        if (onValueChange) {
            onValueChange({[field]: value})
        }
    }

    return (
        <Flexbox direction={'column'} gap={4}>
            <InputText
                type={'text'}
                label="Listing title"
                placeholder="Paste here..."
                {...(values
                    ? {
                          value: values.listingTitle,
                          onChange: e => handleChange('listingTitle', e.target.value)
                      }
                    : register('listingTitle'))}
                errorMessage={errors?.listingTitle?.message}
            />

            <TextArea
                rows={6}
                label="Listing description"
                placeholder="Paste here..."
                {...(values
                    ? {
                          value: values.listingDescription,
                          onChange: e => handleChange('listingDescription', e.target.value)
                      }
                    : register('listingDescription'))}
                errorMessage={errors?.listingDescription?.message}
            />

            <TextArea
                rows={6}
                label="Host bio"
                placeholder="Paste here..."
                {...(values
                    ? {
                          value: values.hostBio,
                          onChange: e => handleChange('hostBio', e.target.value)
                      }
                    : register('hostBio'))}
                errorMessage={errors?.hostBio?.message}
            />
        </Flexbox>
    )
}

ExpCopyStaticForm.displayName = 'ExpCopyStaticForm'
