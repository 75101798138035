import {Modal} from '@components/ui/modal/Modal.tsx'
import {FC} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {ModalBody, ModalFooter, ModalHeader} from '@/components/ui/modal-atoms/ModalAtoms'
import {Divider} from '@/components/ui/divider/Divider'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {TextArea} from '@/components/commons/textarea/TextArea'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {StyledRetouchingQaCommentModalTitle} from './style'
import {useAddPhotosetSpaceComment} from '@/features/photoset/queries/useAddPhotosetSpaceComment'
import {PhotosetSpace} from '@/features/photoset/types'
import {Spinner} from '@/components/ui/spinner/Spinner'

const RetouchinQaCommentModalSchema = z.object({
    comment: z.string()
})
type RetouchinQaCommentModalSchema = z.infer<typeof RetouchinQaCommentModalSchema>

interface RetouchingQaCommentModalProps {
    onClose: () => void
    spaceName: string
    photosetSpaceId: PhotosetSpace['id']
    comment?: string
}

export const RetouchingQaCommentModal: FC<RetouchingQaCommentModalProps> = ({
    onClose,
    spaceName,
    photosetSpaceId,
    comment
}) => {
    const {t} = useTranslation()
    const {mutate: addPhotosetSpaceComment, isPending} = useAddPhotosetSpaceComment(
        {
            photosetSpaceId
        },
        {
            onSuccess: onClose
        }
    )

    const form = useForm<RetouchinQaCommentModalSchema>({
        mode: 'all',
        resolver: zodResolver(RetouchinQaCommentModalSchema),
        defaultValues: {comment}
    })

    const handleSubmit = form.handleSubmit(({comment}) => {
        addPhotosetSpaceComment({comment: comment ?? null})
    })

    return (
        <Modal width={480} closeIconOffset="sm" onOverlayClick={onClose}>
            <ModalHeader>
                <Button iconOnly size="sm" variant="tertiary" onClick={onClose}>
                    <XCloseIcon size={20} />
                </Button>
                <h3>{t('photoset:retouching_qa:comment')}</h3>
            </ModalHeader>

            <Divider direction="horizontal" />

            <ModalBody>
                <Flexbox direction="column" gap={6}>
                    <StyledRetouchingQaCommentModalTitle>{spaceName}</StyledRetouchingQaCommentModalTitle>
                    <Flexbox id="comment-form" render="form" fullWidth onSubmit={handleSubmit}>
                        <TextArea
                            rows={6}
                            maxLength={250}
                            placeholder={t('photoset:retouching_qa:comment_placeholder')}
                            errorMessage={
                                form.formState.errors?.comment?.message && t(form.formState.errors.comment.message)
                            }
                            {...form.register('comment')}
                        />
                    </Flexbox>
                </Flexbox>
            </ModalBody>

            <ModalFooter>
                <Button variant="tertiary" onClick={onClose} disabled={isPending}>
                    {t('commons:cancel')}
                </Button>
                <Button
                    variant="primary"
                    type="submit"
                    form="comment-form"
                    disabled={isPending || !form.formState.isDirty}
                >
                    {t('commons:confirm')}
                    {isPending && <Spinner size={16} />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

RetouchingQaCommentModal.displayName = 'RetouchingQaCommentModal'
