import {ScrollArea} from '@components/commons/scroll-area/ScrollArea.tsx'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {Container} from '@components/ui/container/Container.tsx'
import {ServiceVettingConceptHero} from '@/features/service-vetting/components/service-vetting-concept-hero/ServiceVettingConceptHero.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ServiceVettingConceptHostExpertise} from '@/features/service-vetting/components/service-vetting-concept-host-expertise/ServiceVettingConceptHostExpertise.tsx'
import {useOutletContext} from 'react-router-dom'
import {OpsVettingURLParams} from '@/features/ops-vetting/types.ts'
import {useGetService} from '@/features/service-vetting/query/useGetService.ts'
import {AlertTriangleIcon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {ServiceVettingConceptGallery} from '@/features/service-vetting/components/service-vetting-concept-gallery/ServiceVettingConceptGallery.tsx'
import {ServiceVettingConceptOfferings} from '@/features/service-vetting/components/service-vetting-concept-offerings/ServiceVettingConceptOfferings.tsx'
import {useEffect, useState} from 'react'
import {checkErrors} from '@/features/service-vetting/utils.ts'
import {useServiceVettingError} from '@/features/service-vetting/store/useServiceVettingError.ts'
import {SubHeaderBanner} from '@/features/photoset/components/subheader-banner/SubHeaderBanner.tsx'

export const ServiceConceptVetting = () => {
    const {urlParams} = useOutletContext<{urlParams: OpsVettingURLParams}>()
    const {t} = useTranslation()
    const serviceQuery = useGetService(urlParams.id)
    const setErrors = useServiceVettingError(state => state.setErrors)
    const resetErrors = useServiceVettingError(state => state.reset)
    const isTouched = useServiceVettingError(state => state.isTouched)
    const [showBanner, setShowBanner] = useState(false)

    useEffect(() => {
        if (isTouched && serviceQuery.remappedData) {
            const errorCheck = checkErrors(serviceQuery.remappedData)
            setShowBanner(!errorCheck.isValid)
            setErrors(errorCheck.errors)
        }
    }, [serviceQuery.remappedData, isTouched])

    useEffect(() => {
        return () => resetErrors()
    }, [])

    if (serviceQuery.isPending) {
        return (
            <Container fullWidth>
                <Flexbox style={{height: '100%'}} align={'center'} justify={'center'}>
                    <Spinner size={40} />
                </Flexbox>
            </Container>
        )
    }

    if (serviceQuery.isError) {
        return (
            <Container fullWidth>
                <ErrorBox icon={<AlertTriangleIcon />} title={t('errors:default')} />
            </Container>
        )
    }

    return (
        <ScrollArea scrollbar={<Scrollbar />}>
            {serviceQuery.remappedData && (
                <>
                    <Container fullWidth>
                        <ServiceVettingConceptHero service={serviceQuery.remappedData} />
                    </Container>
                    {showBanner && (
                        <SubHeaderBanner
                            title={t('vetting:concept:service:error_banner:title')}
                            subtitle={t('vetting:concept:service:error_banner:subtitle')}
                            type={'danger'}
                        />
                    )}
                    <Container fullWidth>
                        <Flexbox direction="column" gap={6} fullWidth>
                            <ServiceVettingConceptHostExpertise service={serviceQuery.remappedData} />
                            <ServiceVettingConceptOfferings service={serviceQuery.remappedData} />
                            <ServiceVettingConceptGallery service={serviceQuery.remappedData} />
                        </Flexbox>
                    </Container>
                </>
            )}
        </ScrollArea>
    )
}
