import styled, {DefaultTheme, css} from 'styled-components'

type StyledChip = {
    $variant: 'success' | 'info' | 'neutral' | 'danger' | 'warning'
    $size: 'sm' | 'md'
    $isActive?: boolean
    $isClickable?: boolean
    $shape: 'circle' | 'rectangle'
}

export const StyledChip = styled.div<StyledChip>`
    ${({theme: {spacing, typography}, $isClickable: isClickable, $shape}) => css`
        ${typography.textXs};
        display: flex;
        align-items: center;
        padding: ${spacing * 0.5}px ${spacing}px;
        border-radius: ${$shape == 'rectangle' ? '6px' : '9px'};
        font-weight: 500;
        overflow: hidden;
        cursor: ${isClickable ? 'pointer' : 'inherit'};

        & > span {
            display: block;
            text-overflow: ellipsis;
            padding: 0 ${spacing}px;
            overflow: hidden;
        }

        & > button {
            display: flex;
            cursor: pointer;
            opacity: 0.5;
            z-index: 0;

            &:not(:disabled):hover {
                opacity: 1;
            }
        }
    `}
    ${({$variant: variant, $isActive: isActive, theme}) => ColorStyles(theme, isActive)[variant]};
    ${({$size: size, theme}) => SizeStyles(theme)[size]};
`

const ColorStyles = ({palette}: DefaultTheme, isActive?: boolean) => ({
    success: css`
        color: ${isActive ? palette.success[700] : palette.neutral[700]};
        border: 1px solid ${isActive ? palette.success[200] : palette.neutral[300]};
        background-color: ${isActive ? palette.success[50] : palette.neutral.white};
    `,
    info: css`
        color: ${palette.blue[700]};
        border: 1px solid ${isActive ? palette.blue[200] : palette.neutral[300]};
        background-color: ${isActive ? palette.blue[50] : palette.neutral.white};
    `,
    neutral: css`
        color: ${palette.neutral[700]};
        border: 1px solid ${palette.neutral[200]};
        background-color: ${palette.neutral[50]};
    `,
    danger: css`
        color: ${isActive ? palette.danger[700] : palette.neutral[700]};
        border: 1px solid ${isActive ? palette.danger[200] : palette.neutral[300]};
        background-color: ${isActive ? palette.danger[50] : palette.neutral.white};
    `,
    warning: css`
        color: ${isActive ? palette.warning[700] : palette.neutral[700]};
        border: 1px solid ${isActive ? palette.warning[200] : palette.neutral[300]};
        background-color: ${isActive ? palette.warning[50] : palette.neutral.white};
    `
})

const SizeStyles = (theme: DefaultTheme) => ({
    sm: css`
        ${theme.typography.textXs}

        & svg {
            width: 14px;
            height: 14px;
        }
    `,
    md: css`
        min-height: 36px;
        ${theme.typography.textSm}
        & svg {
            width: 16px;
            height: 16px;
        }
    `
})
