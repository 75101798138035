import {useOutletContext} from 'react-router-dom'
import {useDeallocateImages} from '@/features/photoset/queries/useDeallocateImages'
import {Image, OnAllocateAction, PhotosetSpace, PhotosetURLParams} from '@/features/photoset/types'
import {usePhotosetStore} from '@/features/photoset/store/store'
import {errorHandler} from '@/utilities/helpers'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {MinusCircleIcon} from '@/components/ui/icon/Icon'
import {StyledDeallocateImageButton} from '@/features/photoset/components/deallocate-image-button/style'
import {generateToastAllocationId} from '@/features/photoset/utils'
import toast from 'react-hot-toast'
import {useReallocateImages} from '@/features/photoset/queries/useReallocateImages'
import {useMemo} from 'react'
import {UndoMessage} from '../undo-message/UndoMessage'

interface DeallocateImageButtonProps {
    image: Image
    photosetSpaceId: PhotosetSpace['id']
    keepSelected: boolean
    trackInHistory: boolean
    showToast: boolean
}

export const DeallocateImageButton = ({
    image,
    photosetSpaceId,
    keepSelected,
    trackInHistory,
    showToast
}: DeallocateImageButtonProps) => {
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const allocateImages = usePhotosetStore(state => state.allocateImages)
    const deallocateImages = usePhotosetStore(store => store.deallocateImages)
    const photosetSpace = usePhotosetStore(store => store.photosetSpaces)
    const deallocateImagesMutation = useDeallocateImages()
    const reallocateImagesMutation = useReallocateImages()

    const photoset = useMemo(
        () => [...photosetSpace.values()].find(space => space.id == photosetSpaceId),
        [photosetSpace, photosetSpaceId]
    )

    const onUndo = async (imagesIds: Image['id'][]) => {
        try {
            const toastId = generateToastAllocationId('deallocation', photosetSpaceId, imagesIds)
            await reallocateImagesMutation.mutateAsync({
                urlParams: {photosetId: urlParams.id, photosetSpaceId},
                payload: imagesIds
            })
            allocateImages({
                imagesIds: imagesIds,
                photosetSpaceId: photosetSpaceId,
                keepSelected: false,
                trackInHistory: false
            })
            toast.dismiss(toastId)
        } catch (error) {
            errorHandler(error)
        }
    }

    const onDeallocate = async ({photosetSpaceId, imagesIds}: OnAllocateAction) => {
        try {
            await deallocateImagesMutation.mutateAsync({
                urlParams: {
                    photosetId: urlParams.id,
                    photosetSpaceId: photosetSpaceId
                },
                payload: imagesIds
            })

            deallocateImages({
                imagesIds,
                photosetSpaceId,
                keepSelected,
                trackInHistory
            })

            if (showToast) {
                const toastId = generateToastAllocationId('deallocation', photosetSpaceId, imagesIds)

                toast.success(
                    <UndoMessage
                        operationType={'deallocation'}
                        onUndo={() => onUndo(imagesIds)}
                        spaceKeyName={photoset?.space.key_name}
                    />,
                    {duration: 8000, id: toastId}
                )
            }
        } catch (error) {
            errorHandler(error)
        }
    }

    return (
        <StyledDeallocateImageButton
            disabled={deallocateImagesMutation.isPending}
            $isActive={deallocateImagesMutation.isPending}
            onClick={() =>
                onDeallocate({
                    imagesIds: [image.id],
                    photosetSpaceId
                })
            }
        >
            {deallocateImagesMutation.isPending ? <Spinner size={24} /> : <MinusCircleIcon />}
        </StyledDeallocateImageButton>
    )
}
