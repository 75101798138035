import {z} from 'zod'
import {OfferingTypes} from './types'

export const SERVICE_OUTPUT_CHARACTER_LIMITS = {
    SERVICE: {
        TITLE: 50,
        DESCRIPTION: 90
    },
    OFFERING: {
        TITLE: 32,
        DESCRIPTION: 250
    },
    HOST: {
        EXPERIENCE: {
            TITLE: 32,
            DESCRIPTION: 90
        },
        EDUCATION: {
            TITLE: 32,
            DESCRIPTION: 90
        },
        CAREER_HIGHLIGHT: {
            TITLE: 32,
            DESCRIPTION: 90
        }
    }
} as const

//Schema Atoms
const ServiceOfferingSchema = z.object({
    id: z.coerce.number(),
    title: z.string().optional(),
    description: z.string().optional(),
    duration: z.string().optional(),
    type: OfferingTypes
})

const ServiceHostDetailsSchema = z.object({
    host_name: z.string(),
    host_experience: z.string().optional(),
    host_experience_summary: z.string().optional(),
    host_experience_highlight: z.string().optional(),
    host_experience_background: z.string().optional()
})

export const ServiceMerchandisingBaseSchema = z.object({
    service_title: z.string().min(1, {message: 'Listing title is required'}),
    service_byline: z.string().optional(),
    offerings: z.array(ServiceOfferingSchema).optional()
})

//Schema Molecules: Input
export const ServiceMerchandisingInputSchema = z.object({
    ...ServiceHostDetailsSchema.shape,
    ...ServiceMerchandisingBaseSchema.shape
})

//Schema Molecules: Output
export const ServiceMerchandisingOutputSchema = z.object({
    service_title: z
        .string()
        .max(SERVICE_OUTPUT_CHARACTER_LIMITS.SERVICE.TITLE, {
            message: `Service title must not exceed ${SERVICE_OUTPUT_CHARACTER_LIMITS.SERVICE.TITLE} characters`
        })
        .min(1, {
            message: 'Service title is required'
        }),
    service_description: z
        .string()
        .max(SERVICE_OUTPUT_CHARACTER_LIMITS.SERVICE.DESCRIPTION, {
            message: `Service description must not exceed ${SERVICE_OUTPUT_CHARACTER_LIMITS.SERVICE.DESCRIPTION} characters`
        })
        .min(1, {
            message: 'Service description is required'
        }),
    offerings: z.array(
        ServiceOfferingSchema.extend({
            improved_title: z
                .string()
                .max(SERVICE_OUTPUT_CHARACTER_LIMITS.OFFERING.TITLE, {
                    message: `Offering title must not exceed ${SERVICE_OUTPUT_CHARACTER_LIMITS.OFFERING.TITLE} characters`
                })
                .min(1, {
                    message: 'Offering title is required'
                }),
            improved_description: z
                .string()
                .max(SERVICE_OUTPUT_CHARACTER_LIMITS.OFFERING.DESCRIPTION, {
                    message: `Offering description must not exceed ${SERVICE_OUTPUT_CHARACTER_LIMITS.OFFERING.DESCRIPTION} characters`
                })
                .min(1, {
                    message: 'Offering description is required'
                })
        })
    ),
    host_experience_title: z
        .string()
        .max(SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.EXPERIENCE.TITLE, {
            message: `Host experience title must not exceed ${SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.EXPERIENCE.TITLE} characters`
        })
        .min(1, {
            message: 'Host experience title is required'
        }),
    host_experience_description: z
        .string()
        .max(SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.EXPERIENCE.DESCRIPTION, {
            message: `Host experience description must not exceed ${SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.EXPERIENCE.DESCRIPTION} characters`
        })
        .min(1, {
            message: 'Host experience description is required'
        }),
    host_education_title: z
        .string()
        .max(SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.EDUCATION.TITLE, {
            message: `Host education title must not exceed ${SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.EDUCATION.TITLE} characters`
        })
        .min(1, {
            message: 'Host education title is required'
        }),
    host_education_description: z
        .string()
        .max(SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.EDUCATION.DESCRIPTION, {
            message: `Host education description must not exceed ${SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.EDUCATION.DESCRIPTION} characters`
        })
        .min(1, {
            message: 'Host education description is required'
        }),
    host_career_highlight_title: z
        .string()
        .max(SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.CAREER_HIGHLIGHT.TITLE, {
            message: `Host career highlight title must not exceed ${SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.CAREER_HIGHLIGHT.TITLE} characters`
        })
        .min(1, {
            message: 'Host career highlight title is required'
        }),
    host_career_highlight_description: z
        .string()
        .max(SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.CAREER_HIGHLIGHT.DESCRIPTION, {
            message: `Host career highlight description must not exceed ${SERVICE_OUTPUT_CHARACTER_LIMITS.HOST.CAREER_HIGHLIGHT.DESCRIPTION} characters`
        })
        .min(1, {
            message: 'Host career highlight description is required'
        })
})

export type ServiceOfferingSchema = z.infer<typeof ServiceOfferingSchema>
export type ServiceMerchandisingInputSchema = z.infer<typeof ServiceMerchandisingInputSchema>
export type ServiceMerchandisingOutputSchema = z.infer<typeof ServiceMerchandisingOutputSchema>
