import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {
    HttpRestoreOfferingMainPhoto,
    httpRestoreOfferingMainPhoto
} from '@/features/service-vetting/http/servicesVetting.http.ts'

export const useRestoreMainPhotoOffering = (
    urlParams: HttpRestoreOfferingMainPhoto['urlParams'],
    options?: UseMutationOptions
) => {
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationFn: () =>
            httpRestoreOfferingMainPhoto({
                urlParams,
                params: {
                    response_type: 'small'
                }
            }),
        onError: (error, variables, context) => {
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICE_VETTING]})
            options?.onSuccess?.(data, variables, context)
        }
    })
}
