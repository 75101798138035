import axios from '@/axiosInstance.ts'
import {z} from 'zod'

/*Get exp copy AI reviewed */
export const HttpGenerateMerchVettingCopyOptions = z.object({
    prompt: z.string()
})
type HttpGenerateMerchVettingCopyOptions = z.infer<typeof HttpGenerateMerchVettingCopyOptions>

export const httpGenerateMerchVettingCopy = ({prompt}: HttpGenerateMerchVettingCopyOptions) =>
    axios.post(`/node/v1/experience/ai-copy-enhancement`, {prompt})
