import styled, {css} from 'styled-components'

export const StyledServiceVettingExpertiseOrderedList = styled.ol(
    ({theme: {typography, spacing}}) => css`
        list-style-type: auto;
        padding-left: ${spacing * 4}px;

        & > li > a {
            ${typography.textSm};
            text-decoration: underline;
            cursor: pointer;
        }
    `
)
