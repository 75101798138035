import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {Divider} from '@/components/ui/divider/Divider'
import {ShootListMetadataSidebar} from '@/features/photoset/components/shoot-list-metadata-sidebar/ShootListMetadataSidebar'
import {PageFooter} from '@/components/commons/page-footer/PageFooter'
import {InitialQaImagesPool} from '@/features/photoset/components/initial-qa-images-pool/InitialQaImagesPool'
import {Button} from '@/components/ui/button/Button'
import {ArrowRightIcon} from '@/components/ui/icon/Icon'
import {useGetFlags} from '@/features/photoset/queries/useGetFlags'
import {StepHeroControls} from '@/features/photoset/components/step-hero-controls/StepHeroControls'
import {useUpdateStepToAllocation} from '@/features/photoset/queries/useUpdateStepToAllocation'
import {errorHandler} from '@/utilities/helpers'
import {useOutletContext} from 'react-router-dom'
import {PhotosetURLParams} from '@/features/photoset/types'
import {useGetReasonsDecline} from '@/features/photoset/queries/useGetReasonsDecline'
import {RejectPhotosetModal} from '@/features/photoset/components/reject-photoset-modal/RejectPhotosetModal'
import {usePhotosetStore} from '@/features/photoset/store/store'
import {QUERY_KEYS, queryClient} from '@/queryClient.ts'
import {
    PhotosetStepGridContainer,
    PhotosetStepWrapper
} from '@/features/photoset/components/photoset-step-atoms/PhotosetStepAtoms'

export const PhotosetInitialQaStep = () => {
    const [hasSidebar, setHasSidebar] = useState(true)
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const [rejectModal, showRejectModal] = useState(false)

    //queries
    const reasonsDeclineQuery = useGetReasonsDecline()

    //store actions
    const unselectAll = usePhotosetStore(state => state.unselectAll)

    //mutations
    const updateStepToAllocationMutation = useUpdateStepToAllocation({
        onError: errorHandler,
        onSuccess: () => {
            unselectAll()
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.IMAGES]})
        }
    })

    // get the flags list
    useGetFlags(
        {
            params: {
                types: ['metadata', 'visual_metadata', 'visual'],
                response_type: 'extended'
            }
        },
        {staleTime: Infinity}
    )

    return (
        <>
            <PageHero
                title={t('photoset:initial_qa:title')}
                subtitle={t('photoset:initial_qa:subtitle')}
                endSlot={<StepHeroControls />}
            />

            <PhotosetStepWrapper>
                <PhotosetStepGridContainer fullWidth>
                    <InitialQaImagesPool hasSidebar={hasSidebar} showSidebar={() => setHasSidebar(true)} />
                    {hasSidebar && (
                        <>
                            <Divider direction="vertical" />
                            <ShootListMetadataSidebar hideSidebar={() => setHasSidebar(false)} />
                        </>
                    )}
                </PhotosetStepGridContainer>
                <PageFooter
                    startSlot={
                        <Button
                            onClick={() => showRejectModal(true)}
                            variant="tertiary"
                            disabled={reasonsDeclineQuery.isFetching || updateStepToAllocationMutation.isPending}
                        >
                            {t('commons:needs_reupload')}
                        </Button>
                    }
                    centerSlot={<p>{t('photoset:initial_qa:footer_step_label')}</p>}
                    endSlot={
                        <Button
                            onClick={() => updateStepToAllocationMutation.mutate(urlParams.id)}
                            variant="primary"
                            disabled={updateStepToAllocationMutation.isPending}
                        >
                            {t('photoset:allocation:title')}
                            <ArrowRightIcon />
                        </Button>
                    }
                />

                {rejectModal && reasonsDeclineQuery.data && (
                    <RejectPhotosetModal reasons={reasonsDeclineQuery.data} close={() => showRejectModal(false)} />
                )}
            </PhotosetStepWrapper>
        </>
    )
}

PhotosetInitialQaStep.displayName = 'PhotosetInitialQaStep'
