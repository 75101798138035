import {create} from 'zustand'

export type GalleryItem = {
    id: number
    src: string
    status: 'pending' | 'error' | 'uploaded'
}

interface ServicePhotoUploadStore {
    uploadProgressValues: Record<number, number>
    galleryItems: GalleryItem[]
    addGalleryItems: (uploadingItems: GalleryItem[]) => void
    clearGallery: () => void
    addUploadProgressValues: ({id, progressValue}: {id: number; progressValue: number}) => void
    setUploadingItemsStatus: ({id, status}: {id: number; status: GalleryItem['status']}) => void
    setGalleryItemSrc: ({id, src}: {id: number; src: GalleryItem['src']}) => void
    deleteGalleryItem: (id: number) => void
}

export const useServicePhotoUpload = create<ServicePhotoUploadStore>((setState, getState) => ({
    uploadingFiles: {},
    uploadProgressValues: {},
    galleryItems: [],
    addUploadProgressValues: ({id, progressValue}) => {
        const prevUploadProgressValues = getState().uploadProgressValues
        setState({
            uploadProgressValues: {...prevUploadProgressValues, [id]: progressValue}
        })
    },
    addGalleryItems: (galleryItems: GalleryItem[]) => {
        const prevUploadingItems = getState().galleryItems
        setState({
            galleryItems: [...prevUploadingItems, ...galleryItems]
        })
    },
    setUploadingItemsStatus: ({id, status}) => {
        const prevUploadingItems = getState().galleryItems
        setState({
            galleryItems: prevUploadingItems.map(item => {
                if (item.id == id) {
                    return {...item, status: status}
                }
                return item
            })
        })
    },
    setGalleryItemSrc: ({id, src}) => {
        const prevUploadingItems = getState().galleryItems
        setState({
            galleryItems: prevUploadingItems.map(item => {
                if (item.id == id) {
                    return {...item, src: src}
                }
                return item
            })
        })
    },
    clearGallery: () => setState({galleryItems: []}),
    deleteGalleryItem: (id: number) => {
        const prevUploadingItems = getState().galleryItems
        const filteredGalleryItems = prevUploadingItems.filter(item => item.id != id)
        setState({galleryItems: filteredGalleryItems})
    }
}))
