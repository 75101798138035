import {generateToastAllocationId, hotKeysEventToSpace, spaceNameToShortcut} from '@/features/photoset/utils.ts'
import {PhotosetURLParams} from '@/features/photoset/types.ts'
import {errorHandler, ObjectValues} from '@utilities/helpers.ts'
import {usePhotosetStore} from '@/features/photoset/store/store.ts'
import {useHotkeys} from 'react-hotkeys-hook'
import {useAllocateImages} from '@/features/photoset/queries/useAllocateImages'
import {useDeallocateImages} from '@/features/photoset/queries/useDeallocateImages'
import {useOutletContext} from 'react-router-dom'
import toast from 'react-hot-toast'

export const useAllocationShortcuts = (options?: {disabled?: boolean}) => {
    const selectedImages = usePhotosetStore(store => store.selectedImages)
    const undoLatestAction = usePhotosetStore(store => store.undoLatestAction)
    const allocationHistory = usePhotosetStore(store => store.allocationHistory)
    const photosetSpacesDict = usePhotosetStore(store => store.photosetSpacesDict)
    const deallocateImages = usePhotosetStore(store => store.deallocateImages)
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const deallocateImagesMutation = useDeallocateImages()

    const allocateImagesMutation = useAllocateImages({
        options: {
            onError: errorHandler
        },
        onUndo: async (imagesIds, photosetSpaceId) => {
            try {
                await deallocateImagesMutation.mutateAsync({
                    urlParams: {photosetId: urlParams.id, photosetSpaceId},
                    payload: imagesIds
                })

                const toastId = generateToastAllocationId('allocation', photosetSpaceId, imagesIds)
                deallocateImages({imagesIds, photosetSpaceId, keepSelected: true, trackInHistory: false})
                toast.dismiss(toastId)
            } catch (error) {
                errorHandler(error)
            }
        }
    })

    useHotkeys(
        ObjectValues(spaceNameToShortcut),
        (_, hotkeysEvent) =>
            allocateImagesMutation.mutate({
                urlParams: {
                    photosetId: urlParams.id,
                    photosetSpaceId: hotKeysEventToSpace(hotkeysEvent, ObjectValues(photosetSpacesDict))
                },
                payload: selectedImages
            }),
        {
            preventDefault: true,
            enableOnFormTags: true,
            enabled:
                !options?.disabled &&
                selectedImages.length >= 1 &&
                !allocateImagesMutation.isPending &&
                !deallocateImagesMutation.isPending
        },
        [
            allocateImagesMutation.isPending,
            deallocateImagesMutation.isPending,
            selectedImages,
            ObjectValues(photosetSpacesDict),
            options
        ]
    )
    useHotkeys(
        'mod+Z',
        async () => {
            try {
                await deallocateImagesMutation.mutateAsync({
                    urlParams: {photosetId: urlParams.id, photosetSpaceId: allocationHistory[0].photosetSpaceId},
                    payload: allocationHistory[0].imagesIds
                })

                const toastId = generateToastAllocationId(
                    allocationHistory[0].type,
                    allocationHistory[0].photosetSpaceId,
                    allocationHistory[0].imagesIds
                )

                undoLatestAction(true)
                toast.dismiss(toastId)
            } catch (error) {
                errorHandler(error)
            }
        },
        {
            preventDefault: true,
            enableOnFormTags: true,
            enabled:
                !options?.disabled &&
                allocationHistory.length >= 1 &&
                !allocateImagesMutation.isPending &&
                !deallocateImagesMutation.isPending
        },
        [allocationHistory, allocateImagesMutation.isPending, deallocateImagesMutation.isPending, options]
    )
}
