import {SearchLgIcon} from '@components/ui/icon/Icon.tsx'
import {
    StyledOpsVettingFlagsSelectSearchInput,
    StyledOpsVettingFlagsSelectSearchInputWrapper,
    StyledOpsVettingFlagsSelectPopover,
    StyledOpsVettingFlagsSelectItem
} from '@/features/ops-vetting/components/ops-vetting-flags-select-atoms/style.ts'
import {Combobox, ComboboxProps, SelectPopoverProps} from '@ariakit/react'
import {forwardRef} from 'react'
import {FlexboxProps} from '@components/ui/flexbox/FlexBox.tsx'

export const OpsVettingFlagsSelectSearchInput = forwardRef<HTMLInputElement, ComboboxProps>((props, ref) => (
    <StyledOpsVettingFlagsSelectSearchInputWrapper align="center" gap={2}>
        <SearchLgIcon size={20} />
        <Combobox autoSelect render={<StyledOpsVettingFlagsSelectSearchInput ref={ref} type="text" />} {...props} />
    </StyledOpsVettingFlagsSelectSearchInputWrapper>
))

export const OpsVettingFlagsSelectStyledPopover = (props: SelectPopoverProps) => (
    <StyledOpsVettingFlagsSelectPopover gutter={12} {...props} />
)

export const OpsVettingFlagsSelectItem = (props: FlexboxProps<'div'>) => (
    <StyledOpsVettingFlagsSelectItem gap={2} {...props} />
)
