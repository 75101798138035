import {FC, useState} from 'react'
import {SubmitHandler, useFieldArray, useForm} from 'react-hook-form'
import {Activity, CopyPreparationSchema, CopyPreparationValidationSchema} from '@/features/exp-copy-training/types.ts'
import {zodResolver} from '@hookform/resolvers/zod'
import {
    StyledExpAiCopyBody,
    StyledExpAiCopyForm,
    StyledExpAiCopyFormBox,
    StyledExpAiCopyFormBoxTitle,
    StyledExpAiCopyFormEmptyBox,
    StyledExpAiCopyPage,
    StyledExpAiCopyPageWrapper,
    StyledExpCopyAddActivityButton
} from '@pages/experience-ai-copy-prep-training/style.ts'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {Container} from '@components/ui/container/Container.tsx'
import {ExpCopyStaticForm} from '@/features/exp-copy-training/components/exp-copy-static-form/ExpCopyStaticForm.tsx'
import {ExpCopyActivityForm} from '@/features/exp-copy-training/components/activity-form/ActivityForm.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {AnnotationDotsIcon, PlusIcon, Stars01Icon, XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {EXPERIENCE_TIERS_PROMPT, EXPERIENCE_UPGRADES_PROMPT} from '@/features/ops-vetting/utils'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {PromptModal} from '@/features/exp-copy-training/components/prompt-modal/PromptModal'
import {useMerchCopyAi} from '@/features/ops-vetting/hooks/useMerchCopyAi'

export const ExpAiCopyPreparation: FC = () => {
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm<CopyPreparationValidationSchema>({
        resolver: zodResolver(CopyPreparationSchema),
        defaultValues: {
            activities: []
        }
    })

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'activities'
    })

    const [improvedContent, setImprovedContent] = useState<CopyPreparationValidationSchema | null>(null)
    const [aiError, setAiError] = useState<boolean>(false)
    const [useTiersPrompt, setUseTiersPrompt] = useState(true)
    const [currentPrompt, setCurrentPrompt] = useState<string>(
        useTiersPrompt ? EXPERIENCE_TIERS_PROMPT : EXPERIENCE_UPGRADES_PROMPT
    )

    const handleImprovedContentChange = (values: Partial<CopyPreparationValidationSchema>) => {
        if (improvedContent) {
            setImprovedContent({
                ...improvedContent,
                ...values
            })
        }
    }

    const handleActivityChange = (index: number, value: Partial<Activity>) => {
        if (improvedContent) {
            const newActivities = improvedContent.activities || []
            newActivities[index] = {...newActivities[index], ...value}
            setImprovedContent({
                ...improvedContent,
                activities: newActivities
            })
        }
    }

    const {aiResponse, isLoading, onMerchCopyAiCall} = useMerchCopyAi()

    const onCopySubmit: SubmitHandler<CopyPreparationValidationSchema> = data => {
        onMerchCopyAiCall(data, currentPrompt)
        setImprovedContent(aiResponse as CopyPreparationValidationSchema)
    }

    return (
        <StyledExpAiCopyPage>
            <PageHero
                title={'Copy Preparation'}
                subtitle={'Paste your listing details below, let AI suggest improvements, and finalize your edits.'}
                endSlot={
                    <Flexbox gap={2} direction={'column'} align="start">
                        <Flexbox gap={2}>
                            <Button
                                variant={useTiersPrompt ? 'primary' : 'secondary'}
                                size="xs"
                                onClick={() => {
                                    setUseTiersPrompt(true)
                                    setCurrentPrompt(EXPERIENCE_TIERS_PROMPT)
                                }}
                            >
                                Tier 2 + Tier 3
                            </Button>
                            <Button
                                variant={useTiersPrompt ? 'secondary' : 'primary'}
                                size="xs"
                                onClick={() => {
                                    setUseTiersPrompt(false)
                                    setCurrentPrompt(EXPERIENCE_UPGRADES_PROMPT)
                                }}
                            >
                                Upgrades
                            </Button>
                        </Flexbox>
                        <PromptModal
                            trigger={
                                <Button variant="tertiary" size="sm">
                                    Customize prompt
                                </Button>
                            }
                            currentPrompt={currentPrompt}
                            onPromptChange={newPrompt => setCurrentPrompt(newPrompt)}
                            isLoading={isLoading}
                        />
                    </Flexbox>
                }
            />

            <StyledExpAiCopyPageWrapper>
                <Container fullWidth>
                    <StyledExpAiCopyBody gap={12}>
                        <StyledExpAiCopyFormBox direction={'column'} gap={6} align={'stretch'}>
                            <StyledExpAiCopyFormBoxTitle direction={'column'} gap={1}>
                                <AnnotationDotsIcon />
                                <h3>Original content</h3>
                                <small>Paste your listing details below</small>
                            </StyledExpAiCopyFormBoxTitle>
                            <StyledExpAiCopyForm onSubmit={handleSubmit(onCopySubmit)}>
                                <ExpCopyStaticForm register={register} control={control} errors={errors} />

                                {useTiersPrompt && (
                                    <div>
                                        <ItineraryDivider />
                                        {fields.map((field, index) => (
                                            <ExpCopyActivityForm
                                                key={field.id}
                                                index={index}
                                                register={register}
                                                errors={errors}
                                                onRemove={() => remove(index)}
                                            />
                                        ))}
                                        <StyledExpCopyAddActivityButton
                                            variant="tertiary"
                                            size={'sm'}
                                            onClick={() => append({title: '', description: ''})}
                                        >
                                            <PlusIcon />
                                            Add activity
                                        </StyledExpCopyAddActivityButton>
                                    </div>
                                )}
                                <Flexbox direction={'row'} justify={'space-between'} align={'center'}>
                                    <Button variant="secondary" type="submit" disabled={isLoading}>
                                        <Stars01Icon />
                                        Improve with AI
                                    </Button>
                                    {/* <Button variant="tertiary" disabled={isLoading}>Customize prompt</Button> */}
                                    <Button
                                        variant="tertiary"
                                        disabled={isLoading}
                                        onClick={() => {
                                            setImprovedContent(null)
                                            setAiError(false)
                                            reset()
                                        }}
                                    >
                                        Reset
                                    </Button>
                                </Flexbox>
                            </StyledExpAiCopyForm>
                        </StyledExpAiCopyFormBox>

                        {improvedContent && !isLoading && !aiError ? (
                            <StyledExpAiCopyFormBox direction={'column'} gap={6} align={'stretch'}>
                                <StyledExpAiCopyFormBoxTitle direction={'column'} gap={1}>
                                    <Stars01Icon />
                                    <h3>AI-Improved Content</h3>
                                    <small>Please review the output of AI, make edits if needed</small>
                                </StyledExpAiCopyFormBoxTitle>
                                <ExpCopyStaticForm
                                    register={register}
                                    control={control}
                                    values={improvedContent}
                                    onValueChange={handleImprovedContentChange}
                                />
                                {improvedContent?.activities && improvedContent.activities.length >= 1 && (
                                    <div>
                                        <ItineraryDivider />
                                        {improvedContent.activities.map((activity, index) => (
                                            <ExpCopyActivityForm
                                                key={`act-improv-${index}`}
                                                index={index}
                                                register={register}
                                                errors={errors}
                                                isReadOnly
                                                value={activity}
                                                onValueChange={value => handleActivityChange(index, value)}
                                            />
                                        ))}
                                    </div>
                                )}
                            </StyledExpAiCopyFormBox>
                        ) : (
                            <StyledExpAiCopyFormEmptyBox
                                justify={'center'}
                                direction={'column'}
                                align={'center'}
                                gap={4}
                            >
                                {aiError ? <XCloseIcon size={32} /> : <Stars01Icon size={32} />}
                                <p>
                                    {isLoading && 'AI is analyzing your contnent and re-write a better version of it'}
                                    {!isLoading &&
                                        !aiError &&
                                        'Your AI suggestions will appear here after you click "Improve with AI"'}
                                    {aiError &&
                                        !isLoading &&
                                        'There was an error with the AI process, please try again'}
                                </p>
                                {isLoading && <LoadingAiContentSkeleton />}
                            </StyledExpAiCopyFormEmptyBox>
                        )}
                    </StyledExpAiCopyBody>
                </Container>
            </StyledExpAiCopyPageWrapper>
        </StyledExpAiCopyPage>
    )
}

export const ItineraryDivider = () => (
    <Flexbox align={'center'} gap={2}>
        <h3>Itinerary</h3>
        <Divider direction={'horizontal'} />
    </Flexbox>
)

const LoadingAiContentSkeleton = () => (
    <Flexbox direction={'column'} gap={3}>
        <Skeleton width={300} height={28} />
        <Skeleton width={100} height={28} />
        <Skeleton width={300} height={28} />
        <Skeleton width={150} height={28} />
        <Skeleton width={300} height={28} />
        <Skeleton width={190} height={28} />
        <Skeleton width={300} height={28} />
        <Skeleton width={100} height={28} />
    </Flexbox>
)

ExpAiCopyPreparation.displayName = 'ExpAiCopyPreparation'
