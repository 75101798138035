import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {HttpEditItemFlags, httpRemoveServiceItemFlags} from '@/features/service-vetting/http/servicesVetting.http.ts'
import {Service, ServiceFlag} from '@/features/service-vetting/types.ts'

export const useRemoveServiceExpertiseAnswerFlags = (
    urlParams: Omit<HttpEditItemFlags['urlParams'], 'section' | 'item' | 'itemId'>,
    options?: Omit<UseMutationOptions<unknown, unknown, {flags: ServiceFlag[]; itemId: number}>, 'mutationFn'>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: payload =>
            httpRemoveServiceItemFlags({
                urlParams: {...urlParams, section: 'expertise', item: 'question-answer', itemId: payload.itemId},
                payload: {flags: payload.flags.map(flag => flag.id)},
                params: {
                    response_type: 'small'
                }
            }),
        onError: (error, variables, context) => {
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.SERVICE_VETTING, urlParams.serviceId]}, context)
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onMutate: async variables => {
            const queryKey = [QUERY_KEYS.SERVICE_VETTING, urlParams.serviceId]

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey})

            // Snapshot previous values
            const previousData = queryClient.getQueryData<Service>(queryKey)

            // Update cached data
            if (previousData) {
                queryClient.setQueriesData<Service>(
                    {queryKey},
                    {
                        ...previousData,
                        expertise: {
                            ...previousData.expertise,
                            question_answer: previousData.expertise.question_answer.map(question => {
                                if (question.id == variables.itemId) {
                                    return {
                                        ...question,
                                        flags: question.flags.filter(
                                            flag => !variables.flags.some(prevFlag => flag.id === prevFlag.id)
                                        )
                                    }
                                }
                                return question
                            })
                        }
                    }
                )
            }
            return previousData
        },
        ...options
    })
}
