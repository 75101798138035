import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {
    httpAddServiceSectionFlags,
    HttpEditServiceSectionFlags
} from '@/features/service-vetting/http/servicesVetting.http.ts'
import {Service, ServiceFlag} from '@/features/service-vetting/types.ts'

export const useAddServiceExpertiseFlags = (
    urlParams: Omit<HttpEditServiceSectionFlags['urlParams'], 'section'>,
    options?: Omit<UseMutationOptions<unknown, unknown, {flags: ServiceFlag[]}>, 'mutationFn'>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: payload =>
            httpAddServiceSectionFlags({
                urlParams: {...urlParams, section: 'expertise'},
                payload: {flags: payload.flags.map(flag => flag.id)},
                params: {
                    response_type: 'small'
                }
            }),
        onError: (error, variables, context) => {
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.SERVICE_VETTING, urlParams.serviceId]}, context)
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onMutate: async variables => {
            const queryKey = [QUERY_KEYS.SERVICE_VETTING, urlParams.serviceId]

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey})

            // Snapshot previous values
            const previousData = queryClient.getQueryData<Service>(queryKey)

            // Update cached data
            if (previousData) {
                queryClient.setQueriesData<Service>(
                    {queryKey},
                    {
                        ...previousData,
                        expertise: {
                            ...previousData.expertise,
                            flags: [...previousData.expertise.flags, ...variables.flags]
                        }
                    }
                )
            }
            return previousData
        },
        ...options
    })
}
