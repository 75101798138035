import {Container} from '@/components/ui/container/Container'
import {StyledServiceVettingWrapper} from '../service-vetting/style'
import {StyledCopyTrainingWrapper} from './style'
import {ScrollArea} from '@/components/commons/scroll-area/ScrollArea'
import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Stars01Icon} from '@/components/ui/icon/Icon'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {ServiceVettingMerchOutputForm} from '@/features/service-vetting/components/service-vetting-merch-output-form/ServiceVettingMerchOutputForm'
import {ServiceMerchandisingInputSchema, ServiceMerchandisingOutputSchema} from '@/features/service-vetting/formSchemas'
import {
    StyledServiceMerchandisingVettingWrapper,
    StyledServiceVettingFormsWrapper
} from '../service-vetting/merchandising/style'
import {useMerchCopyAi} from '@/features/ops-vetting/hooks/useMerchCopyAi'
import {useRef, useState} from 'react'
import {SubmitHandler} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ServicePromptModal} from '@/features/service-copy-training/ServicePromptModal'
import {SERVICES_PROMPT} from '@/features/ops-vetting/utils'
import {OpsVettingHero} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {ServiceVettingMercTrainingInputForm} from '@/features/service-vetting/components/service-vetting-merch-input-form/ServiceVettingMerchTrainingInputForm'

// const DefaultServiceMerchandisingInput: ServiceMerchandisingInputSchema = {
//     hostExperience: '17',
//     hostExperienceSummary:
//         'I am an experienced portrait photographer with 17 years of expertise. My journey began in photojournalism, working for a prominent newspaper. I then transitioned to the role of photography coordinator at a renowed magazine, leading creative projects. In 2010 , i founded Lola Estudio, my own photography studio, whew i specialize in creating timeless portraits for individuals',
//     hostExperienceHighlight: 'Estabilishing my own company and growing it into a trusted name in portrait photo',
//     hostExperienceBackground: 'The recognition of my own clients trough the years',
//     serviceTitle: 'An expert portrait photographer',
//     serviceByline: '',
//     offerings: [
//         {
//             title: 'Family photo expirience',
//             description:
//                 'Capture unforgottable memories during your visit to the vibrant and iconic Mexico City" This photography session is designed for families or couples who want to preserve their adventures in the city.',
//             duration: '60 minutes'
//         }
//     ]
// } as const

export const ServiceCopyTraining = () => {
    const {t} = useTranslation()
    const {aiResponse, isLoading, onMerchCopyAiCall, resetAiResponse} = useMerchCopyAi()
    //submission refs
    const finalSubmitRef = useRef<HTMLButtonElement>(null)
    const aiSubmissionRef = useRef<HTMLButtonElement>(null)
    const [currentPrompt, setCurrentPrompt] = useState<string>(SERVICES_PROMPT)

    const handleAiSubmission: SubmitHandler<ServiceMerchandisingInputSchema> = data => {
        resetAiResponse()
        onMerchCopyAiCall(data, currentPrompt)
    }

    const handleFinalSubmission: SubmitHandler<ServiceMerchandisingOutputSchema> = data => {
        console.log('handleFinalSubmission', data)
    }

    return (
        <StyledServiceVettingWrapper>
            <Container fullWidth>
                <StyledCopyTrainingWrapper>
                    <h1>Service Copy Training</h1>
                </StyledCopyTrainingWrapper>
            </Container>

            <ScrollArea scrollbar={<Scrollbar />}>
                <StyledServiceMerchandisingVettingWrapper fullWidth>
                    <OpsVettingHero fullWidth justify="space-between" align="center">
                        <h3>{t('vetting:merch:service:title')}</h3>
                        <Flexbox gap={2}>
                            <Button
                                variant="secondary"
                                size="sm"
                                onClick={() => aiSubmissionRef.current?.click()}
                                disabled={isLoading}
                            >
                                <Stars01Icon />
                                {t('vetting:merch:commons:improve_with_ai')}
                            </Button>
                            <ServicePromptModal
                                trigger={
                                    <Button variant="tertiary" size="sm" disabled={isLoading}>
                                        Customize prompt
                                    </Button>
                                }
                                currentPrompt={currentPrompt}
                                onPromptChange={newPrompt => setCurrentPrompt(newPrompt)}
                                isLoading={isLoading}
                            />
                        </Flexbox>
                    </OpsVettingHero>

                    <StyledServiceVettingFormsWrapper fullWidth>
                        <ServiceVettingMercTrainingInputForm
                            aiSubmissionRef={aiSubmissionRef}
                            handleAiSubmissionCb={handleAiSubmission}
                        />

                        <ServiceVettingMerchOutputForm
                            isLoading={isLoading}
                            defaultValues={aiResponse as ServiceMerchandisingOutputSchema}
                            finalSubmitRef={finalSubmitRef}
                            handleFinalSubmissionCb={handleFinalSubmission}
                        />
                    </StyledServiceVettingFormsWrapper>
                </StyledServiceMerchandisingVettingWrapper>
            </ScrollArea>
        </StyledServiceVettingWrapper>
    )
}
