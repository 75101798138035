import {ModalFooter, ModalHeader} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {FC, useState} from 'react'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {CheckIcon, XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {ScrollArea} from '@components/commons/scroll-area/ScrollArea.tsx'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'

import {useGetService} from '@/features/service-vetting/query/useGetService.ts'
import {useOutletContext} from 'react-router-dom'
import {OpsVettingURLParams} from '@/features/ops-vetting/types.ts'
import {OpsVettingImage} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {
    StyledCheckIconContainer,
    StyledModal,
    StyledPhotoGrid,
    StyledPhotoWrapper
} from '@/features/service-vetting/components/service-vetting-concept-choose-photo-modal/style.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

interface ServiceVettingConceptChoosePhotoModalProps {
    title: string
    onClose: () => void
    onConfirm: (imageId: number) => void
    isPending?: boolean
    currentSelectedImageId?: number
}
export const ServiceVettingConceptChoosePhotoModal: FC<ServiceVettingConceptChoosePhotoModalProps> = ({
    title,
    onClose,
    onConfirm,
    isPending,
    currentSelectedImageId
}) => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: OpsVettingURLParams}>()
    const serviceQuery = useGetService(urlParams.id)
    const [selectedImgId, setSelectedImgId] = useState<number | null>(currentSelectedImageId || null)
    return (
        <StyledModal width={'680'}>
            <ModalHeader>
                <Button iconOnly variant={'ghost'} size={'sm'} onClick={onClose}>
                    <XCloseIcon />
                </Button>
                {title}
            </ModalHeader>
            <Divider direction={'horizontal'} />
            <ScrollArea scrollbar={<Scrollbar />}>
                <StyledPhotoGrid>
                    {serviceQuery.data?.gallery.images.map(image => (
                        <StyledPhotoWrapper key={image.id} onClick={() => setSelectedImgId(image.id)} role={'button'}>
                            <OpsVettingImage src={image.url} />
                            {selectedImgId == image.id && (
                                <StyledCheckIconContainer>
                                    <CheckIcon />
                                </StyledCheckIconContainer>
                            )}
                        </StyledPhotoWrapper>
                    ))}
                </StyledPhotoGrid>
            </ScrollArea>
            <ModalFooter>
                <Button variant={'tertiary'} onClick={onClose} disabled={isPending}>
                    {t('commons:cancel')}
                </Button>
                <Button
                    disabled={(selectedImgId && selectedImgId == currentSelectedImageId) || isPending}
                    onClick={() => selectedImgId && onConfirm(selectedImgId)}
                >
                    {t('commons:confirm')} {isPending && <Spinner size={20} />}
                </Button>
            </ModalFooter>
        </StyledModal>
    )
}
