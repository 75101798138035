import {AvatarFallback, AvatarImage} from '@radix-ui/react-avatar'
import {AvatarRoot} from '@components/ui/avatar/style.ts'

interface AvatarProps {
    name: string
    imageUrl: string
    size?: 'sm' | 'md' | 'lg'
}

const getInitials = (name: string): string => {
    const initials = name.match(/\b\w/g)
    if (initials) {
        return initials.length <= 2 ? initials.join('') : `${initials[0]}${initials.at(-1)}`
    }

    return ''
}

export const Avatar = ({name, imageUrl, size = 'md'}: AvatarProps) => (
    <AvatarRoot $size={size}>
        <AvatarImage src={imageUrl} alt={name} />
        <AvatarFallback delayMs={600}>{getInitials(name)}</AvatarFallback>
    </AvatarRoot>
)

Avatar.displayName = 'Avatar'
