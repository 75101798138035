import {HTMLAttributes, ImgHTMLAttributes} from 'react'
import {
    StyledOpsVettingDisabledContainer,
    StyledOpsVettingLabel,
    StyledOpsVettingParagraph,
    StyledOpsVettingCardTitle,
    StyledOpsVettingSectionTitle,
    StyledOpsVettingHero,
    StyledOpsVettingQuestionsGrid,
    StyledOpsVettingSectionWrapper,
    StyledOpsVettingCardContainer,
    StyledOpsVettingImage,
    StyledOpsVettingImageTitle,
    StyledOpsVettingCardGrid,
    StyledOpsVettingGalleryGrid,
    StyledOpsVettingCardBodyContainer,
    StyledOpsVettingImagePlaceholderBox,
    StyledOpsVettingGalleryItemsContainer
} from './style'
import {FlexboxProps} from '@components/ui/flexbox/FlexBox.tsx'
import {Image01Icon} from '@components/ui/icon/Icon.tsx'

export const OpsVettingSectionTitle = (props: HTMLAttributes<HTMLHeadingElement>) => (
    <StyledOpsVettingSectionTitle {...props} />
)
export const OpsVettingCardTitle = (props: HTMLAttributes<HTMLHeadingElement>) => (
    <StyledOpsVettingCardTitle {...props} />
)
export const OpsVettingLabel = (props: HTMLAttributes<HTMLSpanElement>) => <StyledOpsVettingLabel {...props} />
export const OpsVettingParagraph = (props: HTMLAttributes<HTMLHeadingElement>) => (
    <StyledOpsVettingParagraph {...props} />
)

export const OpsVettingDisabledContainer = (props: HTMLAttributes<HTMLDivElement> & {$isDisabled: boolean}) => {
    return <StyledOpsVettingDisabledContainer {...props} />
}
export const OpsVettingHero = (props: FlexboxProps<'div'>) => {
    return <StyledOpsVettingHero {...props} />
}

export const OpsVettingQuestionsGrid = (props: HTMLAttributes<HTMLHeadingElement>) => (
    <StyledOpsVettingQuestionsGrid {...props} />
)

export const OpsVettingSectionWrapper = (props: FlexboxProps<'div'>) => <StyledOpsVettingSectionWrapper {...props} />

export const OpsVettingCardContainer = (props: FlexboxProps<'div'>) => <StyledOpsVettingCardContainer {...props} />

export const OpsVettingImage = (props: ImgHTMLAttributes<HTMLImageElement>) => <StyledOpsVettingImage {...props} />

export const OpsVettingImageTitle = (props: HTMLAttributes<HTMLHeadingElement>) => (
    <StyledOpsVettingImageTitle {...props} />
)

export const OpsVettingCardGrid = (props: HTMLAttributes<HTMLDivElement>) => <StyledOpsVettingCardGrid {...props} />

export const OpsVettingGalleryGrid = (props: HTMLAttributes<HTMLDivElement>) => (
    <StyledOpsVettingGalleryGrid {...props} />
)

export const OpsVettingCardBodyContainer = (props: HTMLAttributes<HTMLDivElement>) => (
    <StyledOpsVettingCardBodyContainer {...props} />
)
export const OpsVettingGalleryItemsContainer = (props: FlexboxProps<'div'>) => (
    <StyledOpsVettingGalleryItemsContainer {...props} />
)

export const OpsVettingImgPlaceHolder = (props: Omit<HTMLAttributes<HTMLDivElement>, 'children'>) => (
    <StyledOpsVettingImagePlaceholderBox {...props}>
        <Image01Icon />
    </StyledOpsVettingImagePlaceholderBox>
)
