import {z} from 'zod'

const ActivitySchema = z.object({
    title: z.string().optional(),
    description: z.string().optional()
})

export const CopyPreparationSchema = z.object({
    listingTitle: z.string().min(1, {message: 'Listing title is required'}),
    listingDescription: z.string().min(1, {message: 'Listing description is required'}),
    hostBio: z.string().min(1, {message: 'Host bio is required'}),
    activities: z.array(ActivitySchema).optional()
})

export type Activity = z.infer<typeof ActivitySchema>
export type CopyPreparationValidationSchema = z.infer<typeof CopyPreparationSchema>
