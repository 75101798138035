import {PageFooter} from '@/components/commons/page-footer/PageFooter'
import {PageHero} from '@/components/commons/page-hero/PageHero'
import {Button} from '@/components/ui/button/Button'
import {ArrowRightIcon, MessageTextSquare01Icon} from '@/components/ui/icon/Icon'
import {NeedsReviewSpaceImages} from '@/features/photoset/components/needs-review-space-images/NeedsReviewSpaceImages'
import {PhotosetSpaceElement} from '@/features/photoset/components/photoset-space-element/PhotosetSpaceElement'
import {
    PhotosetStepBodyContainer,
    PhotosetStepWrapper
} from '@/features/photoset/components/photoset-step-atoms/PhotosetStepAtoms'
import {SubHeaderBanner} from '@/features/photoset/components/subheader-banner/SubHeaderBanner'
import {PhotosetURLParams} from '@/features/photoset/types'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useOutletContext} from 'react-router-dom'
import {StyledPhotosetSpaceElementDescription, StyledPhotosetSpaceElementDescriptionViewMore} from './style'
import {Tooltip} from '@/components/ui/tooltip/Tooltip'
import {useGetPhotoset} from '@/features/photoset/queries/useGetPhotoset'
import {useExpandPhotosetImage} from '@/features/photoset/hooks/useExpandPhotosetImage'
import {GalleryNeedsReviewModal} from '@/features/photoset/components/gallery-photoset-modal/gallery-needs-review-modal/GalleryNeedsReviewModal'
import {usePhotosetStore} from '@/features/photoset/store/store'
import {errorHandler} from '@/utilities/helpers'
import {QUERY_KEYS, queryClient} from '@/queryClient'
import {useGetPhotosetSpaces} from '@/features/photoset/queries/useGetPhotosetSpaces'
import {GET_PHOTOSET_IMAGES_LIST_RESPONSE_TYPE} from '@/features/photoset/queries/useGetPhotosetImagesList'
import {useUpdateStepToRetouchingRevisionUpload} from '@/features/photoset/queries/useUpdateStepToRetouchingRevisionUpload'
import {StepHeroControls} from '@/features/photoset/components/step-hero-controls/StepHeroControls'

export const PhotosetNeedsReviewStep = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const initPhotosetSpaces = usePhotosetStore(state => state.initPhotosetSpaces)

    // queries
    const photosetQuery = useGetPhotoset(
        {
            id: urlParams.id
        },
        {enabled: false}
    )

    const {
        data: photosetSpaces,
        isFetching: isFetchingPhotosetSpaces,
        isError: isErrorPhotosetSpaces
    } = useGetPhotosetSpaces({
        urlParams: {
            photosetId: urlParams.id
        },
        params: {
            response_type: GET_PHOTOSET_IMAGES_LIST_RESPONSE_TYPE,
            is_final_select: true
        }
    })

    // mutations
    const useUpdateStepToRetouchingRevisionUploadMutation = useUpdateStepToRetouchingRevisionUpload({
        onError: errorHandler,
        onSuccess: () => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.MANUAL_RETOUCHING_SPACES]})
        }
    })

    //init
    useEffect(() => {
        if (photosetSpaces) {
            initPhotosetSpaces(photosetSpaces)
        }
    }, [photosetSpaces])

    // Handle gallery modal view
    const {
        currentDetailImageIndex,
        setCurrentDetailImageIndex,
        currentDetailSpaceIndex,
        currentDetailSpace,
        onExpandImage,
        onReduceImage
    } = useExpandPhotosetImage()

    return (
        <>
            <PageHero
                title={t('photoset:needs_review:title')}
                subtitle={t('photoset:needs_review:subtitle')}
                endSlot={<StepHeroControls hideSortControls />}
            />

            {photosetQuery.data?.comment && (
                <SubHeaderBanner
                    type={'blue'}
                    title={t('photoset:needs_review:error_banner_title')}
                    subtitle={photosetQuery.data.comment}
                />
            )}

            <PhotosetStepWrapper>
                <PhotosetStepBodyContainer fullWidth>
                    {isFetchingPhotosetSpaces &&
                        [0, 1, 2].map(e => <PhotosetSpaceElement asSkeleton key={`${e}-id`} />)}
                    {!isFetchingPhotosetSpaces &&
                        !isErrorPhotosetSpaces &&
                        photosetSpaces?.map(
                            (photosetSpace, spaceIndex) =>
                                photosetSpace.images.length > 0 && (
                                    <PhotosetSpaceElement
                                        key={photosetSpace.id}
                                        spaceName={photosetSpace.space.key_name}
                                        endSlot={
                                            <NeedsReviewSpaceImages
                                                images={photosetSpace.images}
                                                onExpandImage={onExpandImage}
                                                spaceIndex={spaceIndex}
                                            />
                                        }
                                        spaceHeaderEndSlot={
                                            photosetSpace.comment && (
                                                <StyledPhotosetSpaceElementDescription gap={2}>
                                                    <MessageTextSquare01Icon size={20} />
                                                    <p>{photosetSpace.comment}</p>
                                                    <Tooltip
                                                        contentProps={{sideOffset: 8, align: 'center', side: 'right'}}
                                                        variant="light"
                                                        content={<p>{photosetSpace.comment}</p>}
                                                    >
                                                        <StyledPhotosetSpaceElementDescriptionViewMore variant="tertiary">
                                                            {t('photoset:needs_review:view_more')}
                                                        </StyledPhotosetSpaceElementDescriptionViewMore>
                                                    </Tooltip>
                                                </StyledPhotosetSpaceElementDescription>
                                            )
                                        }
                                    />
                                )
                        )}
                </PhotosetStepBodyContainer>
                <PageFooter
                    endSlot={
                        <Button
                            variant="primary"
                            disabled={useUpdateStepToRetouchingRevisionUploadMutation.isPending}
                            onClick={() => useUpdateStepToRetouchingRevisionUploadMutation.mutate(urlParams.id)}
                        >
                            {t('photoset:needs_review:cta')}
                            <ArrowRightIcon />
                        </Button>
                    }
                />
            </PhotosetStepWrapper>

            {currentDetailImageIndex != null && currentDetailSpaceIndex != null && currentDetailSpace && (
                <GalleryNeedsReviewModal
                    onClose={onReduceImage}
                    currentDetailImageIndex={currentDetailImageIndex}
                    setCurrentDetailImageIndex={setCurrentDetailImageIndex}
                    selectableImages={currentDetailSpace.images}
                    currentSpaceName={currentDetailSpace.space.key_name}
                />
            )}
        </>
    )
}
