import {forwardRef, ReactNode} from 'react'
import {StyledChip} from '@/components/commons/chip/style'
import {DefaultNamespace} from 'i18next'

export type ChipProps = {
    label: string | DefaultNamespace
    isActive?: boolean
    onClick?: () => void
    startSlot?: ReactNode
    className?: string
    endSlot?: ReactNode
    variant?: 'success' | 'info' | 'neutral' | 'danger' | 'warning'
    size?: 'sm' | 'md'
    shape?: 'circle' | 'rectangle'
    isClickable?: boolean
}

export const Chip = forwardRef<HTMLDivElement, ChipProps>(
    (
        {
            label,
            className,
            startSlot,
            endSlot,
            onClick,
            isActive = false,
            variant = 'info',
            size = 'sm',
            isClickable,
            shape = 'rectangle'
        },
        ref
    ) => (
        <StyledChip
            ref={ref}
            as={'div'}
            className={className}
            title={label}
            onClick={onClick}
            $variant={variant}
            $size={size}
            $isActive={isActive}
            $isClickable={isClickable || !!onClick}
            role={onClick ? 'button' : 'none'}
            $shape={shape}
        >
            {startSlot}

            <span>{label}</span>

            {endSlot}
        </StyledChip>
    )
)
