import {useState} from 'react'
import {useAsync} from '@/hooks/useAsync'
import {httpGenerateMerchVettingCopy} from '@/features/ops-vetting/http/ops-vetting.http'
import {generateMerchCopyAiPrompt, parseMarkdownJson} from '@/features/ops-vetting/utils'
import {ServiceMerchandisingInputSchema, ServiceMerchandisingOutputSchema} from '@/features/service-vetting/formSchemas'
//todo: remove this import and use the types from the ops-vetting module
import {CopyPreparationValidationSchema} from '@/features/exp-copy-training/types'

export const useMerchCopyAi = () => {
    const [aiResponse, setAiResponse] = useState<
        ServiceMerchandisingOutputSchema | CopyPreparationValidationSchema | undefined
    >(undefined)
    const {run, isLoading, isError} = useAsync()

    const onMerchCopyAiCall = async (
        data: ServiceMerchandisingInputSchema | CopyPreparationValidationSchema,
        customPrompt: string = ''
    ) => {
        const formattedPrompt = generateMerchCopyAiPrompt(data, 'services', customPrompt)
        try {
            const {data: responseData} = await run(httpGenerateMerchVettingCopy({prompt: formattedPrompt}))
            console.log('responseData', responseData)
            console.log('parseMarkdownJson', parseMarkdownJson(responseData))
            setAiResponse(parseMarkdownJson(responseData))
            return responseData
        } catch (e) {
            console.error(e)
            throw e
        }
    }

    const resetAiResponse = () => {
        setAiResponse(undefined)
    }

    return {
        aiResponse,
        isLoading,
        aiError: isError,
        onMerchCopyAiCall,
        resetAiResponse
    }
}
