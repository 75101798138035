import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'

export const StyledExpAiCopyPage = styled.div`
    display: grid;
    grid-template-rows: auto 1fr;
    overflow: hidden;
`
export const StyledExpAiCopyPageWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        position: relative;
        height: 100%;
        display: grid;
        overflow-y: scroll;
        padding-bottom: ${spacing * 10}px;
    `}
`

export const StyledExpAiCopyBody = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        position: relative;
        margin: ${spacing * 6}px 0 ${spacing * 6}px;
        height: 100%;
    `
)

export const StyledExpAiCopyFormBox = styled(Flexbox)(
    ({theme: {palette, spacing}}) => css`
        position: relative;
        width: 100%;
        padding: ${spacing * 6}px;
        border: 1px solid ${palette.neutral[300]};
        border-radius: 24px;
    `
)

export const StyledExpAiCopyFormBoxTitle = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        & > h3 {
            ${typography.textLg};
            color: ${palette.neutral['900']};
            font-weight: 600;
        }

        & > small {
            ${typography.textSm};
            color: ${palette.neutral['700']};
        }
    `
)

export const StyledExpAiCopyFormEmptyBox = styled(Flexbox)(
    ({theme: {palette, spacing}}) => css`
        position: relative;
        width: 100%;
        height: 100%;
        padding: ${spacing * 6}px;
        background: ${palette.neutral[50]};
        border: 1px solid ${palette.neutral[300]};
        border-radius: 24px;
    `
)

export const StyledExpAiCopyForm = styled.form`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: ${spacing * 6}px;
        width: 100%;
    `}
`

export const StyledExpCopyAddActivityButton = styled(Button)`
    ${({theme: {palette, spacing}}) => css`
        color: ${palette.neutral[900]};
        padding-left: 0;
        padding-bottom: ${spacing * 6}px;
        & > svg {
            width: 16px;
        }
    `}
`

export const StyledPromptInfo = styled.small`
    ${({theme: {typography, palette}}) => css`
        ${typography.textXs};
        color: ${palette.neutral[700]};
        & > span {
            text-decoration: underline;
            cursor: pointer;
        }
    `}
`

export const StyledCopyPromptModalBody = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 6}px;
        max-height: 760px;
        overflow-y: auto;
        & p {
            ${typography.textSm};
            color: ${palette.neutral[900]};
        }
        & ul {
            & span {
                font-weight: 600;
            }
            ${typography.textSm};
            color: ${palette.neutral[900]};
        }
        & textarea {
            font-family: monospace;
            ${typography.textSm};
            color: ${palette.neutral['700']};
        }
    `}
`
