import {PhotosetFinal} from '@/features/photoset/components/photoset-final/PhotosetFinal'
import {useGetPhotoset} from '@/features/photoset/queries/useGetPhotoset'
import {PhotosetURLParams} from '@/features/photoset/types'
import {useTranslation} from 'react-i18next'
import {useOutletContext} from 'react-router-dom'

export const PhotosetUnavailableQa = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const photosetQuery = useGetPhotoset(urlParams, {enabled: false})

    return (
        <PhotosetFinal
            status="error"
            title={t('photoset:unavailable_qa:title')}
            isLoading={photosetQuery.isFetching}
            subtitle={
                photosetQuery.data?.reason_decline_id
                    ? t('photoset:unavailable_qa:subtitle_rejected')
                    : t('photoset:unavailable_qa:subtitle_editing')
            }
        />
    )
}
