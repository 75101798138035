import {useTranslation} from 'react-i18next'
import {FC} from 'react'
import toast from 'react-hot-toast'
import {RemappedService} from '@/features/service-vetting/types.ts'
import {useSubmitService} from '@/features/service-vetting/query/useSubmitService.ts'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {
    StyledModalBody,
    StyledModalHeader
} from '@/features/service-vetting/components/service-vetting-concept-submit-modal/style.ts'
import {ModalFooter, ModalXCloseButton} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useServiceVettingError} from '@/features/service-vetting/store/useServiceVettingError.ts'

interface AdminServiceVettingConfirmationModalProps {
    onClose: () => void
    service: RemappedService
    isWaitlisted: boolean
}

export const ServiceVettingSubmitModal: FC<AdminServiceVettingConfirmationModalProps> = ({
    service,
    onClose,
    isWaitlisted
}) => {
    const {t} = useTranslation()
    const resetErrors = useServiceVettingError(state => state.reset)
    const submitQuery = useSubmitService(
        {serviceId: service.id},
        {
            onSettled: () => onClose(),
            onSuccess: () => {
                toast.success(t('vetting:concept:service:confirmation_modal:submit_success'))
                resetErrors()
            }
        }
    )
    const getSubtitleByService = () => {
        const MIN_PHOTO_ACCEPTED = 4
        const remapGallery = {...service.gallery, images: service.gallery.images.filter(img => !img.is_offering_image)}
        if (service.expertise.status == 'declined') {
            return t('vetting:concept:service:confirmation_modal:submission_rejected')
        } else if (
            service.expertise.status == 'back_to_draft' ||
            service.offerings.some(offering => offering.status == 'declined' || offering.status == 'back_to_draft') ||
            remapGallery.images.filter(image => image.is_approved).length < MIN_PHOTO_ACCEPTED
        ) {
            return t('vetting:concept:service:confirmation_modal:submission_back_to_draft')
        } else {
            return t('vetting:concept:service:confirmation_modal:submission_accepted')
        }
    }
    return (
        <Modal width={480}>
            <StyledModalHeader>
                <ModalXCloseButton onClick={onClose} />
                <p>{t('vetting:concept:service:confirmation_modal:title')}</p>
            </StyledModalHeader>
            <StyledModalBody>
                <h3>{getSubtitleByService()}</h3>
                <p>{t('vetting:concept:service:confirmation_modal:description')}</p>
            </StyledModalBody>
            <ModalFooter>
                <Button variant={'tertiary'} onClick={onClose}>
                    {t('vetting:concept:service:confirmation_modal:cancel')}
                </Button>

                <Button
                    variant={'primary'}
                    onClick={() => submitQuery.mutate({is_waitlisted: isWaitlisted, is_host_approval_needed: false})}
                    disabled={submitQuery.isPending}
                >
                    {t('vetting:concept:service:confirmation_modal:confirm')}
                    {submitQuery.isPending && <Spinner size={20} />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
