import {Dispatch, FC, SetStateAction, useMemo} from 'react'
import {GalleryModalHeader} from '@/components/commons/gallery-modal-header/GalleryModalHeader'
import {GalleryModalThumbnails} from '@/components/commons/gallery-modal-thumbnails/GalleryModalThumbnails'
import {Image, PhotosetSpace, Space} from '@/features/photoset/types'
import {spaceNameToLabel} from '@/features/photoset/utils'
import {GalleryModal} from '@/components/commons/gallery-modal/GalleryModal'
import {useTranslation} from 'react-i18next'
import {Button} from '@/components/ui/button/Button'
import {Checkbox} from '@/components/commons/checkbox/ChekBox'
import {useImageSelectionApproval} from '@/features/photoset/queries/useImageSelectionApproval'
import {errorHandler} from '@/utilities/helpers'
import {useGalleryRetouchingQaShortcuts} from '@/features/photoset/hooks/useGalleryRetouchingQaShortcuts'
import {GalleryModalSidebar} from '@/components/commons/gallery-modal-sidebar/GalleryModalSidebar'
import {useGetFlags} from '@/features/photoset/queries/useGetFlags'
import {GalleryRetouchingQaSidebar} from './GalleryRetouchingQaSidebar'

type GalleryRetouchingQaModalProps = {
    selectableImages: Omit<Image, 'space'>[]
    currentDetailImageIndex: number
    setCurrentDetailImageIndex: Dispatch<SetStateAction<number | null>>
    currentDetailSpaceIndex: number
    onClose: () => void
    currentPhotosetSpaceId: PhotosetSpace['id']
    currentSpaceName: Space['key_name']
    isQaView: boolean
}

export const GalleryRetouchingQaModal: FC<GalleryRetouchingQaModalProps> = ({
    selectableImages,
    currentDetailImageIndex,
    setCurrentDetailImageIndex,
    currentDetailSpaceIndex,
    currentPhotosetSpaceId,
    onClose,
    currentSpaceName,
    isQaView
}) => {
    const {t} = useTranslation()
    const currentImage = useMemo(
        () => selectableImages[currentDetailImageIndex],
        [currentDetailImageIndex, selectableImages]
    )

    const flagsQuery = useGetFlags(
        {
            params: {
                types: ['visual_retouching'],
                response_type: 'extended'
            }
        },
        {enabled: false}
    )

    // mutations
    const {mutate: imageSelectionApprovalToggle} = useImageSelectionApproval({
        photosetId: currentImage.photoset_id,
        photosetSpaceId: currentImage.photoset_space_id as number,
        options: {
            onError: errorHandler
        }
    })

    const flagsKeyName = flagsQuery?.data && flagsQuery.data.map(flag => flag.key_name)
    const isSendBackDisabled = useMemo(
        () => !currentImage.flags.some(flag => flagsKeyName?.includes(flag.key_name)),
        [flagsKeyName, currentImage]
    )

    const onToggleSelection = () => {
        if (isSendBackDisabled) return

        imageSelectionApprovalToggle({
            imageId: currentImage.id,
            needs_to_review: !currentImage.needs_to_review
        })
    }

    useGalleryRetouchingQaShortcuts({
        currentImage,
        currentSpaceIndex: currentDetailSpaceIndex,
        selectableImages,
        currentDetailImageIndex,
        setCurrentDetailImageIndex,
        onToggleSelection,
        onClose
    })

    return (
        <GalleryModal
            currentImage={currentImage}
            thumbnails={currentImage.retouched_thumbnails ?? currentImage.ai_retouched_thumbnails}
            onClose={onClose}
            headerSlot={
                <GalleryModalHeader
                    kickerTitle={spaceNameToLabel[currentSpaceName]}
                    currentImage={currentImage}
                    onClose={onClose}
                    endSlot={
                        isQaView && (
                            <Button
                                tabIndex={-1}
                                variant="secondary"
                                onClick={e => {
                                    onToggleSelection()
                                    e.currentTarget.blur()
                                }}
                            >
                                <Checkbox
                                    readOnly
                                    disabled={isSendBackDisabled}
                                    tabIndex={-1}
                                    checked={currentImage.needs_to_review}
                                />
                                {t('photoset:retouching_qa:send_back_for_retouching')}
                            </Button>
                        )
                    }
                />
            }
            sidebarSlot={
                isQaView && (
                    <GalleryModalSidebar
                        title={t('commons:feedback')}
                        content={
                            <GalleryRetouchingQaSidebar
                                currentImage={currentImage}
                                photosetId={currentImage.photoset_id}
                                photosetSpaceId={currentPhotosetSpaceId}
                            />
                        }
                    />
                )
            }
            thumbnailsSlot={
                <GalleryModalThumbnails
                    currentImage={currentImage}
                    thumbnails={'retouched_thumbnails'}
                    thumbnailsFallback="ai_retouched_thumbnails"
                    images={selectableImages}
                    onSelect={(_, index) => {
                        setCurrentDetailImageIndex(index)
                    }}
                />
            }
        />
    )
}
