import {useRedirectEditingPhotoset} from '@/features/photoset/hooks/useRedirectEditingPhotoset'
import {useGetPhotoset} from '@/features/photoset/queries/useGetPhotoset'
import {PhotosetURLParams} from '@/features/photoset/types'
import {Outlet, useOutletContext} from 'react-router-dom'

export const PhotosetEditingRoute = () => {
    const outletContext = useOutletContext<{urlParams: PhotosetURLParams}>()
    const {data: photoset} = useGetPhotoset({id: outletContext.urlParams.id}, {staleTime: Infinity})

    // Redirect the user to the current QA Step
    useRedirectEditingPhotoset({
        photosetId: photoset?.id,
        stepId: photoset?.step_id
    })

    return <Outlet context={outletContext} />
}
