import {FC} from 'react'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Tooltip} from '@components/ui/tooltip/Tooltip.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {HelpCircleIcon, ThumbsDownIcon, ThumbsUpIcon} from '@components/ui/icon/Icon.tsx'
import {ServiceVettingStatus} from '@/features/service-vetting/types.ts'

interface AdminServiceVettingStatusSelectorProps {
    value: ServiceVettingStatus | null
    onChange: (service: ServiceVettingStatus) => void
    isLoading?: boolean
    disabled?: boolean
}

export const ServiceVettingConceptStatusSelector: FC<AdminServiceVettingStatusSelectorProps> = ({
    value,
    onChange,
    disabled
}) => {
    const {t} = useTranslation()
    const {palette} = useTheme()

    return (
        <Flexbox gap={3}>
            <Tooltip
                rootProps={{open: value == 'accepted' ? false : undefined}}
                isOpenOnClickEnabled={false}
                content={t('vetting:accepted')}
                triggerProps={{asChild: true}}
            >
                <Button
                    style={{height: '36px'}}
                    disabled={!!disabled}
                    size={'sm'}
                    variant={value == 'accepted' ? 'primarySuccess' : 'secondary'}
                    onClick={() => value != 'accepted' && onChange('accepted')}
                >
                    <ThumbsUpIcon color={!value ? palette.success['500'] : undefined} />
                    {value == 'accepted' && t('vetting:accepted')}
                </Button>
            </Tooltip>
            <Tooltip
                rootProps={{open: value == 'declined' ? false : undefined}}
                isOpenOnClickEnabled={false}
                content={t('vetting:rejected')}
                triggerProps={{asChild: true}}
            >
                <Button
                    style={{height: '36px'}}
                    disabled={!!disabled}
                    size={'sm'}
                    variant={value == 'declined' ? 'primaryDanger' : 'secondary'}
                    onClick={() => value != 'declined' && onChange('declined')}
                >
                    <ThumbsDownIcon color={!value ? palette.danger['500'] : undefined} />
                    {value == 'declined' && t('vetting:rejected')}
                </Button>
            </Tooltip>
            <Tooltip
                rootProps={{open: value == 'back_to_draft' ? false : undefined}}
                isOpenOnClickEnabled={false}
                content={t('vetting:back_to_draft')}
                triggerProps={{asChild: true}}
            >
                <Button
                    style={{height: '36px'}}
                    disabled={!!disabled}
                    size={'sm'}
                    variant={value == 'back_to_draft' ? 'primaryBlue' : 'secondary'}
                    iconOnly={value != 'back_to_draft'}
                    onClick={() => value != 'back_to_draft' && onChange('back_to_draft')}
                >
                    <HelpCircleIcon color={!value ? palette.blue['500'] : undefined} />
                    {value == 'back_to_draft' && t('vetting:back_to_draft')}
                </Button>
            </Tooltip>
        </Flexbox>
    )
}
