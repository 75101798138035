import {Button} from '@components/ui/button/Button.tsx'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Edit03Icon} from '@components/ui/icon/Icon.tsx'
import {ServiceVettingConceptChoosePhotoModal} from '@/features/service-vetting/components/service-vetting-concept-choose-photo-modal/ServiceVettingConceptChoosePhotoModal.tsx'
import {useUpdateMainPhotoOffering} from '@/features/service-vetting/query/useUpdateMainPhotoOffering.ts'

export const ServiceVettingConceptEditOfferingPhotoButton = ({
    offeringTitle,
    offeringId,
    serviceId
}: {
    offeringTitle: string
    offeringId: number
    serviceId: number
}) => {
    const {t} = useTranslation()
    const [showModal, setShowModal] = useState(false)
    const updateMainPhotoOffering = useUpdateMainPhotoOffering(
        {offeringId, serviceId},
        {
            onSuccess: () => {
                setShowModal(false)
            }
        }
    )

    const onConfirm = (id: number) => {
        updateMainPhotoOffering.mutate({imgId: id})
    }
    return (
        <>
            <Button variant={'tertiary'} size={'sm'} onClick={() => setShowModal(true)}>
                <Edit03Icon />
                {t('commons:edit')}
            </Button>
            {showModal && (
                <ServiceVettingConceptChoosePhotoModal
                    title={t('vetting:concept:service:offerings:edit_offering_photo', {type: offeringTitle})}
                    onClose={() => setShowModal(false)}
                    onConfirm={onConfirm}
                    isPending={updateMainPhotoOffering.isPending}
                />
            )}
        </>
    )
}
