import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {XCircleIcon} from '@/components/ui/icon/Icon'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useGetPhotoset} from '@/features/photoset/queries/useGetPhotoset'
import {PhotosetURLParams} from '@/features/photoset/types'
import {
    StyledPhotosetRejectedCopyWrapper,
    StyledPhotosetRejectedContainer,
    StyledPhotosetRejectedParapraph,
    StyledPhotosetRejectedTitle
} from '@/pages/photoset/photoset-rejected/style'
import {routes} from '@/utilities/constants/routes'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useOutletContext} from 'react-router-dom'
import {useTheme} from 'styled-components'

export const PhotosetRejected = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const navigate = useNavigate()
    const photosetQuery = useGetPhotoset(
        {
            id: urlParams.id
        },
        {enabled: false}
    )

    useEffect(() => {
        if (photosetQuery?.data && photosetQuery?.data?.reason_decline_id == null) {
            navigate(routes.WELCOME.path, {replace: true})
        }
    }, [photosetQuery.data])

    return (
        <StyledPhotosetRejectedContainer>
            {photosetQuery.isFetching ? (
                <Spinner size={48} color={theme.palette.neutral[500]} />
            ) : (
                <Flexbox gap={8} direction="column" align="center" justify="center">
                    <XCircleIcon fill={theme.palette.danger[600]} size={40} />
                    <StyledPhotosetRejectedCopyWrapper direction="column" gap={2} align="center">
                        <StyledPhotosetRejectedTitle>{t('photoset:rejected:title')}</StyledPhotosetRejectedTitle>
                        <StyledPhotosetRejectedParapraph>
                            {t('photoset:rejected:paragraph')}
                        </StyledPhotosetRejectedParapraph>
                    </StyledPhotosetRejectedCopyWrapper>
                </Flexbox>
            )}
        </StyledPhotosetRejectedContainer>
    )
}

PhotosetRejected.displayName = 'PhotosetRejected'
