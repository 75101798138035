import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpUpdateStepToVettingQa} from '@/features/photoset/http/photoset.http'
import {MUTATION_KEYS} from '@/queryClient.ts'
import {AxiosError} from 'axios'

export const useUpdateStepToVettingQa = (
    options?: Omit<UseMutationOptions<unknown, AxiosError, number>, 'mutationFn' | 'mutationKey'>
) =>
    useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.UPDATE_STEP_VETTING_QA],
        mutationFn: httpUpdateStepToVettingQa
    })
