import {useMemo} from 'react'
import {qaStepIdToStepSlug} from '@/features/photoset/hooks/useRedirectQaPhotoset'
import {useGetPhotoset} from '@/features/photoset/queries/useGetPhotoset'
import {PhotosetURLParams} from '@/features/photoset/types'
import {routes} from '@/utilities/constants/routes'
import {generatePath, Navigate, Outlet, useLocation, useOutletContext} from 'react-router-dom'
import {qaStepsFlow} from '@/features/photoset/utils'

export const Photoset2LaRoute = () => {
    const outletContext = useOutletContext<{urlParams: PhotosetURLParams}>()
    const {data: photoset, isLoading} = useGetPhotoset({id: outletContext.urlParams.id}, {staleTime: Infinity})
    const location = useLocation()
    const currentUrlStep = location.pathname.split('/').at(-1)

    const is2LaStepEnabled = useMemo(() => {
        if (!photoset?.qa_step_id) return false

        const qaStepKey = photoset.qa_step_id
            ? qaStepIdToStepSlug?.[photoset.qa_step_id as keyof typeof qaStepIdToStepSlug]
            : null
        const urlStepFlowIndex = qaStepsFlow.findIndex(step => step == currentUrlStep)
        const qaStepFlowIndex = qaStepsFlow.findIndex(step => step == qaStepKey)

        return qaStepFlowIndex > urlStepFlowIndex
    }, [photoset, currentUrlStep])

    return !is2LaStepEnabled && !isLoading ? (
        <Navigate to={generatePath(routes.PHOTOSET_UNAVAILABLE_2LA.path, {id: photoset?.id})} />
    ) : (
        <Outlet context={outletContext} />
    )
}
