import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StyledServiceVettingFormHeader} from './style'

interface ServiceVettingMerchFormHeaderProps {
    icon: React.ReactNode
    title: string
    description: string
    variant?: 'base' | 'purple'
}

export const ServiceVettingMerchFormHeader = ({
    icon,
    title,
    description,
    variant = 'base'
}: ServiceVettingMerchFormHeaderProps) => {
    return (
        <StyledServiceVettingFormHeader direction={'column'} gap={3} $variant={variant}>
            {icon}
            <Flexbox direction={'column'} gap={1}>
                <h3>{title}</h3>
                <small>{description}</small>
            </Flexbox>
        </StyledServiceVettingFormHeader>
    )
}
