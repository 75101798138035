import {StyledCompletedAllocationBox} from '@/features/photoset/components/completed-allocation-box/style.ts'
import {ArrowRightIcon, CheckCircleIcon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useUpdateStepToCuration} from '@/features/photoset/queries/useUpdateStepToCuration'
import {errorHandler} from '@utilities/helpers.ts'
import {useOutletContext} from 'react-router-dom'
import {PhotosetURLParams} from '@/features/photoset/types.ts'
import {FC} from 'react'
import {useGetPhotoset} from '@/features/photoset/queries/useGetPhotoset'
import dayjs from 'dayjs'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {usePhotosetStore} from '@/features/photoset/store/store'
import {QUERY_KEYS, queryClient} from '@/queryClient.ts'

export const CompletedAllocationBox: FC<{imagesCount: number}> = ({imagesCount}) => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const photoset = useGetPhotoset(urlParams, {enabled: false})
    const unselectAll = usePhotosetStore(state => state.unselectAll)
    const updateStepToCurationMutation = useUpdateStepToCuration({
        onError: errorHandler,
        onSuccess: () => {
            unselectAll()
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.IMAGES]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET_SPACES]})
        }
    })
    const minutesCount = dayjs(dayjs()).diff(photoset.data?.initial_qa_completed_at, 'minute')

    return (
        <StyledCompletedAllocationBox direction="column" justify="center" gap={8}>
            <CheckCircleIcon size={40} />
            <Flexbox direction="column" gap={4}>
                <Flexbox direction="column" gap={2}>
                    <h3>{t('photoset:allocation:completed_title')}</h3>
                    <h4>{t('photoset:allocation:completed_subtitle')}</h4>
                </Flexbox>
                {minutesCount <= 5 && (
                    <p>{t('photoset:allocation:completed_success_subtitle', {imagesCount, minutesCount})}</p>
                )}
            </Flexbox>
            <Button
                onClick={() => updateStepToCurationMutation.mutate(urlParams.id)}
                variant="primary"
                disabled={updateStepToCurationMutation.isPending}
            >
                {updateStepToCurationMutation.isPending && <Spinner size={20} />}
                {t('commons:curation')}
                <ArrowRightIcon />
            </Button>
        </StyledCompletedAllocationBox>
    )
}

CompletedAllocationBox.displayName = 'CompletedAllocationBox'
