import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ImagesSortControls} from '@/features/photoset/components/images-sort-controls/ImagesSortControls'
import {AnnotationDotsIcon, DownloadCloud02Icon} from '@/components/ui/icon/Icon'
import {useGetPhotoset} from '@/features/photoset/queries/useGetPhotoset'
import {useLocation, useOutletContext} from 'react-router-dom'
import {PhotosetURLParams} from '@/features/photoset/types'
import {ButtonLink} from '@/components/ui/button-link/ButtonLink'
import {PopoverDisclosure, PopoverProvider, usePopoverStore} from '@ariakit/react'
import {
    StyledPhotographerNotesButton,
    StyledPhotographerNotesPopover,
    StyledPhotographerNotesPopoverDescription
} from './style'
import {ScrollArea} from '@/components/commons/scroll-area/ScrollArea'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'

export const StepHeroControls = ({
    hideSortControls = false,
    hidePhotographerNotes = false,
    hideZipButton = false
}: {
    hideSortControls?: boolean
    hidePhotographerNotes?: boolean
    hideZipButton?: boolean
}) => {
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const location = useLocation()

    const photosetQuery = useGetPhotoset(
        {
            id: urlParams.id
        },
        {enabled: false}
    )

    const photographerNotesPopover = usePopoverStore()

    return (
        <>
            <Flexbox gap={4}>
                {!hideSortControls && (
                    <ImagesSortControls isReadonlyOrderBy={location.pathname.includes('allocation')} />
                )}

                {(!hidePhotographerNotes || !hideZipButton) && (
                    <Flexbox gap={2}>
                        {!hidePhotographerNotes && photosetQuery.data?.assignment.photographer_notes && (
                            <PopoverProvider store={photographerNotesPopover} placement="bottom-end">
                                <PopoverDisclosure
                                    render={
                                        <StyledPhotographerNotesButton variant="secondary" size="sm">
                                            <AnnotationDotsIcon />
                                            Photographer notes
                                        </StyledPhotographerNotesButton>
                                    }
                                />
                                <StyledPhotographerNotesPopover gutter={4} portal>
                                    <ScrollArea style={{height: '100%'}} scrollbar={<Scrollbar />}>
                                        <StyledPhotographerNotesPopoverDescription>
                                            {photosetQuery.data.assignment.photographer_notes}
                                        </StyledPhotographerNotesPopoverDescription>
                                    </ScrollArea>
                                </StyledPhotographerNotesPopover>
                            </PopoverProvider>
                        )}

                        {!hideZipButton && photosetQuery.data?.zip_url && (
                            <ButtonLink size="sm" iconOnly variant="secondary" to={photosetQuery.data.zip_url}>
                                <DownloadCloud02Icon />
                            </ButtonLink>
                        )}
                    </Flexbox>
                )}
            </Flexbox>
        </>
    )
}
