import {captureException} from '@sentry/react'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {z} from 'zod'
import {ServiceVersion} from '@/features/service-vetting/types.ts'
import {
    httpGetAdminServiceVersions,
    HttpGetAdminServiceVersions
} from '@/features/service-vetting/http/servicesVetting.http.ts'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'

export const useGetServiceVersions = (
    urlParams: HttpGetAdminServiceVersions['urlParams'],
    options?: UseQueryOptions<ServiceVersion[]>
) => {
    const query = useQuery({
        queryKey: [QUERY_KEYS.SERVICE_VETTING, urlParams],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetAdminServiceVersions({
                    params: {response_type: 'extended'},
                    urlParams
                }),
                responseShape: z.array(ServiceVersion),
                onZodError: captureException
            }),
        ...options
    })

    return {...query, remappedData: query.data ? query.data : []}
}
