import {Outlet} from 'react-router-dom'
import {StyledServiceVettingWrapper} from './style'
import {Container} from '@/components/ui/container/Container'
import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {AlertTriangleIcon} from '@/components/ui/icon/Icon'
import {ErrorBoundary} from '@/ErrorBoundary'
import {useURLParsedParams} from '@/hooks/useURLParsedParams'
import {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'
import {OpsVettingURLParams} from '@/features/ops-vetting/types'
import {OpsVettingHeader} from '@/features/ops-vetting/components/ops-vetting-header/OpsVettingHeader'
import {useGetService} from '@/features/service-vetting/query/useGetService.ts'
import {ServiceVettingHeaderEndSlot} from '@/features/service-vetting/components/service-vetting-header-end-slot/ServiceVettingHeaderEndSlot.tsx'

export const ServiceVetting = () => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(OpsVettingURLParams)

    return urlParams.success ? (
        <OpsVettingDataInitializer vettingId={urlParams.data.id}>
            <ErrorBoundary>
                <StyledServiceVettingWrapper>
                    <Container fullWidth>
                        <OpsVettingHeader
                            endSlot={<ServiceVettingHeaderEndSlot vettingId={urlParams.data.id} />}
                            vettingId={urlParams.data.id}
                        />
                    </Container>
                    <Outlet context={{urlParams: urlParams.data}} />
                </StyledServiceVettingWrapper>
            </ErrorBoundary>
        </OpsVettingDataInitializer>
    ) : (
        <ErrorBox title={t('errors:invalid_url')} icon={<AlertTriangleIcon />} />
    )
}

const OpsVettingDataInitializer = ({
    vettingId,
    children
}: {
    vettingId: OpsVettingURLParams['id']
    children: ReactNode
}) => {
    useGetService(vettingId)
    return children
}
