import {errorHandler} from '@/utilities/helpers'
import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpAddPhotosetComment, HttpAddPhotosetCommentOptions} from '@/features/photoset/http/photoset.http'

export const useAddPhotosetComment = (
    urlParams: HttpAddPhotosetCommentOptions['urlParams'],
    options?: UseMutationOptions<unknown, unknown, HttpAddPhotosetCommentOptions['payload']>
) => {
    return useMutation({
        ...options,
        mutationFn: payload => httpAddPhotosetComment({urlParams, payload}),
        onError: errorHandler,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context)
        }
    })
}
