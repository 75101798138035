import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {useState} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {OpsVettingHero} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {RemappedService} from '@/features/service-vetting/types.ts'
import {useServiceVettingError} from '@/features/service-vetting/store/useServiceVettingError.ts'
import {checkErrors} from '@/features/service-vetting/utils.ts'
import {ServiceVettingSubmitModal} from '@/features/service-vetting/components/service-vetting-concept-submit-modal/ServiceVettingSubmitModal.tsx'

export const ServiceVettingConceptHero = ({service}: {service: RemappedService}) => {
    const {t} = useTranslation()
    const setIsTouched = useServiceVettingError(state => state.setIsTouched)
    const setErrors = useServiceVettingError(state => state.setErrors)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [isWaitlisted, setIsWaitlisted] = useState(false)

    const onClickSubmit = () => {
        setIsTouched(true)
        const errorCheck = checkErrors(service)
        setErrors(errorCheck.errors)
        if (errorCheck.isValid) {
            setShowConfirmationModal(true)
        }
    }

    return (
        <OpsVettingHero justify={'space-between'} fullWidth>
            <h3>{t('vetting:concept:service:title')}</h3>
            {service.status == 'under_review' && (
                <Flexbox gap={4} align="center">
                    <Checkbox
                        checked={isWaitlisted}
                        id="waitlisted"
                        label={t('vetting:concept:commons:waitlisted')}
                        onChange={() => setIsWaitlisted(!isWaitlisted)}
                    />
                    <Button onClick={onClickSubmit} variant="primary" size={'sm'}>
                        {t('commons:submit')}
                    </Button>
                </Flexbox>
            )}
            {showConfirmationModal && (
                <ServiceVettingSubmitModal
                    onClose={() => setShowConfirmationModal(false)}
                    service={service}
                    isWaitlisted={isWaitlisted}
                />
            )}
        </OpsVettingHero>
    )
}
