import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpUpdateStepToAiManualRetouchingUpload} from '@/features/photoset/http/photoset.http'
import {MUTATION_KEYS} from '@/queryClient.ts'
import {AxiosError} from 'axios'

export const useUpdateStepToAiManualRetouchingUpload = (
    options?: Omit<UseMutationOptions<unknown, AxiosError, number>, 'mutationFn' | 'mutationKey'>
) =>
    useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.UPDATE_STEP_AI_MANUAL_RETOUCHING_UPLOAD],
        mutationFn: httpUpdateStepToAiManualRetouchingUpload
    })
