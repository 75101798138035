import {useTranslation} from 'react-i18next'
import {FC, RefObject} from 'react'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {
    StyledModalBody,
    StyledModalHeader
} from '@/features/service-vetting/components/service-vetting-concept-submit-modal/style.ts'
import {ModalFooter, ModalXCloseButton} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

interface ServiceVettingMerchAiSubmitModalProps {
    isSubmitting: boolean
    onClose: () => void
    submissionRef: RefObject<HTMLButtonElement>
}

export const ServiceVettingMerchAiSubmitModal: FC<ServiceVettingMerchAiSubmitModalProps> = ({
    isSubmitting,
    onClose,
    submissionRef
}) => {
    const {t} = useTranslation()

    return (
        <Modal width={480} onOverlayClick={onClose}>
            <StyledModalHeader>
                <ModalXCloseButton onClick={onClose} />
                <p>{t('vetting:concept:service:confirmation_modal:title')}</p>
            </StyledModalHeader>
            <StyledModalBody>
                <h3>{t('vetting:merch:service:confirmation_modal:title')}</h3>
                <p>{t('vetting:merch:service:confirmation_modal:description')}</p>
            </StyledModalBody>
            <ModalFooter>
                <Button variant={'tertiary'} onClick={onClose}>
                    {t('vetting:concept:service:confirmation_modal:cancel')}
                </Button>

                <Button variant={'primary'} onClick={() => submissionRef.current?.click()} disabled={isSubmitting}>
                    {t('vetting:concept:service:confirmation_modal:confirm')}
                    {isSubmitting && <Spinner size={20} />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
