import {PhotosetFinal} from '@/features/photoset/components/photoset-final/PhotosetFinal'
import {useGetNeedManualRetouchingSpaces} from '@/features/photoset/queries/useGetNeedManualRetouchingSpaces'
import {GET_PHOTOSET_IMAGES_LIST_RESPONSE_TYPE} from '@/features/photoset/queries/useGetPhotosetImagesList'
import {PhotosetURLParams} from '@/features/photoset/types'
import {useTranslation} from 'react-i18next'
import {useOutletContext} from 'react-router-dom'

export const PhotosetAiVettingCompletedStep = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()

    // queries
    const {data: photosetSpaces, isFetching} = useGetNeedManualRetouchingSpaces({
        urlParams: {
            photosetId: urlParams.id
        },
        params: {
            response_type: GET_PHOTOSET_IMAGES_LIST_RESPONSE_TYPE,
            is_final_select: true
        }
    })

    const manuallyRetouchedCounter =
        photosetSpaces?.reduce((count, photosetSpace) => {
            return count + photosetSpace.images.filter(image => image.is_manually_retouched || image.is_revised).length
        }, 0) || 0

    return (
        <PhotosetFinal
            title={
                photosetSpaces && manuallyRetouchedCounter > 0
                    ? t('photoset:manual_retouching_completed:title')
                    : t('photoset:ai_vetting_completed:title')
            }
            subtitle={
                photosetSpaces && manuallyRetouchedCounter > 0
                    ? t('photoset:manual_retouching_completed:subtitle_image', {
                          count: manuallyRetouchedCounter
                      })
                    : t('photoset:ai_vetting_completed:subtitle')
            }
            status="success"
            isLoading={isFetching}
        />
    )
}

PhotosetAiVettingCompletedStep.displayName = 'PhotosetAiVettingCompletedStep'
