import {Header} from '@/components/commons/header/Header'
import {ButtonLink} from '@/components/ui/button-link/ButtonLink'
import {Skeleton} from '@/components/ui/skeleton/Skeleton'
import {Outlet, useOutletContext} from 'react-router-dom'
import {useGetPhotoset} from '@/features/photoset/queries/useGetPhotoset'
import {PhotosetURLParams} from '@/features/photoset/types'
import {AirbnbIcon} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'
import {StyledPhotosetLayout, StyledPhotosetLayoutMain} from './style'

export const PhotosetLayout = () => {
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const {data: photoset, isFetching} = useGetPhotoset({id: urlParams.id}, {enabled: false})
    const {palette} = useTheme()

    return (
        <StyledPhotosetLayout>
            <Header
                endSlot={
                    isFetching ? (
                        <>
                            <Skeleton height={30} width={140} />
                            <Skeleton height={30} width={140} />
                        </>
                    ) : (
                        <>
                            {photoset?.pdp_url && (
                                <ButtonLink variant="tertiary" to={photoset.pdp_url} target="_blank">
                                    <AirbnbIcon fill={palette.neutral['400']} />
                                    {photoset.name}
                                </ButtonLink>
                            )}

                            {/*{photoset?.airtable_url && (
                                <ButtonLink variant="tertiary" to={photoset.airtable_url} target="_blank">
                                    <AirtableIcon fill={palette.neutral['400']} />
                                    {t('commons:airtable_cta')}
                                </ButtonLink>
                            )}*/}
                        </>
                    )
                }
            />

            <StyledPhotosetLayoutMain>
                <Outlet context={{urlParams}} />
            </StyledPhotosetLayoutMain>
        </StyledPhotosetLayout>
    )
}

PhotosetLayout.displayName = 'PhotosetLayout'
