import {useGetFlags} from '@/features/photoset/queries/useGetFlags'
import {Flag, Image, PhotosetSpace, StepKey} from '@/features/photoset/types'
import {FC, ReactNode, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {
    StyledGalleryFlags,
    StyledGalleryFlagsContent,
    StyledGalleryFlagsTitle
} from '@/features/photoset/components/gallery-photoset-modal/gallery-flags/style'
import {Chip} from '@/components/commons/chip/Chip'
import {useAddImagesFlag} from '@/features/photoset/queries/useAddImagesFlag'
import {useRemoveImagesFlag} from '@/features/photoset/queries/useRemoveImagesFlag'
import {Skeleton} from '@/components/ui/skeleton/Skeleton'
import {EditingQaChip} from '@/features/photoset/components/editing-qa-chip/EditingQaChip'

interface GalleryFlagsProps {
    image: Omit<Image, 'space'>
    stepId: StepKey
    types?: Flag['type'][]
    photosetSpaceIds?: PhotosetSpace['id'][]
    endSlot?: ReactNode
    isQaView?: boolean
}

export const GalleryFlags: FC<GalleryFlagsProps> = ({
    image,
    stepId,
    types = ['metadata', 'visual_metadata', 'visual'],
    photosetSpaceIds,
    endSlot,
    isQaView = false
}) => {
    const {t} = useTranslation()

    const {
        data: flags,
        isLoading,
        isError
    } = useGetFlags({
        params: {
            types,
            response_type: 'extended'
        }
    })

    const addImagesFlag = useAddImagesFlag({stepId, photosetSpaceIds})
    const removeImagesFlag = useRemoveImagesFlag({stepId, photosetSpaceIds})

    const imageFlagsIds = useMemo(() => image.flags.map(flag => flag.id), [image])
    const remappedFlags = useMemo(
        () =>
            // Check the stepId to identify the editing qa flags step
            stepId != StepKey['editing-qa-flags'] ? flags ?? [] : image.flags ?? [],
        [image.flags, flags, image.image_flags]
    )

    const handleUpdateFlagToImages = (flag: Flag) => {
        const mutationFunction = imageFlagsIds.includes(flag.id) ? removeImagesFlag : addImagesFlag
        mutationFunction.mutate({flag, imageIds: [image.id]})
    }

    return (
        <StyledGalleryFlags direction="column">
            <StyledGalleryFlagsTitle align="center" justify="space-between">
                <h3>{t('commons:flag', {count: 2})}</h3>

                {endSlot}
            </StyledGalleryFlagsTitle>

            <StyledGalleryFlagsContent gap={2.5}>
                {isLoading ? (
                    Array.from({length: 4}).map((_, index) => <Skeleton key={index} height={16} width={35} />)
                ) : isError ? (
                    <p>{t('errors:default')}</p>
                ) : remappedFlags.length > 0 ? (
                    remappedFlags.map(flag =>
                        stepId == StepKey['editing-qa-flags'] ? (
                            photosetSpaceIds && (
                                <EditingQaChip
                                    key={flag.id}
                                    flag={flag}
                                    image={image}
                                    label={t(`photoset:flags:${flag.key_name}:label`)}
                                    photosetSpaceIds={photosetSpaceIds}
                                    isQaView={isQaView}
                                />
                            )
                        ) : (
                            <Chip
                                key={flag.id}
                                label={t(`photoset:flags:${flag.key_name}:label`)}
                                isActive={imageFlagsIds.includes(flag.id)}
                                variant={imageFlagsIds.includes(flag.id) ? 'info' : 'neutral'}
                                onClick={() => handleUpdateFlagToImages(flag)}
                            />
                        )
                    )
                ) : (
                    <p>{t('commons:no_results')}</p>
                )}
            </StyledGalleryFlagsContent>
        </StyledGalleryFlags>
    )
}
