import {Header} from '@/components/commons/header/Header'
import {Outlet} from 'react-router-dom'
import {StyledPrivateVettingLayout, StyledPrivateVettingLayoutMain} from './style.ts'

export const PrivateVettingLayout = () => {
    return (
        <StyledPrivateVettingLayout>
            <Header />
            <StyledPrivateVettingLayoutMain>
                <Outlet />
            </StyledPrivateVettingLayoutMain>
        </StyledPrivateVettingLayout>
    )
}

PrivateVettingLayout.displayName = 'PrivateVettingLayout'
