import {
    StyledCoverPhotoWrapper,
    StyledEditButton,
    StyledNoCoverPhotoContainer
} from '@/features/service-vetting/components/service-vetting-concept-cover-photo/style.ts'
import {
    OpsVettingGalleryItemsContainer,
    OpsVettingImage,
    OpsVettingImageTitle
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {useTranslation} from 'react-i18next'
import {Edit02Icon, Image01Icon} from '@components/ui/icon/Icon.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Fragment, useState} from 'react'
import {ServiceVettingConceptChoosePhotoModal} from '@/features/service-vetting/components/service-vetting-concept-choose-photo-modal/ServiceVettingConceptChoosePhotoModal.tsx'
import {useUpdateIsCoverGalleryImg} from '@/features/service-vetting/query/useUpdateIsCoverGalleryImg.ts'
import {Service} from '@/features/service-vetting/types.ts'

export const ServiceVettingConceptCoverPhoto = ({
    coverPhoto,
    hideAction,
    serviceId,
    galleryId
}: {
    coverPhoto?: Service['gallery']['images'][number]
    hideAction: boolean
    galleryId: number
    serviceId: number
}) => {
    const {t} = useTranslation()
    const [showModal, setShowModal] = useState(false)
    const updateIsCoverGalleryImg = useUpdateIsCoverGalleryImg(
        {galleryId, serviceId},
        {onSuccess: () => setShowModal(false)}
    )

    const onConfirm = (id: number) => {
        updateIsCoverGalleryImg.mutate({is_cover: true, imgId: id})
    }
    return (
        <Fragment>
            <OpsVettingGalleryItemsContainer direction="column" gap={4}>
                {coverPhoto ? (
                    <StyledCoverPhotoWrapper>
                        <Flexbox direction="column" gap={4}>
                            <OpsVettingImage src={coverPhoto.url} loading={'lazy'} />
                            <OpsVettingImageTitle>{coverPhoto.name}</OpsVettingImageTitle>
                            {!hideAction && (
                                <StyledEditButton onClick={() => setShowModal(true)} iconOnly variant={'secondary'}>
                                    <Edit02Icon />
                                </StyledEditButton>
                            )}
                        </Flexbox>
                    </StyledCoverPhotoWrapper>
                ) : (
                    <StyledNoCoverPhotoContainer direction={'column'} gap={2} align={'center'} justify={'center'}>
                        <Image01Icon size={32} />
                        <Flexbox direction={'column'} align={'center'}>
                            <h4>{t('vetting:concept:service:gallery:no_cover_photo')}</h4>
                            {!hideAction && (
                                <Button onClick={() => setShowModal(true)} variant={'tertiary'} size={'sm'}>
                                    {t('vetting:concept:service:gallery:choose_a_photo')}
                                </Button>
                            )}
                        </Flexbox>
                    </StyledNoCoverPhotoContainer>
                )}
            </OpsVettingGalleryItemsContainer>
            {showModal && (
                <ServiceVettingConceptChoosePhotoModal
                    title={t('vetting:concept:service:gallery:cover_photo')}
                    onClose={() => setShowModal(false)}
                    onConfirm={onConfirm}
                    isPending={updateIsCoverGalleryImg.isPending}
                    currentSelectedImageId={coverPhoto?.id}
                />
            )}
        </Fragment>
    )
}
