import {ServiceVettingConceptVersionSelect} from '@/features/service-vetting/components/service-vetting-concept-version-select/ServiceVettingConceptVersionSelect.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {Service} from '@/features/service-vetting/types.ts'
import {useGetService} from '@/features/service-vetting/query/useGetService.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {StyledCategory} from '@/features/service-vetting/components/service-vetting-header-end-slot/style.ts'

const categoryToLabel = {
    photography: 'vetting:concept:service:category:photography',
    private_chef: 'vetting:concept:service:category:private_chef',
    prepared_meals: 'vetting:concept:service:category:prepared_meals',
    catering: 'vetting:concept:service:category:catering',
    personal_training: 'vetting:concept:service:category:personal_training',
    massage: 'vetting:concept:service:category:massage',
    spa_treatments: 'vetting:concept:service:category:spa_treatments',
    haircare: 'vetting:concept:service:category:haircare',
    makeup: 'vetting:concept:service:category:makeup',
    nails: 'vetting:concept:service:category:nails',
    generic: 'vetting:concept:service:category:generic',
    boudoir_photography: 'vetting:concept:service:category:photography'
} as const satisfies Record<NonNullable<Service['category']>['key_name'], string>

export const ServiceVettingHeaderEndSlot = ({vettingId}: {vettingId: number}) => {
    const {t} = useTranslation()
    const serviceQuery = useGetService(vettingId)
    return (
        <Flexbox gap={2.5} align={'center'}>
            {serviceQuery.isPending ? (
                <Skeleton height={20} width={130} />
            ) : (
                serviceQuery.data?.category && (
                    <StyledCategory>
                        {t('vetting:concept:service:category:category_value', {
                            category: t(categoryToLabel[serviceQuery.data.category.key_name])
                        })}
                    </StyledCategory>
                )
            )}
            <ServiceVettingConceptVersionSelect serviceId={vettingId} />
        </Flexbox>
    )
}
