import {
    OpsVettingDisabledContainer,
    OpsVettingGalleryGrid,
    OpsVettingParagraph,
    OpsVettingSectionTitle,
    OpsVettingSectionWrapper
} from '@/features/ops-vetting/components/ops-vetting-atoms/OpsVettingAtoms.tsx'
import {useTranslation} from 'react-i18next'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {ServiceVettingConceptGalleryItem} from '@/features/service-vetting/components/service-vetting-concept-gallery-item/ServiceVettingConceptGalleryItem.tsx'
import {RemappedService} from '@/features/service-vetting/types.ts'
import {useLayoutEffect, useMemo} from 'react'
import {useGetServiceFlags} from '@/features/service-vetting/query/useGetServiceFlags.ts'
import {useAddServiceGalleryFlags} from '@/features/service-vetting/query/useAddServiceGalleryFlags.ts'
import {useRemoveServiceGalleryFlags} from '@/features/service-vetting/query/useRemoveServiceGalleryFlags.ts'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useServiceVettingError} from '@/features/service-vetting/store/useServiceVettingError.ts'
import {AlertTriangleIcon, CheckIcon} from '@components/ui/icon/Icon.tsx'
import {useTheme} from 'styled-components'
import {Chip} from '@components/commons/chip/Chip.tsx'
import {ServiceVettingConceptGalleryUploadButton} from '@/features/service-vetting/components/service-vetting-concept-gallery-upload-button/ServiceVettingConceptGalleryUploadButton.tsx'
import {ServiceVettingConceptCoverPhoto} from '@/features/service-vetting/components/service-vetting-concept-cover-photo/ServiceVettingConceptCoverPhoto.tsx'
import {ServiceVettingConceptUploadItem} from '@/features/service-vetting/components/service-vetting-concept-upload-item/ServiceVettingConceptUploadItem.tsx'
import {useServicePhotoUpload} from '@/features/service-vetting/store/servicesPhotoUpload.ts'

export const ServiceVettingConceptGallery = ({service}: {service: RemappedService}) => {
    const {t} = useTranslation()

    const galleryGenericPhoto = useMemo(
        () => ({
            ...service.gallery,
            images: service.gallery.images.filter(
                img => !img.is_offering_image && !img.is_host_approval_needed && !img.is_cover
            )
        }),
        [service.gallery]
    )
    const uploadedPhoto = useMemo(
        () => service.gallery.images.filter(img => img.is_host_approval_needed),
        [service.gallery]
    )

    const coverPhoto = useMemo(() => service.gallery.images.find(img => img.is_cover), [service.gallery])

    const uploadingItems = useServicePhotoUpload(store => store.galleryItems)
    const clearGallery = useServicePhotoUpload(store => store.clearGallery)

    const addGalleryItems = useServicePhotoUpload(store => store.addGalleryItems)

    const errors = useServiceVettingError(state => state.errors)

    const flagsQuery = useGetServiceFlags({
        response_type: 'extended',
        types: ['gallery']
    })
    const addServiceGalleryFlags = useAddServiceGalleryFlags({serviceId: service.id, itemId: service.gallery.id})
    const removeServiceGalleryFlags = useRemoveServiceGalleryFlags({serviceId: service.id, itemId: service.gallery.id})

    const onChangeCheckBox = (isChecked: boolean) => {
        const flag = flagsQuery.data?.find(flag => flag.key_name == 'lack_photo_variety')
        const mutationFunction = isChecked ? addServiceGalleryFlags : removeServiceGalleryFlags
        if (flag) {
            mutationFunction.mutate({flags: [flag]})
        }
    }
    const {palette} = useTheme()

    const hideAction = service.status != 'under_review'
    useLayoutEffect(() => {
        addGalleryItems(uploadedPhoto.map(item => ({id: item.id, status: 'uploaded', src: item.url})))
        return () => clearGallery()
    }, [uploadedPhoto])

    return (
        <OpsVettingSectionWrapper fullWidth direction={'column'} gap={6}>
            <Flexbox fullWidth justify="space-between" align="center">
                <Flexbox gap={8} align={'center'}>
                    <OpsVettingSectionTitle>{t('vetting:concept:service:gallery:title')}</OpsVettingSectionTitle>
                    {!hideAction && (
                        <ServiceVettingConceptGalleryUploadButton
                            serviceId={service.id}
                            galleryId={service.gallery.id}
                            disabled={service.expertise.status == 'declined'}
                        />
                    )}
                    <Checkbox
                        checked={galleryGenericPhoto.flags.some(flag => flag.key_name == 'lack_photo_variety')}
                        onChange={e => onChangeCheckBox(e.currentTarget.checked)}
                        id={'lack_photo_variety'}
                        label={t('vetting:concept:service:flags_key_name:lack_photo_variety')}
                        disabled={hideAction || service.expertise.status == 'declined'}
                    />
                </Flexbox>
                <Flexbox gap={4} align={'center'}>
                    {errors.galleryErrors.map((error, index) => (
                        <Chip
                            key={index}
                            label={t(error)}
                            variant={'danger'}
                            isActive
                            startSlot={<AlertTriangleIcon />}
                        />
                    ))}
                    <OpsVettingParagraph>
                        {galleryGenericPhoto.images.filter(image => image.is_approved === null).length > 0 ? (
                            t('vetting:concept:service:gallery:images_remaining', {
                                value: galleryGenericPhoto.images.filter(image => image.is_approved === null).length
                            })
                        ) : (
                            <Flexbox render={'span'} gap={1}>
                                <CheckIcon size={20} color={palette.success[600]} />
                                {t('vetting:concept:service:gallery:images_reviewed')}
                            </Flexbox>
                        )}
                    </OpsVettingParagraph>
                </Flexbox>
            </Flexbox>
            <Divider direction="horizontal" />
            <OpsVettingDisabledContainer $isDisabled={service.expertise.status == 'declined'}>
                <OpsVettingGalleryGrid>
                    <ServiceVettingConceptCoverPhoto
                        serviceId={service.id}
                        galleryId={service.gallery.id}
                        coverPhoto={coverPhoto}
                        hideAction={hideAction}
                    />
                    {galleryGenericPhoto.images.map((image, index) => (
                        <ServiceVettingConceptGalleryItem
                            image={image}
                            key={index}
                            galleryId={galleryGenericPhoto.id}
                            hideAction={hideAction}
                        />
                    ))}
                </OpsVettingGalleryGrid>
                {uploadingItems.length > 0 && (
                    <>
                        <Divider direction={'horizontal'} />
                        <OpsVettingGalleryGrid>
                            {uploadingItems.map((image, index) => (
                                <ServiceVettingConceptUploadItem
                                    key={index}
                                    image={image}
                                    serviceId={service.id}
                                    galleryId={service.gallery.id}
                                    hideAction={hideAction}
                                />
                            ))}
                        </OpsVettingGalleryGrid>
                    </>
                )}
            </OpsVettingDisabledContainer>
        </OpsVettingSectionWrapper>
    )
}
